import { Accordion, Container, HStack, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ColorPicker, spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { useModelUpdate, useTranslation } from "@hooks";
import { ModelAttributes } from "@models/backend";
import { Model } from "@models/project";
import { useCallback } from "react";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";

type ModelReferencePropertiesProps = { reference: Model } & StyleProps;

export function SidebarModelReferenceProperties({ reference, ...props }: ModelReferencePropertiesProps) {
  const { t } = useTranslation("project.sidebar.properties.references");
  const { model, changeAttributes } = useModelUpdate({ modelId: reference.id });
  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const borderColor = semanticTokens.border.border;
  const { border } = useThemeTokens();

  const updateMeshSettingsAttributes = useCallback(
    async (attributes: Partial<ModelAttributes["meshSettings"]>) => {
      const modelMeshSettingsAttributes = model?.attributes?.meshSettings ?? {};
      const updated = { ...modelMeshSettingsAttributes, ...attributes };
      await changeAttributes({ meshSettings: updated });
    },
    [model?.attributes?.meshSettings, changeAttributes],
  );

  const updateColor = useCallback(
    async (color: string) => await updateMeshSettingsAttributes({ color }),
    [updateMeshSettingsAttributes],
  );

  if (!model) {
    return null;
  }

  return (
    <Container variant="classic" borderTopWidth={border.width} {...props}>
      <Accordion
        variant="outline"
        borderTop="unset"
        allowMultiple
        defaultIndex={[0, 1, 2]}
        paddingX={spacing.space["200"]}
        w="full"
        borderColor={borderColor}
      >
        <SidebarPropertiesSection borderBottom="unset" title={t("sections.meshSettings.label")}>
          <HStack justifyContent="space-between" w="100%">
            <Text color={textClassicSecondary} variant="2xs-regular" width="50%">
              {t("sections.meshSettings.fields.color.label")}
            </Text>
            <ColorPicker color={model?.attributes?.meshSettings?.color} setColor={updateColor} />
          </HStack>
        </SidebarPropertiesSection>
      </Accordion>
    </Container>
  );
}
