import { getOrganizationProjectsPath, getProjectPath } from "@hooks";
import { Project } from "@models/backend";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function useProjectsNavigation() {
  const navigate = useNavigate();
  const navigateToProject = useCallback((projectId: string) => navigate(getProjectPath(projectId)), [navigate]);
  const navigateToProjects = useCallback(
    (organizationId: Project["organizationId"]) => {
      navigate(getOrganizationProjectsPath(organizationId), { replace: true });
    },
    [navigate],
  );

  return { navigateToProject, navigateToProjects };
}
