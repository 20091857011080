import { Button, StyleProps, ThemingProps, Tooltip, TooltipProps, ButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export type IconButtonProps = {
  onClick: () => void;
  shortcut?: {
    keys: string | string[];
  };
  label: string;
  leftIcon: ReactElement;
  hideLabel?: boolean;
  isTooltipDisabled?: boolean;
  isDisabled?: ButtonProps["isDisabled"];
  disabledReason?: string;
  tooltipPlacement?: TooltipProps["placement"];
} & StyleProps &
  ThemingProps<"Button">;

export const IconButton = ({
  leftIcon,
  label,
  hideLabel,
  shortcut,
  onClick,
  isTooltipDisabled = false,
  isDisabled,
  disabledReason,
  tooltipPlacement,
  size,
  variant,
}: IconButtonProps) => {
  useHotkeys(shortcut?.keys ?? "", onClick, { preventDefault: true });

  const tooltip = isDisabled ? disabledReason : label + (shortcut ? ` (${shortcut?.keys})` : "");
  const icon = hideLabel ? undefined : leftIcon;

  return (
    <Tooltip hasArrow label={tooltip} isDisabled={isTooltipDisabled} placement={tooltipPlacement} openDelay={500}>
      <Button leftIcon={icon} size={size} variant={variant} onClick={onClick} isDisabled={isDisabled}>
        {hideLabel ? leftIcon : label}
      </Button>
    </Tooltip>
  );
};
