import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function RecycleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="5" width="5" {...props}>
      <path
        d="M12.8412 10.8333H13.3412V10.3333H12.8412V10.8333ZM10.1746 10.3333H9.67456V11.3333H10.1746V10.3333ZM3.16667 5.16667H2.66667V5.66667H3.16667V5.16667ZM5.83333 5.66667H6.33333V4.66667H5.83333V5.66667ZM13.3412 13.5V10.8333H12.3412V13.5H13.3412ZM12.8412 10.3333H10.1746V11.3333H12.8412V10.3333ZM2.66667 2.5V5.16667H3.66667V2.5H2.66667ZM3.16667 5.66667H5.83333V4.66667H3.16667V5.66667ZM8 13C5.23858 13 3 10.7614 3 8H2C2 11.3137 4.68629 14 8 14V13ZM8 3C10.7614 3 13 5.23858 13 8H14C14 4.68629 11.3137 2 8 2V3ZM3.6203 5.50036C4.48309 4.00886 6.14506 3 8 3V2C5.78339 2 3.79386 3.20323 2.7547 4.99964L3.6203 5.50036ZM3 8C3 7.7879 3.01318 7.57912 3.03871 7.37437L2.04639 7.25063C2.01576 7.49633 2 7.74644 2 8H3ZM13 8C13 8.2121 12.9868 8.42088 12.9613 8.62563L13.9536 8.74937C13.9842 8.50367 14 8.25356 14 8H13ZM12.3797 10.4996C11.5169 11.9911 9.85494 13 8 13V14C10.2166 14 12.2061 12.7968 13.2453 11.0004L12.3797 10.4996Z"
        fill="currentColor"
      />
    </Icon>
  );
}
