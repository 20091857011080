import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function FlipIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="5" width="5" {...props}>
      <path
        d="M10.6665 2L13.3332 4.66667L10.6665 7.33333M5.33317 8.66667L2.6665 11.3333L5.33317 14M12.6665 4.66667L2.6665 4.66667M13.3332 11.3333H3.33317"
        stroke="currentColor"
        strokeWidth={1}
        strokeLinecap="square"
      />
    </Icon>
  );
}
