import { useEffect } from "react";
import { useThree } from "@react-three/fiber";

type RaycasterSetupProps = {
  pointsThreshold: number;
};
export function RaycasterSetup({ pointsThreshold }: RaycasterSetupProps) {
  const { raycaster } = useThree();

  useEffect(() => {
    raycaster.params.Points!.threshold = pointsThreshold;
  }, [pointsThreshold]);

  return null;
}
