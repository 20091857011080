import { MenuItem } from "@chakra-ui/react";
import { IconDropdown, IconDropdownProps, Separator } from "@design-system";
import { RenameProjectPanel } from "@fragments/projects/view";
import { useDeleteProject, useModelName, useProjectUpdate, useProjectUrl } from "@hooks";
import { Project } from "@models/backend";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { MoreIcon } from "./icons";

export function ProjectContextMenu({
  project,
  onProjectUpdated,
  onProjectDeleted,
  ...iconDropdownProps
}: {
  project: Project;
  onProjectUpdated?: (updatedProject: Project) => void;
  onProjectDeleted?: (updatedProject: Project) => void;
} & Partial<IconDropdownProps>) {
  const { path, onCopy } = useProjectUrl(project.id);
  const { deleteProject } = useDeleteProject();
  const { renameProject } = useProjectUpdate(project as Project);
  const displayName = useModelName(project as Project);
  const [isRenameOpen, setIsRenameOpen] = useState(false);

  const handleRename = async (newName: string): Promise<Project> => {
    const updatedProject = await renameProject(newName);
    onProjectUpdated?.(updatedProject);
    setIsRenameOpen(false);
    return updatedProject;
  };

  return (
    <>
      <IconDropdown label="more" icon={<MoreIcon />} variant="contextMenu" {...iconDropdownProps}>
        <MenuItem as={NavLink} to={path}>
          Open
        </MenuItem>
        <MenuItem as={NavLink} target="_blank" to={path}>
          Open in a new tab
        </MenuItem>
        <MenuItem onClick={() => setIsRenameOpen(true)}>Rename</MenuItem>
        <Separator p={0} m={0} variant="inverse" />
        <MenuItem onClick={onCopy}>Copy link</MenuItem>
        <Separator p={0} m={0} variant="inverse" />
        <MenuItem onClick={() => deleteProject(project, onProjectDeleted)}>Delete</MenuItem>
      </IconDropdown>
      <RenameProjectPanel
        isOpen={isRenameOpen}
        onClose={() => setIsRenameOpen(false)}
        currentName={displayName}
        onRename={handleRename}
      />
    </>
  );
}
