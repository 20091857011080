import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";
import { useSemanticTokens } from "@design-system";

export function LogoIcon(props: IconProps) {
  const {
    text: {
      classic: { primary: fill },
    },
  } = useSemanticTokens();

  return (
    <Icon viewBox="0 0 125 27" fill={fill} w="4.5rem" {...props}>
      <g clipPath="url(#clip0_6912_33230)">
        <path d="M1.76262 0.720612H6.03408V22.081H1.76262V0.720612ZM6.03408 22.081H27.3945V26.3525H6.03408V22.081Z" />
        <path d="M35.3579 25.3711C34.2332 24.7168 33.3421 23.8257 32.6878 22.701C32.0336 21.5763 31.7064 20.3425 31.7064 19.0059V4.99207H35.9779V18.7473C35.9779 19.6602 36.305 20.4422 36.9593 21.0965C37.6136 21.7508 38.3987 22.0779 39.3084 22.0779H49.7332C50.646 22.0779 51.428 21.7508 52.0823 21.0965C52.7366 20.4422 53.0637 19.6602 53.0637 18.7473V8.32262C53.0637 7.41287 52.7366 6.62775 52.0823 5.97348C51.428 5.3192 50.6429 4.99207 49.7332 4.99207H35.9779V0.720612H49.9918C51.3315 0.720612 52.5621 1.04775 53.6868 1.70202C54.8116 2.35629 55.7026 3.24735 56.3569 4.37207C57.0112 5.49679 57.3383 6.73056 57.3383 8.06714V19.0028C57.3383 20.3425 57.0112 21.5732 56.3569 22.6979C55.7026 23.8226 54.8116 24.7137 53.6868 25.3679C52.5621 26.0253 51.3283 26.3494 49.9918 26.3494H39.0561C37.7164 26.3494 36.4857 26.0222 35.361 25.3679L35.3579 25.3711Z" />
        <path d="M65.2612 25.3711C64.1365 24.7168 63.2454 23.8257 62.5912 22.701C61.9369 21.5763 61.6098 20.3425 61.6098 19.0059V4.99207H65.8812V18.7473C65.8812 19.6602 66.2083 20.4422 66.8626 21.0965C67.5169 21.7508 68.302 22.0779 69.2118 22.0779H79.6365C80.5494 22.0779 81.3314 21.7508 81.9856 21.0965C82.6399 20.4422 82.967 19.6602 82.967 18.7473V8.32262C82.967 7.41287 82.6399 6.62775 81.9856 5.97348C81.3314 5.3192 80.5462 4.99207 79.6365 4.99207H65.8812V0.720612H79.8951C81.2348 0.720612 82.4654 1.04775 83.5902 1.70202C84.7149 2.35629 85.6059 3.24735 86.2602 4.37207C86.9145 5.49679 87.2416 6.73056 87.2416 8.06714V19.0028C87.2416 20.3425 86.9145 21.5732 86.2602 22.6979C85.6059 23.8226 84.7149 24.7137 83.5902 25.3679C82.4654 26.0253 81.2317 26.3494 79.8951 26.3494H68.9594C67.6197 26.3494 66.389 26.0222 65.2643 25.3679L65.2612 25.3711Z" />
        <path d="M91.5972 0.720612H109.627C110.967 0.720612 112.213 1.05709 113.366 1.72383C114.519 2.39368 115.431 3.29719 116.101 4.4375C116.771 5.5778 117.104 6.83026 117.104 8.198C117.104 9.56574 116.755 10.8182 116.058 11.9585C115.36 13.0988 114.428 14.0023 113.26 14.6722C112.091 15.342 110.823 15.6754 109.459 15.6754H95.8718V11.4039H109.543C110.425 11.4039 111.194 11.0924 111.848 10.463C112.503 9.83679 112.83 9.08282 112.83 8.198C112.83 7.31317 112.515 6.5592 111.889 5.93297C111.263 5.30674 110.506 4.99207 109.624 4.99207H95.8687V11.4008H91.5972V0.720612ZM95.8687 26.3525H91.5972V15.6723H95.8687V26.3525Z" />
      </g>
      <defs>
        <clipPath id="clip0_6912_33230">
          <rect width="124" height="25.8593" transform="translate(0.829903 0.274628)" />
        </clipPath>
      </defs>
    </Icon>
  );
}
