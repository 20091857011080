import { defineStyleConfig, SystemStyleFunction, SystemStyleObject } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";

const noSelectStyle: SystemStyleObject = {
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  KhtmlUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

const classic: SystemStyleFunction = ({ theme, colorMode }) => ({
  background: semanticTokens(theme, colorMode).surface.classic.primary,
  borderColor: semanticTokens(theme, colorMode).border.classic.primary,
  color: semanticTokens(theme, colorMode).text.classic.primary,
  "&::-webkit-scrollbar": {
    width: "8px",
    backgroundColor: semanticTokens(theme, colorMode).surface.classic.primary,
  },
  "&::-webkit-scrollbar-corner": {
    width: "8px",
    backgroundColor: semanticTokens(theme, colorMode).surface.classic.primary,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: semanticTokens(theme, colorMode).surface.classic.secondary,
  },
});

const sidebarLayer: SystemStyleFunction = ({ theme, colorMode }) => ({
  display: "flex",
  flexDir: "row",
  alignItems: "center",
  padding: "2px 4px",
  height: "20px",
  _hover: {
    bg: semanticTokens(theme, colorMode).surface.classic.secondary,
  },
  ...noSelectStyle,
});

const sidebarLayerSelected: SystemStyleFunction = ({ theme, colorMode }) => ({
  display: "flex",
  flexDir: "row",
  alignItems: "center",
  padding: "2px 4px",
  height: "20px",
  bg: semanticTokens(theme, colorMode).surface.accent["tertiary-opacity"],
  ...noSelectStyle,
});

const sidebarLayerHovered: SystemStyleFunction = ({ theme, colorMode }) => ({
  display: "flex",
  flexDir: "row",
  alignItems: "center",
  padding: "2px 4px",
  height: "20px",
  bg: semanticTokens(theme, colorMode).surface.classic.secondary,
  ...noSelectStyle,
});

export const containerStyleConfig = defineStyleConfig({
  baseStyle: {
    gap: "0rem",
    padding: "0rem",
  },
  variants: {
    classic,
    sidebarLayer,
    sidebarLayerSelected,
    sidebarLayerHovered,
  },
});
