import { Box, HStack, VStack } from "@chakra-ui/react";
import { spacing, useSemanticTokens } from "@design-system";
import { textStyleConfig } from "@design-system/component-styles/TextStyleConfig";
import { hexToHsva, HsvaColor, hsvaToHex, validHex } from "@uiw/color-convert";
import { hsvaToHexa } from "@uiw/color-convert/src";
import Alpha from "@uiw/react-color-alpha";
import EditableInput from "@uiw/react-color-editable-input";
import RGBA from "@uiw/react-color-editable-input-rgba";
import Hue from "@uiw/react-color-hue";
import Saturation from "@uiw/react-color-saturation";
import { CSSProperties } from "react";

export interface ColorPickerPopupProps {
  color: string;
  setColor: (color: string) => void;
  disableAlpha?: boolean;
}

export function ColorPickerPopup({ color, setColor, disableAlpha = false }: ColorPickerPopupProps) {
  const hsva = hexToHsva(color);
  const {
    surface: {
      classic: { primary: backgroundColorPrimary, secondary: backgroundColorSecondary },
    },
    text: {
      classic: { primary: colorPrimary, secondary: colorSecondary },
    },
  } = useSemanticTokens();
  const editableInputProps = {
    style: { alignItems: "start" },
    inputStyle: {
      border: "none",
      boxShadow: "none",
      backgroundColor: backgroundColorSecondary,
      color: colorPrimary,
      padding: spacing.space[50],
      textAlign: "center" as CSSProperties["textAlign"],
    },
    labelStyle: {
      ...(textStyleConfig.variants!["2xs-regular"] as CSSProperties),
      color: colorSecondary,
    },
  };

  const handleChange = (hsv: HsvaColor) => {
    setColor?.(disableAlpha ? hsvaToHex(hsv) : hsvaToHexa(hsv));
  };
  const handleHex = (value: string | number) => {
    if (typeof value === "string" && validHex(value) && [3, 6].includes(value.length)) {
      setColor?.(value);
    }
  };
  const handleAlphaChange = (newAlpha: { a: number }) => handleChange({ ...hsva, ...{ a: newAlpha.a } });
  const handleSaturationChange = (newColor: HsvaColor) => handleChange({ ...hsva, ...newColor, a: hsva.a });

  return (
    <VStack
      p={spacing.space[400]}
      gap={spacing.space[200]}
      width={spacing.space[5400]}
      backgroundColor={backgroundColorPrimary}
    >
      <Saturation
        hsva={hsva}
        onChange={handleSaturationChange}
        style={{ height: spacing.space[4000], border: "none" }}
      />
      <HStack gap={spacing.space[200]} width="full">
        <VStack gap={spacing.space[200]} width="full">
          <Hue
            width="100%"
            height={spacing.space[300]}
            hue={hsva.h}
            onChange={(newHue) => handleChange({ ...hsva, ...newHue })}
          />
          {!disableAlpha && <Alpha width="100%" height={spacing.space[300]} hsva={hsva} onChange={handleAlphaChange} />}
        </VStack>
        {!disableAlpha && (
          <Box
            backgroundColor="white"
            backgroundImage="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg=="
          >
            <Box boxSize={spacing.space[800]} backgroundColor={color} />
          </Box>
        )}
      </HStack>
      <HStack gap={spacing.space[200]}>
        <EditableInput
          label="Hex"
          placement="top"
          value={color.replace(/^#/, "").toLocaleUpperCase()}
          onChange={(_, val) => handleHex(val)}
          {...editableInputProps}
          style={{ ...editableInputProps.style, minWidth: spacing.space[1600] }}
        />
        <RGBA
          placement="top"
          hsva={hsva}
          aProps={disableAlpha ? false : editableInputProps}
          bProps={editableInputProps}
          gProps={editableInputProps}
          rProps={editableInputProps}
          onChange={(result) => handleHex(result.hexa)}
        />
      </HStack>
    </VStack>
  );
}
