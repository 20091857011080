import { AuthContext } from "@auth/AuthContext";
import { useInitAuth } from "@auth/useInitAuth.ts";
import { Button } from "@chakra-ui/react";
import { basename } from "./config";
import { ErrorBoundary, ErrorScreen, Loading } from "@fragments";
import { useBaseUrl, useTranslation, ViewVersionProvider } from "@hooks";
import { Onboarding } from "@routes";
import Home from "@routes/Home.tsx";
import Project from "@routes/Project.tsx";
import { captureException, wrapCreateBrowserRouter } from "@sentry/react";
import { lazy, Suspense, useEffect } from "react";
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from "react-router-dom";
import { analytics } from "@integrations/analytics/analytics";
import { UserTraits } from "@integrations/analytics";
import { Organization } from "@integrations/analytics/analyticsTypes";
import { trackPage } from "@/events";

interface IdentifyUserTraits extends UserTraits {
  organizations: Organization[];
  host: string;
}

export function identifyUser(userId: string, traits: IdentifyUserTraits) {
  try {
    analytics.identify(userId, {
      ...traits,
    });
  } catch (error) {
    captureException(error);
  }
}

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPage(location.pathname);
  }, [location.pathname]);

  return <Outlet />;
};

export const Playground = lazy(() => import("./routes/Playground"));

export default function App() {
  const baseUrl = useBaseUrl();
  const { accessToken, isLoading, error, headers, loginWithRedirect, logout, refreshAuthHeaders, user } = useInitAuth();
  const { t } = useTranslation();

  window.refreshAuthHeaders = refreshAuthHeaders;

  useEffect(() => {
    if (user) {
      const organizations = user.organizations.map((org) => ({
        id: org.organizationId,
        memberRole: org.memberRole,
      }));

      identifyUser(user.id, {
        organizations,
        host: window.location.host,
      });
    }
  }, [user]);

  if (error) {
    return (
      <ErrorScreen
        error={error}
        actions={
          <>
            <Button onClick={() => window.location.replace(baseUrl + basename)} variant="outline">
              {t("global.retry")}
            </Button>
            {accessToken && <Button onClick={logout}>{t("applicationContextMenu.logout")}</Button>}
          </>
        }
      />
    );
  }

  if (isLoading) {
    return <Loading height="full" />;
  }

  if (!accessToken || !headers || !user) {
    return <Onboarding loginWithRedirect={loginWithRedirect} />;
  }

  if (!window.location.pathname.startsWith(basename)) {
    window.location.replace(basename + window.location.pathname.substring(1));
  }

  const router = wrapCreateBrowserRouter(createBrowserRouter)(
    [
      {
        element: <PageTracker />,
        children: [
          {
            index: true,
            element: <Navigate to="/projects" />,
          },
          {
            path: "/*",
            element: (
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            ),
          },
          {
            path: "projects/:id",
            element: (
              <Suspense fallback={<Loading />}>
                <Project />
              </Suspense>
            ),
            loader: ({ params }: { params: unknown }) => params,
          },
          {
            path: "/playground",
            element: (
              <Suspense fallback={<Loading />}>
                <Playground />
              </Suspense>
            ),
          },
        ],
      },
    ],
    { basename },
  );

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        headers,
        user,
        logout,
      }}
    >
      <ViewVersionProvider>
        <ErrorBoundary user={user}>
          <RouterProvider router={router} />
        </ErrorBoundary>
      </ViewVersionProvider>
    </AuthContext.Provider>
  );
}
