import { User } from "@auth";
import { ErrorScreen } from "@fragments";
import { captureException } from "@sentry/react";
import { Component, PropsWithChildren, ReactNode } from "react";

type ErrorBoundaryProps = PropsWithChildren<{ showHeader?: boolean; user?: User }>;

type ErrorBoundaryState = { error?: Error };

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.debug("getDerivedStateFromError", { error });
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: Record<string, unknown>) {
    console.log({ error, errorInfo });
    captureException(error, { extra: errorInfo, user: this.props.user });
  }

  render(): ReactNode {
    if (this.state.error) {
      return <ErrorScreen error={this.state.error} showHeader={this.props.showHeader} />;
    }

    return this.props.children;
  }
}
