import { Modal as ModalComponent, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { Popover, utility } from "@design-system";
import { ReactNode } from "react";

export type ModalProps = {
  children: ReactNode;
  header?: string;
  isOpen: boolean;
  onClose?: () => void;
  closable?: boolean;
  closeOnOverlayClick?: boolean;
} & StyleProps;

export function Modal({
  header,
  children,
  isOpen,
  onClose = () => {
    /* Nothing to do here */
  },
  closable = true,
  closeOnOverlayClick = false,
  ...styles
}: ModalProps) {
  return (
    <ModalComponent isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={closable && closeOnOverlayClick}>
      <ModalOverlay bg={utility.secondary.black} />
      <ModalContent w="unset" borderRadius={0}>
        <Popover header={header} onClose={onClose} closable={closable} {...styles}>
          {children}
        </Popover>
      </ModalContent>
    </ModalComponent>
  );
}
