import { analytics } from "@integrations/analytics";
import { captureException } from "@sentry/react";

export function trackPage(path: string) {
  try {
    analytics.page({
      name: path,
      properties: {
        url: window.location.href,
        path,
        title: document.title,
        host: window.location.host,
        pathname: window.location.pathname,
        hash: window.location.hash,
      },
    });
  } catch (error) {
    captureException(error, {
      tags: {
        action: "PAGE_VIEW",
        path,
      },
      extra: {
        url: window.location.href,
        title: document.title,
      },
    });
  }
}
