import BackendApi from "@api/BackendApi";
import { useBackendApi } from "@api/useBackendApi";
import { OrganizationMembership } from "@auth/AuthContext.ts";
import { Box, Flex, Heading, VStack } from "@chakra-ui/react";
import { ErrorScreen, Loading, ProjectsEmptyState, ProjectsToolbar, ProjectsView } from "@fragments";
import { useTranslation } from "@hooks";

export function ProjectsContainer({ organization }: { organization: OrganizationMembership }) {
  const { t } = useTranslation("home");
  const { error, isLoading, result } = useBackendApi(
    BackendApi.getProjects,
    {
      params: { organizationId: organization.organizationId },
    },
    [organization],
  );

  if (isLoading) {
    return <Loading height="full" />;
  }

  if (error) {
    return <ErrorScreen showHeader={false} error={error} height="full" width="full" />;
  }

  const hasProjects = !!result?.projects?.length;

  return (
    <VStack gap={0} width="full" height="full" align="stretch">
      <Flex minWidth="full" alignItems="center" justifyContent="space-between" gap="2" minH="8rem" px={6}>
        {hasProjects && (
          <Heading as="h1" size="h1" textTransform="uppercase">
            {t("sidebar.projects")}
          </Heading>
        )}
        {hasProjects && <ProjectsToolbar organizationId={organization.organizationId} />}
      </Flex>
      {hasProjects ? (
        <Box minW="full" flex={1}>
          <ProjectsView organization={organization} projects={result?.projects} />
        </Box>
      ) : (
        <Flex flex={1} minW="full" direction="column" justify="center">
          <ProjectsEmptyState organization={organization} />
        </Flex>
      )}
    </VStack>
  );
}
