import { resolveHexValue, spacing, useSemanticTokens } from "@design-system";
import { Html } from "@react-three/drei";
import { Vector3 } from "three";

export function LengthCallout({ length, vertices }: { length?: number; vertices: Vector3[] }) {
  const semanticTokens = useSemanticTokens();

  if (!length) {
    return undefined;
  }

  const color = resolveHexValue(semanticTokens.surface.classic.tertiary);
  const background = resolveHexValue(semanticTokens.text.classic.primary);
  const midPoint = vertices[Math.floor(vertices.length * 0.5)].clone();

  return (
    <Html
      position={midPoint}
      distanceFactor={10}
      style={{
        padding: spacing.space["200"],
        background,
        color,
        transition: "all 0.25s",
        userSelect: "none",
      }}
      center
    >
      {length.toFixed(2)}cm
    </Html>
  );
}
