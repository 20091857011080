import { HttpStatusException } from "./exception";
import { OrganizationMember, User } from "@auth";
import { ColumnAnchor, Model, PathCollection, Project, SectionAnchor } from "@models/backend";
import { Process } from "@models/project";
import {
  AddOrganizationMemberRequest,
  AddProjectModelRequest,
  BackendApi,
  CreatePathCollectionRequest,
  CreateProcessRequest,
  CreateProjectRequest,
  CreateSectionAnchorRequest,
  CreateColumnAnchorRequest,
  DecimateProjectModelRequest,
  DeletePathCollectionRequest,
  DeleteProjectModelRequest,
  DeleteProjectRequest,
  DeleteSectionAnchorRequest,
  DeleteColumnAnchorRequest,
  GetOrganizationMembersRequest,
  GetProcessRequest,
  GetProjectRequest,
  GetProjectsRequest,
  GetUserRequest,
  OrganizationMembers,
  PatchProjectModelRequest,
  PatchProjectRequest,
  Projects,
  RemoveOrganizationMembersRequest,
  UpdateOrganizationMemberRequest,
  UpdatePathCollectionRequest,
  UpdateProjectThumbnailRequest,
  UpdateSectionAnchorRequest,
  UpdateColumnAnchorRequest,
  Upload,
} from "./BackendApi";

const loopBaseUri = import.meta.env.VITE_LOOP_BASE_URI;

if (!loopBaseUri) {
  throw new Error("VITE_LOOP_BASE_URI must be set");
}

const contentTypeApplicationJsonUTF8 = { "Content-Type": "application/json;charset=UTF-8" };

type ProjectResponse = { result: Project };
type OrganizationMemberResponse = { result: OrganizationMember };
type ProjectModelUploadResponse = { result: Model };
type PathCollectionResponse = { result: PathCollection };
type SectionAnchorResponse = { result: SectionAnchor };
type ColumnAnchorResponse = { result: ColumnAnchor };
type CreateProcessResponse = { result: { id: string; filename: string; url: string } };
type GetProcessResponse = { result: Process };

class FetchBackendApi implements BackendApi {
  async createProject({ body, ...options }: CreateProjectRequest): Promise<Project> {
    return (
      await pushAndFetchJson<ProjectResponse>("projects", {
        ...options,
        method: "POST",
        body,
      })
    ).result;
  }

  async patchProject({
    params: { projectId },
    body: { name },
    headers,
    ...options
  }: PatchProjectRequest): Promise<Project> {
    const url = `projects/${projectId}`;

    const result = await pushAndFetchJson<ProjectResponse>(url, {
      ...options,
      headers,
      method: "PATCH",
      body: { name },
    });

    return result.result;
  }

  deleteProject({ params: { id }, ...options }: DeleteProjectRequest): Promise<void> {
    return fetchJson(`projects/${id}`, { ...options, method: "DELETE" });
  }

  async getProject({ params: { id }, ...options }: GetProjectRequest): Promise<Project> {
    return ((await fetchJson(`projects/${id}`, options)) as ProjectResponse).result;
  }

  getProjects({ params: { organizationId }, ...options }: GetProjectsRequest): Promise<Projects> {
    return fetchJson("projects" + (organizationId !== undefined ? `?organizationId=${organizationId}` : ""), options);
  }

  async addProjectModel({
    params: { projectId },
    body,
    content,
    onProgress,
    ...options
  }: AddProjectModelRequest): Promise<Model> {
    const { result } = await pushAndFetchJson<ProjectModelUploadResponse>(`projects/${projectId}/models`, {
      ...options,
      method: "POST",
      body: {
        filename: content.name,
        pathCollections: [],
        sectionAnchors: [],
        ...body,
      },
    });
    await uploadBlob(result.url, { content, onProgress });

    return {
      ...result,
      url: (window.URL || window.webkitURL).createObjectURL(content),
      pathCollections: [],
      sectionAnchors: [],
    };
  }

  async decimateProjectModel({
    params: { projectId, id },
    content,
    onProgress,
    ...options
  }: DecimateProjectModelRequest): Promise<Model> {
    const result = await pushAndFetchJson<ProjectModelUploadResponse>(`projects/${projectId}/models/${id}/decimate`, {
      ...options,
      method: "POST",
      body: {
        filename: content.name,
      },
    });

    const { url, decimatedUrl } = result.result;

    await uploadBlob(decimatedUrl!, { content, onProgress });
    return {
      id,
      filename: content.name,
      name: content.name,
      url,
      decimatedUrl: (window.URL || window.webkitURL).createObjectURL(content),
      pathCollections: [],
      sectionAnchors: [],
      columnAnchors: [],
    };
  }

  async patchProjectModel({ params: { projectId, id }, body, ...options }: PatchProjectModelRequest): Promise<Model> {
    const result = (
      await pushAndFetchJson<ProjectModelUploadResponse>(`projects/${projectId}/models/${id}`, {
        ...options,
        method: "PATCH",
        body,
      })
    ).result;
    return {
      ...result,
    };
  }

  deleteProjectModel({ params: { projectId, id }, ...options }: DeleteProjectModelRequest): Promise<void> {
    return fetchJson(`projects/${projectId}/models/${id}`, { ...options, method: "DELETE" });
  }

  async createPathCollection({
    params: { projectId, modelId },
    body,
    ...options
  }: CreatePathCollectionRequest): Promise<PathCollection> {
    return (
      await pushAndFetchJson<PathCollectionResponse>(`projects/${projectId}/models/${modelId}/path-collections`, {
        ...options,
        method: "POST",
        body,
      })
    ).result;
  }

  async updatePathCollection({
    params: { projectId, modelId, id },
    body,
    ...options
  }: UpdatePathCollectionRequest): Promise<PathCollection> {
    return (
      await pushAndFetchJson<PathCollectionResponse>(`projects/${projectId}/models/${modelId}/path-collections/${id}`, {
        ...options,
        method: "PUT",
        body,
      })
    ).result;
  }

  async deletePathCollection({
    params: { projectId, modelId, id },
    ...options
  }: DeletePathCollectionRequest): Promise<void> {
    return fetchJson(`projects/${projectId}/models/${modelId}/path-collections/${id}`, {
      ...options,
      method: "DELETE",
    });
  }

  async updateProjectThumbnail({
    params: { projectId },
    content,
    onProgress,
    ...options
  }: UpdateProjectThumbnailRequest): Promise<void> {
    const result = await pushAndFetchJson<ProjectModelUploadResponse>(`projects/${projectId}/thumbnail`, {
      ...options,
      method: "PUT",
      body: {},
    });

    const { url } = result.result;

    await uploadBlob(url, { content, onProgress });
  }

  async createSectionAnchor({
    params: { projectId, modelId },
    body,
    ...options
  }: CreateSectionAnchorRequest): Promise<SectionAnchor> {
    return (
      await pushAndFetchJson<SectionAnchorResponse>(`projects/${projectId}/models/${modelId}/section-anchors`, {
        ...options,
        method: "POST",
        body,
      })
    ).result;
  }

  async updateSectionAnchor({
    params: { projectId, modelId, id },
    body,
    ...options
  }: UpdateSectionAnchorRequest): Promise<SectionAnchor> {
    return (
      await pushAndFetchJson<SectionAnchorResponse>(`projects/${projectId}/models/${modelId}/section-anchors/${id}`, {
        ...options,
        method: "PATCH",
        body,
      })
    ).result;
  }

  async deleteSectionAnchor({
    params: { projectId, modelId, id },
    ...options
  }: DeleteSectionAnchorRequest): Promise<void> {
    return fetchJson(`projects/${projectId}/models/${modelId}/section-anchors/${id}`, {
      ...options,
      method: "DELETE",
    });
  }

  async createColumnAnchor({
    params: { projectId, modelId },
    body,
    ...options
  }: CreateColumnAnchorRequest): Promise<ColumnAnchor> {
    return (
      await pushAndFetchJson<ColumnAnchorResponse>(`projects/${projectId}/models/${modelId}/column-anchors`, {
        ...options,
        method: "POST",
        body,
      })
    ).result;
  }

  async updateColumnAnchor({
    params: { projectId, modelId, id },
    body,
    ...options
  }: UpdateColumnAnchorRequest): Promise<ColumnAnchor> {
    return (
      await pushAndFetchJson<ColumnAnchorResponse>(`projects/${projectId}/models/${modelId}/column-anchors/${id}`, {
        ...options,
        method: "PUT",
        body,
      })
    ).result;
  }

  async deleteColumnAnchor({
    params: { projectId, modelId, id },
    ...options
  }: DeleteColumnAnchorRequest): Promise<void> {
    return fetchJson(`projects/${projectId}/models/${modelId}/column-anchors/${id}`, {
      ...options,
      method: "DELETE",
    });
  }

  async createProcess({
    params: { projectId },
    content,
    onProgress,
    ...options
  }: CreateProcessRequest): Promise<Process> {
    const { id: processId, url: signedUrl } = (
      await pushAndFetchJson<CreateProcessResponse>(`projects/${projectId}/processes`, {
        ...options,
        method: "POST",
        body: {
          filename: content.name,
        },
      })
    ).result;

    await uploadBlob(signedUrl, { content, onProgress });

    return (
      await pushAndFetchJson<{ result: Process }>(`projects/${projectId}/processes/${processId}`, {
        ...options,
        method: "PATCH",
        body: { status: "ready" },
      })
    ).result;
  }

  async getProcess({ params: { id, projectId }, ...options }: GetProcessRequest): Promise<Process> {
    return ((await fetchJson(`projects/${projectId}/processes/${id}`, options)) as GetProcessResponse).result;
  }

  async getUser(request: GetUserRequest): Promise<User> {
    return (await fetchJson<{ user: User }>("user", request)).user;
  }

  async getOrganizationMembers({
    params: { organizationId },
    ...options
  }: GetOrganizationMembersRequest): Promise<OrganizationMembers> {
    const response: OrganizationMembers = await fetchJson(`organizations/${organizationId}/members`, options);

    return {
      ...response,
      members: response.members.map((member) => ({
        ...member,
        createdAt: new Date(member.createdAt),
        updatedAt: new Date(member.updatedAt),
      })),
    };
  }

  removeOrganizationMembers({
    params: { organizationId },
    body,
    ...options
  }: RemoveOrganizationMembersRequest): Promise<OrganizationMembers> {
    return pushAndFetchJson(`organizations/${organizationId}/members`, {
      ...options,
      method: "DELETE",
      body,
    });
  }

  async addOrganizationMember({
    params: { organizationId },
    body,
    ...options
  }: AddOrganizationMemberRequest): Promise<OrganizationMember> {
    const result = await pushAndFetchJson<OrganizationMemberResponse>(`organizations/${organizationId}/members`, {
      ...options,
      method: "POST",
      body,
    });

    return result.result;
  }

  async updateOrganizationMember({
    params: { organizationId, memberId },
    body,
    ...options
  }: UpdateOrganizationMemberRequest): Promise<OrganizationMember> {
    const result = await pushAndFetchJson<OrganizationMemberResponse>(
      `organizations/${organizationId}/members/${memberId}`,
      {
        ...options,
        method: "PATCH",
        body,
      },
    );

    return result.result;
  }
}

export default new FetchBackendApi();

const MAX_RETRY_COUNT = 3;

async function fetchJson<T>(path: string, _request?: RequestInit & { retryCount?: number }): Promise<T> {
  const apiUrl = path.startsWith("http") ? path : loopBaseUri + "api/v1/" + path;
  const { retryCount = 0, ...request } = _request || {};
  const response = await fetch(apiUrl, request);
  let responseBody = await response.text();

  try {
    responseBody = responseBody.length ? JSON.parse(responseBody) : undefined;
  } catch {
    /* empty */
  }
  console.debug({
    apiUrl,
    request,
    response: {
      headers: response.headers,
      responseBody,
      status: { code: response.status, text: response.statusText },
    },
  });

  if (!response.status || response.status >= 400) {
    if (response.status === 401 && apiUrl.startsWith(loopBaseUri) && request.headers && retryCount < MAX_RETRY_COUNT) {
      const authHeaders = await refreshAuthHeaders?.();

      return fetchJson(path, {
        ...request,
        headers: {
          ...request.headers,
          ...authHeaders,
        },
        retryCount: retryCount + 1,
      });
    }
    throw new HttpStatusException(response.status, (responseBody as { error?: string })?.error || response.statusText);
  }
  return responseBody as T;
}

async function pushAndFetchJson<T>(
  path: string,
  {
    method,
    body,
    headers,
    ...options
  }: Omit<RequestInit, "body"> & {
    body: object;
  },
): Promise<T> {
  return await fetchJson(path, {
    ...options,
    method,
    body: JSON.stringify(body),
    headers: {
      ...headers,
      ...contentTypeApplicationJsonUTF8,
    },

    redirect: "follow",
  });
}

async function uploadBlob<T>(url: string, { onProgress = console.debug, content }: Upload) {
  return new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const contentType = content.type;

    xhr.open("PUT", url, true);
    xhr.setRequestHeader("Content-Type", contentType);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentage = (event.loaded / event.total) * 100;

        const progress = {
          loaded: event.loaded,
          total: event.total,
          percentage,
        };

        onProgress(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(new Error(`File upload failed: ${xhr.statusText}`));
      }

      console.debug({
        url,
        request: {
          headers: {
            "Content-Type": contentType,
          },
        },
        response: {
          headers: xhr.getAllResponseHeaders(),
          responseBody: xhr.responseText,
          status: { code: xhr.status, text: xhr.statusText },
        },
      });
    };

    xhr.onerror = () => {
      reject(new Error(`Error uploading file: ${xhr.statusText}`));

      console.debug({
        url,
        request: {
          headers: {
            "Content-Type": contentType,
          },
        },
        response: {
          headers: xhr.getAllResponseHeaders(),
          responseBody: xhr.responseText,
          status: { code: xhr.status, text: xhr.statusText },
        },
      });
    };

    xhr.send(content);
  });
}
