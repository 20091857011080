import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function SectionIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path
        d="M15.1787 3.77528L15.5701 3.38388L17.4494 5.26316L12.0875 10.625H10.2083V8.74573L15.1787 3.77528Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="square"
        fill="none"
      />
      <path
        d="M8.74996 11.453V10.2025C8.6133 10.2064 8.47461 10.2083 8.3339 10.2083C6.33466 10.2083 4.74047 9.80856 3.65166 9.41263C3.37303 9.31131 3.12764 9.21029 2.91724 9.11639V6.97363C2.94811 6.98511 2.97933 6.9966 3.01089 7.00807C4.27077 7.46621 6.08238 7.91667 8.3339 7.91667C8.5562 7.91667 8.77421 7.91228 8.98783 7.90392L10.3598 6.53195C9.73828 6.61584 9.0615 6.66667 8.3339 6.66667C5.90921 6.66667 4.04892 6.10227 2.91724 5.63035C2.1042 5.29131 1.66724 5 1.66724 5V16.6667C1.66724 16.6667 4.16724 18.3333 8.3339 18.3333C12.5006 18.3333 15.0006 16.6667 15.0006 16.6667V9.77452L13.7506 11.0245V12.4497C13.5402 12.5436 13.2948 12.6446 13.0161 12.746C11.9273 13.1419 10.3331 13.5417 8.3339 13.5417C6.33466 13.5417 4.74047 13.1419 3.65166 12.746C3.37303 12.6446 3.12764 12.5436 2.91724 12.4497V10.4712C3.01541 10.5098 3.11784 10.5486 3.22448 10.5874C4.42734 11.0248 6.16648 11.4583 8.3339 11.4583C8.4744 11.4583 8.61309 11.4565 8.74996 11.453ZM13.4433 13.9207C13.55 13.8819 13.6524 13.8431 13.7506 13.8046V15.9273C13.508 16.0447 13.1902 16.1843 12.8026 16.3253C11.7708 16.7005 10.2491 17.0833 8.3339 17.0833C6.41876 17.0833 4.89703 16.7005 3.86525 16.3253C3.47764 16.1843 3.15983 16.0447 2.91724 15.9273V13.8046C3.01541 13.8431 3.11784 13.8819 3.22448 13.9207C4.42734 14.3581 6.16648 14.7917 8.3339 14.7917C10.5013 14.7917 12.2405 14.3581 13.4433 13.9207Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </Icon>
  );
}
