import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function MoreIcon({ color = "#898989", ...props }: IconProps) {
  return (
    <Icon width="5" height="5" viewBox="0 0 17 4" {...props}>
      <path
        d="M8.49984 2.83268C8.96007 2.83268 9.33317 2.45959 9.33317 1.99935C9.33317 1.53911 8.96007 1.16602 8.49984 1.16602C8.0396 1.16602 7.6665 1.53911 7.6665 1.99935C7.6665 2.45959 8.0396 2.83268 8.49984 2.83268Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3748 2.83268C15.8351 2.83268 16.2082 2.45959 16.2082 1.99935C16.2082 1.53911 15.8351 1.16602 15.3748 1.16602C14.9146 1.16602 14.5415 1.53911 14.5415 1.99935C14.5415 2.45959 14.9146 2.83268 15.3748 2.83268Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.62484 2.83268C2.08507 2.83268 2.45817 2.45959 2.45817 1.99935C2.45817 1.53911 2.08507 1.16602 1.62484 1.16602C1.1646 1.16602 0.791504 1.53911 0.791504 1.99935C0.791504 2.45959 1.1646 2.83268 1.62484 2.83268Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
