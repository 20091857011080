import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useTranslation } from "@hooks";
import { useCallback } from "react";
import { useToast } from "@design-system";
import { PathCollection } from "@models/project";
import { Path } from "@models/backend";

export function useBackendPathCollection() {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.pathCollections");
  const toast = useToast();

  const createBackendPathCollection = useCallback(
    async (projectId: string, modelId: string, pathCollection: PathCollection) => {
      const paths = (await pathCollection.cpp.getState()) as Path[];
      const { usage, name, attributes } = pathCollection;

      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: {
          paths: paths,
          modelId: modelId,
          usage,
          name: name ?? "Collection",
          attributes,
        },
      };

      try {
        const response = await BackendApi.createPathCollection(request);
        pathCollection.id = response.id;
        return response;
      } catch (error) {
        console.log({ message: "Failed to upload path", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendPathCollection = useCallback(
    async (projectId: string, modelId: string, pathCollection: PathCollection) => {
      if (pathCollection.id === undefined) {
        return null;
      }
      const paths = (await pathCollection.cpp.getState()) as Path[];
      const { usage, name, attributes } = pathCollection;
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: pathCollection.id,
        },
        body: {
          paths,
          usage,
          name,
          attributes,
        },
      };

      try {
        await BackendApi.updatePathCollection(request);
      } catch (error) {
        console.log({ message: "Failed to update path", error, project: request.body });
        toast({
          title: t("update.toast.status.error.title"),
          children: t("update.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  const deleteBackendPathCollection = useCallback(
    async (projectId: string, modelId: string, pathCollectionId: string) => {
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: pathCollectionId,
        },
      };

      try {
        await BackendApi.deletePathCollection(request);
      } catch (error) {
        console.log({ message: "Failed to delete path", error, request });
        toast({
          title: t("delete.toast.status.error.title"),
          children: t("delete.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  return { createBackendPathCollection, updateBackendPathCollection, deleteBackendPathCollection };
}
