export default {
  translation: {
    project3D: {
      toolbarLeft: {
        title: "Toolbar - Left",
        tools: {
          select: { label: "Select" },
          pen: { label: "Pen" },
          section: { label: "Section" },
          column: { label: "Column" },
        },
      },
    },
    projectProperties: {
      propertiesSidebar: {
        tools: {
          meshSettings: {
            label: "mesh settings",
            units: {
              label: "Units",
              mm: { label: "mm" },
              cm: { label: "cm" },
              m: { label: "m" },
              in: { label: "in" },
            },
            sections: {
              label: "Auto-sections",
              regenerate: {
                label: "Regenerate",
              },
            },
            columns: {
              label: "Auto-columns",
              regenerate: {
                label: "Regenerate",
              },
            },
            flipNormals: { label: "Flip mesh normals" },
          },
          curveSettings: {
            label: "curve settings",
            direction: { label: "Flip direction" },
          },
          stitchDensity: {
            label: "stitch density",
            default: { label: "Inherit from mesh" },
            course: { label: "Course /cm" },
            wale: { label: "Wale /cm" },
          },
        },
      },
    },
    bottomToolbar: {
      actions: {
        export: {
          label: "Export",
          disabled: "No guide curve set",
        },
      },
    },
    home: {
      sidebar: {
        admin: "Admin",
        help: "Help & support",
        projects: "Projects",
        updates: "Updates",
      },
      projects: {
        emptyState: {
          title: "Welcome to LOOP",
          subtitle:
            "The future of 3D knitting starts now.<br/> If you have any questions, you can <Link href='https://airtable.com/appZrtSy8nqllTBsN/pagZBJoHaLCJfxmvW/form'>contact us</Link>.",
          actions: {
            create: {
              label: "Create project",
            },
            updates: {
              label: "What's new",
            },
          },
        },
        toolbar: {
          sort: {
            label: "Order by",
            options: {
              createdDesc: "Most recent",
              createdAsc: "Oldest",
              lastUpdatedDesc: "Last edited",
            },
          },
          view: {
            label: "View",
          },
          filter: {
            label: "Filter",
          },
          createFolder: {
            label: "Create Folder",
          },
          createProject: {
            label: "New project",
          },
        },
        view: {
          attributes: {
            name: {
              label: "Project",
            },
            createdAt: {
              label: "Created",
            },
            updatedAt: {
              label: "Edited",
            },
            members: {
              label: "Members",
            },
          },
        },
        renamePanel: {
          attributes: {
            rename: {
              label: "Rename",
            },
            cancel: {
              label: "Cancel",
            },
          },
        },
      },
    },
    onboarding: {
      actions: {
        login: {
          label: "Log in",
        },
        register: {
          label: "Get started",
        },
      },
      title: "Variant3D.io",
    },
    underConstruction: {
      title: "Under Construction",
      subtitle: "Come back later to see what's here",
      callToAction: {
        label: "Back to Projects",
        path: "/projects",
      },
    },
    admin: {
      header: {
        members: {
          label: "members",
        },
      },
      views: {
        dashboard: {
          title: "Dashboard",
        },
        members: {
          title: "Members",
          toolbar: {
            title: "Members",
            filters: {
              status: {
                label: "Account Status",
                options: {
                  all: "All",
                  pending: "Pending",
                  active: "Active",
                  disabled: "Disabled",
                },
              },
              role: {
                label: "Role",
                options: {
                  all: "All",
                  guest: "Guest",
                  member: "Member",
                  admin: "Admin",
                },
              },
              lastActive: {
                label: "Last active",
                options: {
                  0: "All",
                  30: "More than 1 month ago",
                  91: "More than 3 months ago",
                  183: "More than 6 months ago",
                  365: "More than a year ago",
                },
              },
            },
            actions: {
              invite: {
                label: "Invite members",
                modal: {
                  header: "Invite other members to {{organization}}",
                  body: "You can invite members with their email address to join the {{organization}} organization.",
                  input: {
                    placeholder: "Email, comma separated",
                    options: {
                      guest: "Guest",
                      member: "Member",
                      admin: "Admin",
                    },
                  },
                  action: { label: "Invite" },
                },
              },
              download: {
                label: "Get CSV",
              },
            },
          },
        },
        activity: {
          title: "Activity",
        },
      },
    },
    project: {
      sidebar: {
        layers: {
          emptyState: "Upload a file to see curves here.",
          types: {
            closed: "Closed Curve",
            curve: "Curve",
            unnamed: "Unnamed",
          },
          groups: {
            guide: "KNIT DIRECTION",
            zone: "ZONES",
            unused: "UNUSED",
            section: "SECTIONS",
            column: "COLUMNS",
            reference: "REFERENCES",
          },
        },
        properties: {
          references: {
            sections: {
              meshSettings: {
                label: "Mesh Settings",
                fields: {
                  color: {
                    label: "Color",
                  },
                },
              },
            },
          },
        },
      },
      "overwrite-model": {
        toast: {
          title: "Your progress will be lost",
          description:
            "Importing a new file will reset the current workspace. Start a new project from the dashboard page to keep your work.",
          actions: {
            import: "Import anyway",
            cancel: "Cancel",
          },
        },
      },
      "upload-model": {
        modal: {
          header: "Import file",
          body: {
            label: {
              base: "Click to Import or Drag & Drop here",
              active: "Drag & Drop here",
            },
            description: "3D model (.obj)",
          },
        },
      },
    },
    hooks: {
      project: {
        exportBMP: {
          progress: {
            title: "Exporting knit-map",
            status: {
              "in-progress": {
                label: "Exporting...",
                knitProcess: "Preparing data for export",
                createProcess: "Uploading data to server",
                getProcess: "Generating knit-map",
                saveAs: "Exporting knit-map",
              },
              success: {
                label: "Knit-map exported successfully",
              },
              error: {
                title: "Something went wrong",
                label: "Export failed! Please retry",
                knitProcess: "The knit-map was not processed: {{message}}",
              },
            },
          },
        },
        calibration: {
          "section-curves": {
            error: {
              title: "Something went wrong",
              message:
                "Failed to compute section curves. Select a different knit direction curve or draw a new one. " +
                "<a href='https://www.notion.so/Knit-direction-e6bf627ff6424c488f41615d9dcb748c' target='_blank' style='text-decoration: underline'>Learn more</a>",
            },
          },
          "column-curves": {
            error: {
              title: "Something went wrong",
              message:
                "Failed to compute column curves. Select a different knit direction curve or draw a new one. " +
                "<a href='https://www.notion.so/Knit-direction-e6bf627ff6424c488f41615d9dcb748c' target='_blank' style='text-decoration: underline'>Learn more</a>",
            },
          },
        },
      },
      models: {
        load: {
          progress: {
            status: {
              "in-progress": {
                label: "Loading...",
                "path-collections": "Loading curves...",
              },
              success: {
                label: "Ready",
              },
              error: {
                label:
                  "The file you are importing cannot be used in LOOP. Make sure your mesh is:" +
                  '<ul style="padding:0 1rem">' +
                  "<li>triangulated</li>" +
                  "<li>a single-boundary mesh</li>" +
                  "<li>in .obj or .stl file format</li>" +
                  "</ul>",
              },
            },
          },
        },
        upload: {
          progress: {
            status: {
              "in-progress": {
                label: "Uploading...",
              },
              success: {
                label: "Uploaded",
              },
              error: {
                label: "Upload failed! Please retry",
                modelLoadLabel:
                  "The file you are importing cannot be used in LOOP. Make sure your mesh is:" +
                  '<ul style="padding:0 1rem">' +
                  "<li>triangulated</li>" +
                  "<li>a single-boundary mesh</li>" +
                  "<li>in .obj or .stl file format</li>" +
                  "</ul>",
              },
            },
          },
        },
      },
      pathCollections: {
        create: {
          toast: {
            status: {
              error: {
                title: "Failed to save path collection",
                description: "Failed to save path collection in project file! Please retry",
              },
            },
          },
        },
        update: {
          toast: {
            status: {
              error: {
                title: "Failed to save path collection",
                description: "Failed to save path collection in project file! Please retry",
              },
            },
          },
        },
        delete: {
          toast: {
            status: {
              error: {
                title: "Failed to delete path collection",
                description: "Failed to delete path collection in project file! Please retry",
              },
            },
          },
        },
      },
      projects: {
        create: {
          progress: {
            status: {
              "in-progress": {
                label: "Creating project...",
              },
              success: {
                label: "Project created",
              },
              error: {
                label: "Failed to create project! Please retry",
              },
            },
          },
        },
        delete: {
          progress: {
            status: {
              "in-progress": {
                label: "Deleting project...",
              },
              success: {
                label: "Project deleted",
              },
              error: {
                label: "Failed to delete project! Please retry",
              },
            },
          },
        },
      },
      sectionAnchors: {
        create: {
          toast: {
            status: {
              error: {
                title: "Failed to save section anchor",
                description: "Failed to save section anchor in project file! Please retry",
              },
            },
          },
        },
        update: {
          toast: {
            status: {
              error: {
                title: "Failed to save section anchor",
                description: "Failed to save section anchor in project file! Please retry",
              },
            },
          },
        },
        delete: {
          toast: {
            status: {
              error: {
                title: "Failed to delete section anchor",
                description: "Failed to delete section anchor in project file! Please retry",
              },
            },
          },
        },
      },
      columnAnchors: {
        create: {
          toast: {
            status: {
              error: {
                title: "Failed to save column anchor",
                description: "Failed to save column anchor in project file! Please retry",
              },
            },
          },
        },
        update: {
          toast: {
            status: {
              error: {
                title: "Failed to save column anchor",
                description: "Failed to save column anchor in project file! Please retry",
              },
            },
          },
        },
        delete: {
          toast: {
            status: {
              error: {
                title: "Failed to delete column anchor",
                description: "Failed to delete column anchor in project file! Please retry",
              },
            },
          },
        },
      },
    },
    applicationContextMenu: {
      menuLabel: "Menu",
      goToDashboard: "Go to dashboard",
      darkTheme: "Dark",
      lightTheme: "Light",
      classicTheme: "Classic",
      contactUs: "Contact us",
      logout: "Logout",
    },
    authentication: {
      errors: {
        Forbidden: "You don't have access to LOOP yet!<br/>Please contact your administrator.",
        "Internal Server Error": "Something went wrong!<br/>Please try again.",
        Unauthorized: "Your login session has expired<br/>Please log in again.",
      },
    },
    global: {
      info: {
        loggedIn: {
          description: "You’re currently logged in as {{email}}.",
          actions: {
            login: {
              prefix: "You might need to",
              description: "log in",
              suffix: "with a different email.",
            },
          },
        },
      },
      errors: {
        "Failed to fetch": "We are working to resolve the issue. Please try again later.",
        Forbidden: "You don't have access to this page, please contact your admin.",
        "Internal Server Error": "We are working to resolve the issue. Please try again later.",
        "Not Found": "The page you are looking for can’t be found.",
        "Login Required": "Your login session has expired, Please log in again.",
        Unauthorized: "Your login session has expired, Please log in again.",
      },
      goBack: "Go back",
      goHome: "Go to Home page",
      retry: "Reload",
    },
  },
};
