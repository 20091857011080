import "@/globals";
import { ColumnAnchor, Model, PathCollection, SectionAnchor } from "@models/project";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { BasePath, Path } from "@variant-tech/pattern-derivation";
import { exportMeshToGLB, getMeshFromMesh3DBase } from "./mesh";
import { defaultsDeep } from "lodash";
import { modelAttributes as defaultAttributes } from "@defaults";
export default async function exportBMP(
  model: Model,
  guideSource: BasePath,
  collections: PathCollection[],
  sectionAnchors: SectionAnchor[],
  columnAnchors: ColumnAnchor[],
  binary = true,
  debug = false,
): Promise<File> {
  const v3dApi = getV3dApi();
  const part = await v3dApi.Part.create(
    model.mesh3DBase,
    guideSource as Path,
    collections.map(({ cpp }) => cpp),
  );
  const modelAttributes = defaultsDeep(model?.attributes, defaultAttributes);
  await part.computeFieldValues();

  const wale = modelAttributes.stitchDensity.wale ?? 0.0;
  // Using the value 0.0, to revert to use a default value based on the mesh bounding box
  await part.computeIsolineCurves(1.0 / wale);

  const sectionAnchorPoints = sectionAnchors.map((sectionAnchor) => sectionAnchor.point);
  await part.setSectionAnchors(sectionAnchorPoints);

  const columnAnchorPoints = columnAnchors.map((columnAnchor) => columnAnchor.point);
  await part.setColumnAnchors(columnAnchorPoints);

  await part.computeIsolineSections(0.0);
  await part.computeIsolineZones();

  const extrasPayload = await part.getExtrasPayload();

  const partMesh3DBase = await part.getMesh3DBase();

  const partMesh = await getMeshFromMesh3DBase(partMesh3DBase);

  partMesh.geometry.userData = {
    ...extrasPayload,
    knitParameters: {
      stitchDensity: {
        default: modelAttributes.stitchDensity,
        zones: collections.map(({ attributes }) => attributes?.stitchDensity),
      },
    },
  };
  console.log(partMesh.geometry.userData);
  await partMesh3DBase.delete();
  await part.delete();

  return exportMeshToGLB(partMesh, "scene", binary, debug);
}
