import { modelAttributes as defaultAttributes } from "@defaults";
import { Units } from "@models/backend";
import { Model } from "@models/project";
import { arrayToRecord } from "@utils/project/collection";
import { scaleMesh } from "@utils/project/mesh.ts";
import { refreshPathCollections } from "@utils/project/model.ts";
import { createPathCollection } from "@utils/project/pathCollections";
import { defaultsDeep } from "lodash";
import { GetState, SetState } from "zustand";
import { ModelsStore, PathCollections } from "../types";

export const modelsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  models: [],
  showNormals: false,
  setShowNormals: (show: boolean) => set({ showNormals: show }),
  addModel: async (model: Model) => {
    const modelWithAttributes = {
      ...model,
      attributes: defaultsDeep(model.attributes, defaultAttributes),
    };
    const newCollection = await createPathCollection(model, "Path");
    set(({ models, pathCollections, sections, columns }: ModelsStore) => ({
      models: [...models, modelWithAttributes],
      pathCollections: {
        ...pathCollections,
        [model.id]: {
          collections: {},
          newCollection,
        },
      },
      sections: {
        ...sections,
        [model.id]: {
          sectionAnchors: [],
        },
      },
      columns: {
        ...columns,
        [model.id]: {
          columnAnchors: [],
        },
      },
      selectedObjects: [{ type: "model", id: model.id }],
    }));
  },
  addReferenceModel: (parentModelId: string, referenceModel: Model) => {
    set((state) => {
      const models = state.models.map((model) => {
        if (model.id === parentModelId) {
          const references = model.references ? [...model.references, referenceModel] : [referenceModel];
          return { ...model, references };
        } else {
          return model;
        }
      });

      return { models: [...models, referenceModel] };
    });
  },
  setModels: async (models: Model[]) => {
    const newCollections = await Promise.all(models.map((model) => createPathCollection(model, "Path")));
    const modelsWithAttributes = models.map(({ attributes, ...rest }) => ({
      ...rest,
      attributes: defaultsDeep(attributes, defaultAttributes),
    }));
    set(() => {
      const pathCollections: PathCollections = arrayToRecord(
        models,
        (model) => model.id,
        (model) => ({
          collections: {},
          newCollection: newCollections[models.findIndex((m) => m.id === model.id)],
        }),
      );
      return { models: modelsWithAttributes, pathCollections };
    });
  },
  updateModel: (id: string, update: Partial<Model>) =>
    set(({ models }: ModelsStore) => {
      const index = models.findIndex((model) => model.id === id);

      if (index === -1) {
        throw new Error(`updateModel: Model ${id} not found`);
      }

      const result = [...models];
      const model = { ...models[index], ...update };
      result[index] = model;

      if (model?.parentId) {
        const parentIndex = models.findIndex(({ id }) => id === model.parentId);

        if (parentIndex !== -1) {
          const parent = result[parentIndex];
          console.log("updating parent");
          result[parentIndex] = {
            ...parent,
            references: parent.references.map((reference) => (reference.id === id ? model : reference)),
          };
        }
      }

      return { models: result };
    }),
  updateModelUnit: async (modelId: Model["id"], unit: Model["unit"]) => {
    const { models, updateModel, dispatchPathEdit, dispatchPathNew } = get();
    const model = models.find(({ id }) => id === modelId);
    if (!model) return;

    const { mesh: oldMesh, mesh3DBase } = model;
    const scale = Units[unit];
    const { mesh } = await scaleMesh({ mesh: oldMesh, mesh3DBase }, scale);

    await refreshPathCollections(model.id, get().pathCollections, dispatchPathEdit, dispatchPathNew);

    return updateModel(modelId, {
      mesh,
      scale,
      unit,
    });
  },
  removeReferenceModel: async (modelId: string) => {
    set(({ models }: ModelsStore) => {
      const model = models.find(({ id }) => id === modelId);

      if (!model?.parentId) {
        return { models };
      }

      const parent = models.find(({ id }) => id === model.parentId);

      if (parent) {
        parent.references = parent.references.filter(({ id }) => id !== modelId);
      }

      return { models: models.filter(({ id }) => id !== modelId) };
    });
  },
});
