import { ToastId, useToast as useCToast } from "@chakra-ui/react";
import { ToastVariant } from "@design-system/component-styles";
import { Toast, ToastProps as DSToastProps } from "@design-system/components";
import { ReactNode, useCallback } from "react";

export type ToastProps = {
  id?: ToastId;
  title: string;
  icon?: ReactNode;
  variant?: ToastVariant;
  children?: ReactNode;
  footer?: ReactNode;
  duration?: number;
  isCloseable?: boolean;
  onClose?: () => void;
};

const position = "bottom-right";

const render = ({ title, icon, variant, onClose, children, footer, isCloseable }: DSToastProps) => (
  <Toast
    variant={variant}
    title={title}
    icon={icon}
    onClose={onClose}
    gap={2}
    footer={footer}
    isCloseable={isCloseable}
  >
    {children}
  </Toast>
);

export function useToast() {
  const cToast = useCToast();

  const toast = useCallback(
    ({ id, title, icon, variant, children, footer, duration, isCloseable, onClose: toastOnClose }: ToastProps) => {
      return cToast({
        id,
        position,
        duration: duration ?? null,
        title,
        icon,
        variant,
        render: ({ onClose: chakraOnClose, ...cToastProps }) => {
          return render({
            ...cToastProps,
            children,
            footer,
            isCloseable,
            onClose: () => {
              chakraOnClose();

              if (toastOnClose) {
                toastOnClose();
              }
            },
          });
        },
      });
    },
    [cToast],
  );

  const update = useCallback(
    (
      id: ToastId,
      {
        title,
        icon,
        variant,
        children,
        footer,
        isCloseable,
        onClose: toastOnClose,
      }: Partial<Omit<ToastProps, "id" | "duration">>,
    ) =>
      cToast.update(id, {
        title,
        icon,
        variant,
        render: ({ onClose: chakraOnClose, ...cToastProps }) =>
          render({
            ...cToastProps,
            children,
            footer,
            isCloseable,
            onClose: () => {
              chakraOnClose();

              if (toastOnClose) {
                toastOnClose();
              }
            },
          }),
      }),
    [cToast],
  );

  const close = useCallback((id: ToastId) => cToast.close(id), [cToast]);

  return (props: ToastProps) => {
    const toastId = toast(props);

    return {
      update: ({ ...updateProps }: Partial<Omit<ToastProps, "title">>) => {
        update(toastId, { ...props, ...updateProps });
      },
      close: () => close(toastId),
    };
  };
}
