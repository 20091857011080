import React, { useState, useCallback, useEffect, useRef } from "react";
import { Input, Text, Box, BoxProps } from "@chakra-ui/react";
import { spacing } from "@design-system";

export interface EditableInputProps extends Omit<BoxProps, "onChange"> {
  initialValue: string;
  textColor?: string;
  textVariant?: string;
  onChange?: (newValue: string) => void;
  isEditable?: boolean;
}

export const EditableInput: React.FC<EditableInputProps> = ({
  initialValue,
  textColor,
  textVariant,
  onChange,
  isEditable = true,
  ...boxProps
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isEditing) {
      setNewValue(initialValue);
    }
  }, [initialValue, isEditing]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing]);

  const handleValueChange = useCallback(() => {
    if (newValue !== initialValue) {
      setIsEditing(false);
      onChange?.(newValue);
    } else {
      setIsEditing(false);
    }
  }, [newValue, initialValue, onChange]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleValueChange();
    } else if (e.key === "Escape") {
      setNewValue(initialValue);
      setIsEditing(false);
    }
  };

  const handleDoubleClick = () => {
    if (isEditable) {
      setIsEditing(true);
    }
  };

  return (
    <Box height="20px" display="flex" alignItems="center" width="100%" overflow="hidden" {...boxProps}>
      {isEditing && isEditable ? (
        <Input
          ref={inputRef}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleValueChange}
          size="sm"
          width="100%"
          height="20px"
          padding={spacing.space["50"]}
          backgroundColor="#FFF"
          color="#242424"
          _hover={{ backgroundColor: "#FFF" }}
          _focus={{
            boxShadow: "none",
            borderColor: "#F75F2D",
            backgroundColor: "#FFF",
          }}
          sx={{
            "&::selection": {
              backgroundColor: "rgba(247, 95, 45, 0.2)",
              color: "inherit",
            },
          }}
        />
      ) : (
        <Text
          variant={textVariant}
          color={textColor}
          onDoubleClick={handleDoubleClick}
          isTruncated
          width="100%"
          padding={spacing.space["50"]}
          cursor={isEditable ? "text" : "default"}
        >
          {newValue}
        </Text>
      )}
    </Box>
  );
};
