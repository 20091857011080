import { useSemanticTokens } from "@design-system";
import { useDeepCompareEffect, usePath } from "@hooks";
import { PathCollection as PathCollectionBackend, Project } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { Vector3 as VP } from "@variant-tech/pattern-derivation";
import { Fragment, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { BezHandles } from "./BezHandles";
import { Curve } from "./curve";
import { SurfacePoint } from "./SurfacePoint";
import { workspace3dTokens } from "./workspace-3d-tokens";

export type PathProps = {
  project: Project;
  model: Model;
  collectionId?: string;
  collectionIdx?: number;
  collectionUsage?: PathCollectionBackend["usage"];
  pathIdx: number;
  lock?: boolean;
  isSelectionAllowed?: boolean;
};

export function Path({ project, model, collectionId, collectionUsage, pathIdx, isSelectionAllowed = true }: PathProps) {
  const {
    getNormalAndTangent,
    updatePoint,
    changeVector,
    removeVector,
    updateCurves,
    removePoints,
    getSegmentLengths,
    path,
  } = usePath(project, model, pathIdx, collectionId);

  const { points, curves, controlVectors, isLoop } = path;

  const [hoveredPoint, setHoveredPoint] = useState<VP>();
  const [selectedPoints, setSelectedPoints] = useState<Record<number, boolean>>({});
  const [segmentLengths, setSegmentLengths] = useState<number[]>();
  const { hoveredObject, setHoveredObject, selectedObjects, setSelectedObjects } = useModelsStore();

  const style = workspace3dTokens.path[collectionUsage ?? "unused"];
  const defaultColor = style.color;
  const selectedColor = useSemanticTokens().border.accent.secondary;

  const [color, setColor] = useState(defaultColor);
  const [lineWidth, setLineWidth] = useState(1.0);

  const isSelected = collectionId
    ? isSelectionAllowed && !!selectedObjects.find(({ id }) => id === collectionId)
    : true;
  const isNewPath = collectionId === undefined;
  const isHovered = !isSelected && isSelectionAllowed && hoveredObject?.id === collectionId;
  const showPoints = isNewPath || isSelected || points.length < 2;

  const opacity = isHovered || isSelected ? 1.0 : style.opacity;

  useEffect(() => {
    if (isNewPath) return;

    if (isSelected || isHovered) {
      setColor(selectedColor);

      if (isHovered) {
        setLineWidth(2.0);
      } else {
        setLineWidth(1.0);
      }
    } else {
      setColor(defaultColor);
      setLineWidth(1.0);
      setSelectedPoints({});
    }
  }, [selectedObjects, hoveredObject, isNewPath, isSelected, isHovered, selectedColor, defaultColor]);

  useDeepCompareEffect(() => {
    const getLength = async () => {
      const segmentLengths = await getSegmentLengths();
      setSegmentLengths(segmentLengths);
    };
    getLength();
  }, [points, controlVectors]);

  useHotkeys(["delete", "backspace"], async () => {
    await removeSelectedPoints();
  });

  useEffect(() => {
    setSelectedPoints({});
  }, [points]);

  const removeSelectedPoints = async () => {
    const toDelete: Array<number> = [];

    for (let i = points.length - 1; i >= 0; i--) {
      if (selectedPoints[i]) {
        toDelete.push(i);
      }
    }

    if (!isSelected && toDelete.length < 1) return;

    const removeAll = toDelete.length === points.length || (isSelected && toDelete.length === 0);
    await removePoints(toDelete, removeAll);
  };

  const pointViz = points.map((point, index) => {
    const neighbors: VP[] = [];
    if (points[index + 1] !== undefined) {
      neighbors.push(points[index + 1]);
    }
    if (points[index - 1] !== undefined) {
      neighbors.push(points[index - 1]);
    }

    // handle neighbors for closed paths
    if (index === 0 && isLoop) {
      neighbors.push(points[points.length - 1]);
    } else if (index === points.length - 1 && isLoop) {
      neighbors.push(points[0]);
    }

    return (
      <Fragment key={index}>
        <SurfacePoint
          hovered={hoveredPoint === point}
          setHovered={(hovered) => {
            if (hovered) {
              setHoveredPoint(point);
            } else {
              setHoveredPoint(undefined);
            }
          }}
          model={model}
          point={point}
          isVisible={showPoints}
          collectionId={collectionId}
          neighbors={neighbors}
          updatePoint={async (point: VP) => {
            await updatePoint(index, point);
            await updateCurves();
            if (collectionId) setSelectedObjects([{ type: "path", id: collectionId, modelId: model.id }]);
          }}
          setPointSelection={async (selected: boolean) => {
            const sel = selectedPoints;
            sel[index] = selected;
            setSelectedPoints(sel);
          }}
          removePoint={async () => {
            const removeAll = points.length === 1;
            await removePoints([index], removeAll);
          }}
          toggleBend={async () => {
            if (controlVectors[index][1].every((v) => v !== 0)) {
              await removeVector(index);
              await updateCurves();
            } else {
              const nAndT = await getNormalAndTangent(index);
              const updatedPathCPP = await changeVector(index, nAndT.second);
              await updateCurves(updatedPathCPP);
            }
            if (collectionId) setSelectedObjects([{ type: "path", id: collectionId, modelId: model.id }]);
          }}
        />
        {showPoints && (
          <BezHandles
            point={point}
            controlVectors={controlVectors[index]}
            getNormalAndTangent={async () => await getNormalAndTangent(index)}
            mesh={model.mesh}
            changeVector={async (vector: VP) => {
              if (vector) {
                const updatedPathCPP = await changeVector(index, vector);
                await updateCurves(updatedPathCPP);
                if (collectionId) setSelectedObjects([{ id: collectionId, type: "path", modelId: model.id }]);
              } else {
                await removeVector(index);
                await updateCurves();
              }
            }}
          />
        )}
      </Fragment>
    );
  });

  const pathSegments = curves?.map((curve, index) => {
    const segmentLength = segmentLengths ? segmentLengths[index] : 0;

    return (
      <Curve
        key={index}
        curve={curve}
        mesh={model.mesh}
        length={segmentLength}
        color={color}
        opacity={opacity}
        width={lineWidth}
        isHovered={isHovered}
        isSelected={isSelected}
        onHovered={(hovered) => {
          if (!collectionId || !isSelectionAllowed) {
            return;
          }

          if (hovered) {
            setHoveredObject({ type: "path", id: collectionId, modelId: model.id });
          } else {
            setHoveredObject(null);
          }
        }}
        onSelected={() => {
          if (collectionId && isSelectionAllowed)
            setSelectedObjects([{ type: "path", id: collectionId, modelId: model.id }]);
        }}
        userData={{ type: "path", id: collectionId, modelId: model.id }}
      />
    );
  });

  return (
    <>
      {pathSegments}
      {pointViz}
    </>
  );
}
