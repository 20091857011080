import { analytics } from "@integrations/analytics/analytics";
import { EventTrackingError } from "../integrations/analytics/analyticsErrorHandlers";
import { PROJECT_CREATED, PROJECT_CREATION_FAILED } from "./eventNames";
import { captureException } from "@sentry/react";

interface ProjectCreatedProperties {
  projectId?: string;
  organizationId: string;
  status: "started" | "success" | "failed";
  error?: string;
  source: string;
}

export function trackProjectCreated(properties: ProjectCreatedProperties): void {
  const eventName = properties.status === "failed" ? PROJECT_CREATION_FAILED : PROJECT_CREATED;

  analytics.track(eventName, properties).catch((error: Error) => {
    const trackingError = new EventTrackingError(eventName, error, { properties });
    captureException(trackingError);
  });
}
