import { IconButton, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { DropdownProps } from "./Dropdown.tsx";

export type IconDropdownProps = DropdownProps & {
  icon: JSX.Element;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
};

export function IconDropdown({
  isOpen,
  onOpen,
  onClose,
  placement,
  icon,
  label,
  children,
  variant = "dropdown",
  buttonStyleProps,
  menuListStyleProps,
  ...rest
}: IconDropdownProps) {
  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement={placement ?? "bottom-start"}
      variant={variant}
      {...rest}
    >
      <MenuButton
        as={IconButton}
        aria-label={typeof label === "string" ? label : ""}
        icon={icon}
        variant={variant}
        {...buttonStyleProps}
      />
      <MenuList {...menuListStyleProps}>{children}</MenuList>
    </Menu>
  );
}
