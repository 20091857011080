function toRem(px: number) {
  return px / 16.0 + "rem";
}

export const spacing = {
  space: {
    50: toRem(2),
    100: toRem(4),
    200: toRem(8),
    300: toRem(12),
    400: toRem(16),
    600: toRem(24),
    800: toRem(32),
    1600: toRem(64),
    2400: toRem(96),
    3200: toRem(128),
    3600: toRem(144),
    4000: toRem(160),
    4800: toRem(192),
    5000: toRem(200),
    5400: toRem(232),
    6400: toRem(256),
  },
};
