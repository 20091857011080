import { OrganizationMember } from "@auth";
import BackendApi from "@api/BackendApi.ts";

export function updateOrganizationMemberRole({
  headers,
  organizationId,
  member,
  memberRole,
}: {
  headers: { Authorization: string };
  organizationId: string;
  member: OrganizationMember;
  memberRole: OrganizationMember["memberRole"];
}) {
  return BackendApi.updateOrganizationMember({
    headers,
    params: {
      organizationId,
      memberId: member.id,
    },
    body: {
      memberRole,
    },
  });
}

export function updateOrganizationMemberStatus({
  headers,
  organizationId,
  member,
  memberStatus,
}: {
  headers: { Authorization: string };
  organizationId: string;
  member: OrganizationMember;
  memberStatus: OrganizationMember["memberStatus"];
}) {
  return BackendApi.updateOrganizationMember({
    headers,
    params: {
      organizationId,
      memberId: member.id,
    },
    body: {
      memberStatus,
    },
  });
}

export function removeOrganizationMember({
  headers,
  organizationId,
  member,
}: {
  headers: { Authorization: string };
  organizationId: string;
  member: OrganizationMember;
}) {
  return BackendApi.removeOrganizationMembers({ headers, params: { organizationId }, body: { ids: [member.id] } });
}
