import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function MeshIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="5" width="5" {...props}>
      <path
        d="M7.99996 2L5.26289 8M7.99996 2L2.66663 5M7.99996 2L13.3333 5M7.99996 2L10.3949 8M13.3333 5V11M13.3333 5L10.3949 8M13.3333 11L7.99996 14M13.3333 11L10.3949 8M7.99996 14L2.66663 11M7.99996 14L5.26289 8M7.99996 14L10.3949 8M2.66663 11V5M2.66663 11L5.26289 8M2.66663 5L5.26289 8M5.26289 8H10.3949"
        fill="transparent"
        stroke="currentColor"
        strokeWidth={1}
        strokeLinecap="square"
      />
    </Icon>
  );
}
