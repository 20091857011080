import { Box, Center, ResponsiveValue, Spacer, VStack } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing } from "@design-system";
import { ReactNode } from "react";

export function Container({
  header,
  children,
  footer,
  backgroundImage,
  ...props
}: {
  header?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  backgroundImage?: ResponsiveValue<string>;
} & StyleProps) {
  return (
    <Box h="100%" p={{ base: spacing.space[200], lg: spacing.space[800] }} {...props}>
      {header}
      <Center
        mt={{ base: spacing.space[200], lg: spacing.space[1600] }}
        w="full"
        h={{
          base: `calc(100% - (${spacing.space[400]} + ${spacing.space[400]}))`,
          lg: `calc(100% - (${spacing.space[400]} + ${spacing.space[2400]}))`,
        }}
      >
        <Box
          h="full"
          w="full"
          mt={{ base: 0, lg: spacing.space[1600] }}
          maxW="920px"
          backgroundImage={backgroundImage}
          backgroundPosition="50% 0"
          backgroundRepeat="no-repeat"
          backgroundSize="60rem"
        >
          <Center flex="1 1 auto" h="100%" flexDirection="column">
            <VStack maxH="50rem" pt="11rem">
              {children}
            </VStack>
            <Spacer />
            {footer}
          </Center>
        </Box>
      </Center>
    </Box>
  );
}
