import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function ExportIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path
        d="M16.875 10.625V16.875H3.125V10.625M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="square"
        fill="none"
      />
    </Icon>
  );
}
