import { checkForOcclusion } from "@fragments/project/workspace-3d/useOcclusionChecker.tsx";
import { BufferGeometry, Camera, Mesh, Vector3 } from "three";

export function computeSegmentsOcclusions(
  mesh: Mesh,
  vertices: Vector3[],
  camera: Camera,
): { occluded: boolean; vertices: Vector3[] }[] {
  const occlusions = vertices.map((vertex) => checkForOcclusion(mesh, vertex.toArray(), camera));
  const result: [boolean, number[]][] = [];
  let previousValue: boolean | undefined = undefined;
  let currentIndexes: number[] = [];

  for (let i = 0; i < occlusions.length; i++) {
    if (occlusions[i] !== previousValue) {
      if (currentIndexes.length > 0) {
        result.push([previousValue!, currentIndexes]);
      }

      currentIndexes = [i];
      previousValue = occlusions[i];
    } else {
      currentIndexes.push(i);
    }
  }

  if (currentIndexes.length > 0) {
    result.push([previousValue!, currentIndexes]);
  }

  return result
    .map(([occluded, indexes]) => ({
      occluded,
      vertices: indexes.map((i) => vertices[i]).filter((v) => v !== undefined),
    }))
    .filter(({ vertices }) => vertices.length);
}

export function toVertices(geometry: BufferGeometry) {
  const result: Vector3[] = [];
  const positions = geometry.attributes.position;

  for (let index = 0; index < positions.count; index++) {
    result.push(new Vector3().fromBufferAttribute(geometry.attributes.position, index));
  }

  return result;
}
