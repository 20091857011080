import { useState, useEffect, useRef } from "react";
import { Input, Button, VStack, HStack } from "@chakra-ui/react";
import { Modal } from "@design-system";
import { Project } from "@models/backend";
import { useTranslation } from "@hooks";

interface RenameProjectPanelProps {
  isOpen: boolean;
  onClose: () => void;
  currentName: string;
  onRename: (newName: string) => Promise<Project>;
}

export function RenameProjectPanel({ isOpen, onClose, currentName, onRename }: RenameProjectPanelProps) {
  const [newName, setNewName] = useState(currentName);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("home.projects.renamePanel.attributes");

  useEffect(() => {
    if (isOpen) {
      setNewName(currentName);
    }
  }, [isOpen, currentName]);

  const handleRename = async () => {
    await onRename(newName);
    onClose();
  };

  const handleClose = () => {
    setNewName(currentName);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} header="Rename project" closable={true} closeOnOverlayClick={false}>
      <VStack spacing={4} align="stretch">
        <Input
          ref={initialFocusRef}
          placeholder={currentName}
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          autoFocus
        />
        <HStack spacing={2} justify="flex-end">
          <Button variant="outline" onClick={handleClose}>
            {t("cancel.label")}
          </Button>
          <Button variant="primary" onClick={handleRename} isDisabled={!newName || newName === currentName}>
            {t("rename.label")}
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
}
