import { Organization } from "@auth";
import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { MembersView } from "@fragments/members";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function getTabIndex(location: { pathname: string }, routePrefix: string) {
  switch (location.pathname) {
    case `${routePrefix}/members`:
      return 1;
    default:
      return 0;
  }
}

//https://www.figma.com/file/IrSHApht4p7tXsNxouLqAf/LOOP-UI?type=design&node-id=12369-335072&mode=design&t=u9B0sehzBwRI7JT4-0
export function AdminViews({ routePrefix, organization }: { routePrefix: string; organization: Organization }) {
  // const { t } = useTranslation("admin.views");
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(getTabIndex(location, routePrefix));

  useEffect(() => {
    setTabIndex(getTabIndex(location, routePrefix));
  }, [location, routePrefix]);

  return (
    <Tabs tabIndex={tabIndex} h="100%">
      {/*<TabList>*/}
      {/*  <Tab as={Link} to={`${routePrefix}/members`}>*/}
      {/*    {t("members.title")}*/}
      {/*  </Tab>*/}
      {/*</TabList>*/}

      <TabPanels h="100%">
        <TabPanel p={0} h="100%">
          <MembersView organization={organization} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
