import { useSemanticTokens } from "@design-system";
import { ReactNode } from "react";
import { useDropzone } from "react-dropzone";

interface ViewportDropzoneProps {
  onDrop: (file: File[]) => Promise<void>;
  children: ReactNode;
}

export function ViewportDropzone({ onDrop, children }: ViewportDropzoneProps) {
  const {
    border: {
      accent: { primary: borderAccentPrimary },
    },
  } = useSemanticTokens();

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      "model/obj": [".obj"],
      "model/gltf-binary": [".glb"],
      "model/gltf+json": [".gltf"],
    },
  });

  return (
    <div
      {...getRootProps()}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
      {isDragActive && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: `1px solid ${borderAccentPrimary}`,
            pointerEvents: "none",
            transition: "all 0.1s ease-in-out",
            zIndex: 1000,
          }}
        />
      )}
    </div>
  );
}
