import { Box, useStyleConfig } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { DropBoxVariant } from "../component-styles/DropBoxStyleConfig";
import { ReactNode } from "react";

export type DropBoxProps = {
  variant?: DropBoxVariant;
  children?: ReactNode;
} & StyleProps;

export function DropBox(props: DropBoxProps) {
  const { variant, ...rest } = props;

  const styles = useStyleConfig("DropBox", { variant });

  return <Box __css={styles} {...rest} />;
}
