import { ModelAttributes } from "@models/backend";

export const modelAttributes: ModelAttributes = {
  stitchDensity: {
    course: 12.0,
    wale: 8.0,
  },
  sections: {
    count: 10,
  },
  columns: {
    count: 10,
  },
};
