import { ModelsStore } from "../types";
import { ColumnAnchor } from "@models/project";
import { GetState, SetState } from "zustand";

export const columnsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  columns: {},
  columnsCount: 1,
  setColumnAnchors: (modelId: string, columnAnchors: ColumnAnchor[]) => {
    set(({ columns }) => {
      return {
        columns: {
          ...columns,
          [modelId]: {
            columnAnchors,
          },
        },
      };
    });
  },
  addColumnAnchor: (modelId: string, columnAnchor: ColumnAnchor) => {
    set(({ columns }: ModelsStore) => {
      const modelColumnAnchors = columns[modelId]?.columnAnchors || [];
      const changedColumns = [...modelColumnAnchors, columnAnchor];
      return {
        columns: {
          ...columns,
          [modelId]: {
            columnAnchors: changedColumns,
          },
        },
      };
    });
  },
  removeColumnAnchor: (modelId: string, id: string) => {
    set(({ columns }: ModelsStore) => {
      const modelColumnAnchors = columns[modelId].columnAnchors;
      const changedColumns = modelColumnAnchors.filter((columnAnchor) => columnAnchor.id !== id);
      return {
        columns: {
          ...columns,
          [modelId]: {
            columnAnchors: changedColumns,
          },
        },
      };
    });
  },
  updateColumnAnchor: (modelId: string, idx: number, update: Partial<ColumnAnchor>) => {
    let result: ColumnAnchor | undefined;
    set(({ columns }: ModelsStore) => {
      const modelColumnAnchors = columns[modelId].columnAnchors;
      const changedColumns = [...modelColumnAnchors];
      const mergedUpdate = { ...update };

      const columnAnchor = {
        ...modelColumnAnchors[idx],
        ...mergedUpdate,
      };

      changedColumns[idx] = columnAnchor;

      result = columnAnchor;
      return {
        columns: {
          ...columns,
          [modelId]: {
            columnAnchors: changedColumns,
          },
        },
      };
    });
    return result!;
  },
  getColumnAnchors: (modelId: string) => {
    return get().columns[modelId].columnAnchors;
  },
});
