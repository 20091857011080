import { useProgressToast, useTranslation } from "@hooks";
import { useModelLoader, usePathCollectionLoader } from "@hooks/project";
import { Project } from "@models/backend";
import { useEffect } from "react";
import { useColumnAnchorLoader, useSectionAnchorLoader } from "@hooks";
import { useModelsStore } from "@state";

type UseSavedModelProps = {
  triggerUploadModelModal: () => void;
  project: Project;
};
export function useLoadProject({ triggerUploadModelModal }: { triggerUploadModelModal?: () => void }) {
  const { clear: clearModels } = useModelsStore(({ clear }) => ({
    clear,
  }));
  const { t } = useTranslation("hooks.models.load.progress");
  const toast = useProgressToast();
  const { loadModel } = useModelLoader();
  const { loadPathCollections } = usePathCollectionLoader();
  const { loadSectionAnchors } = useSectionAnchorLoader();
  const { loadColumnAnchors } = useColumnAnchorLoader();

  return async (project: Project) => {
    clearModels();
    for (const model of project.models) {
      const { update } = toast(t, { title: model.name, status: "in-progress" });

      try {
        const loadedModel = await loadModel(model);
        update({
          message: t(`status.in-progress.path-collections`),
          status: "in-progress",
        });

        if (!model.parentId) {
          const pathCollections = await loadPathCollections(loadedModel, model);
          const guideSource = pathCollections?.find(({ usage }) => usage === "guide");
          if (guideSource) {
            await loadSectionAnchors(loadedModel, model, guideSource.paths[0]);
            await loadColumnAnchors(loadedModel, model, guideSource.paths[0]);
          }
        }

        update({ status: "success" });
      } catch (error) {
        console.log("Error loading model", error);
        update({ status: "error" });

        if (!model.parentId && triggerUploadModelModal) {
          triggerUploadModelModal();
        }
      }
    }

    if (!project.models?.length && triggerUploadModelModal) {
      triggerUploadModelModal();
    }
  };
}
export function useSavedModel({ project, triggerUploadModelModal }: UseSavedModelProps) {
  const loadProject = useLoadProject({ triggerUploadModelModal });
  useEffect(() => {
    loadProject(project);
  }, [project]);
}
