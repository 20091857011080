import { useAuth } from "@auth";
import { Project } from "@models/backend";
import { generateThumbnail } from "@utils/generateThumbnail";
import { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

export function useUpdateProjectThumbnail(project: Project, delay = 3000) {
  const { headers } = useAuth();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const updateProjectThumbnail = useDebouncedCallback(() => generateThumbnail({ project, canvasRef, headers }), delay);

  return { canvasRef, updateProjectThumbnail };
}
