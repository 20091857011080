import "@/globals";
import { useCalibrationCurves, useTranslation } from "@hooks";
import { useBackendPathCollection } from "@hooks/project/useBackendPathCollection";
import { Project } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { PathAction, PathActionType } from "@state/models/reducers/pathReducer";

import {
  BezierPath as CPPBezierPath,
  CPair as CP,
  HarmonicPath as CPPHarmonicPath,
  Path as CPPPath,
  Vector3 as VP,
} from "@variant-tech/pattern-derivation";
import { useCallback, useEffect, useMemo, useRef } from "react";

export const usePath = (project: Project, model: Model, pathIdx: number, collectionId?: string) => {
  const {
    changePathCollectionNew,
    pushPathCollection,
    dispatchPathNew,
    dispatchPathEdit,
    removePathCollection,
    pathCollections,
    getSectionAnchors,
    getColumnAnchors,
  } = useModelsStore();

  const { createBackendPathCollection, updateBackendPathCollection, deleteBackendPathCollection } =
    useBackendPathCollection();

  const { getAutoColumns, getAutoSections } = useCalibrationCurves({ project, model });

  const { t } = useTranslation("project.sidebar.layers");

  const pathCollection = useMemo(() => {
    return collectionId === undefined
      ? pathCollections[model.id].newCollection
      : pathCollections[model.id].collections[collectionId];
  }, [pathCollections, collectionId]);

  const noGuide = useMemo(() => {
    const collections = pathCollections[model.id].collections;
    for (const id in collections) {
      if (collections[id].usage === "guide") {
        return false;
      }
    }
    return true;
  }, [pathCollections]);

  const state = pathCollection.paths[pathIdx];
  const [collectionCpp, pathCpp] = useMemo(() => {
    return [pathCollection.cpp, state.cpp];
  }, [pathCollection.cpp, state.cpp]);

  const dispatch = useCallback(
    (action: PathAction) => {
      if (collectionId === undefined) {
        return dispatchPathNew(model.id, pathIdx, action);
      } else {
        return dispatchPathEdit(model.id, collectionId, pathIdx, action);
      }
    },
    [model.id, collectionId, dispatchPathNew, dispatchPathEdit],
  );

  const normalAndTangentCache = useRef<{ [key: string]: CP<VP, VP> }>({});
  useEffect(() => {
    normalAndTangentCache.current = {};
  }, [pathCpp]);

  const updateAfterPathChange = useCallback(async () => {
    const pathCollection = dispatch({ type: PathActionType.SET_STATE, value: await pathCpp.getState() });
    if (pathCollection.usage === "guide") {
      const currentSectionAnchors = getSectionAnchors(model.id);
      const numAutoSections = model.attributes.sections?.count || 0;

      const currentColumnAnchors = getColumnAnchors(model.id);
      const numAutoColumns = model.attributes.columns?.count || 0;

      (await getAutoSections(pathCollection.paths[0], numAutoSections, currentSectionAnchors)) ?? [];
      (await getAutoColumns(pathCollection.paths[0], numAutoColumns, currentColumnAnchors)) ?? [];
    }
    await updateBackendPathCollection(project.id, model.id, pathCollection);
  }, [dispatch, pathCpp, getAutoColumns, getAutoSections, getSectionAnchors]);

  const updateCurves = useCallback(
    async (updatedPath?: CPPPath | CPPBezierPath | CPPHarmonicPath) => {
      const pathCppToUse = updatedPath ?? pathCpp;
      dispatch({ type: PathActionType.SET_CURVES, value: await pathCppToUse.getCurveSegmentsAsFloats() });
    },
    [dispatch, pathCpp],
  );
  const addPoint = useCallback(
    async (index: number, point: VP) => {
      dispatch({ type: PathActionType.ADD_POINT, index, point });
      await pathCpp.addPoint(index, point);
      await updateAfterPathChange();
    },
    [dispatch, pathCpp, updateAfterPathChange],
  );
  const appendPoint = useCallback(
    async (point: VP) => {
      dispatch({ type: PathActionType.ADD_LAST_POINT, point });
      await pathCpp.appendPoint(point);
      await updateAfterPathChange();
    },
    [dispatch, pathCpp, updateAfterPathChange],
  );
  const removePoint = useCallback(
    async (index: number) => {
      dispatch({ type: PathActionType.REMOVE_POINT, index });
      // NOTE: the try / catch here is just temporary while i debug the process that calls removePoint
      try {
        await pathCpp.removePoint(index);
        await updateAfterPathChange();
      } catch {
        console.log("couldn't remove point @ index ", index);
      }
    },
    [dispatch, pathCpp, updateAfterPathChange],
  );
  const removePoints = useCallback(
    async (orig: Array<number>, removeAll = false) => {
      if (removeAll) {
        if (pathCollection.id === undefined) {
          console.log("couldn't remove pathCollection; no collection id");
          return;
        }
        removePathCollection(model.id, pathCollection.id);
        deleteBackendPathCollection(project.id, model.id, pathCollection.id);
        return;
      }

      const indexes = orig.slice();
      indexes.sort((a: number, b: number) => {
        return b - a;
      });
      dispatch({ type: PathActionType.REMOVE_POINTS, indexes });
      await Promise.all(indexes.map((index) => pathCpp.removePoint(index)));
      await updateAfterPathChange();
      await updateCurves();
    },
    [dispatch, pathCpp, updateAfterPathChange],
  );
  const close = useCallback(async () => {
    if (pathCpp["__type"] === "HarmonicPath") return;
    dispatch({ type: PathActionType.CLOSE });
    await pathCpp.close();
  }, [dispatch, pathCpp]);

  const updatePoint = useCallback(
    async (index: number, point: VP) => {
      dispatch({ type: PathActionType.MOVE_POINT, index, point });
      await pathCpp.updatePoint(index, point);
      await updateAfterPathChange();
    },
    [dispatch, pathCpp, updateAfterPathChange],
  );

  const getTotalLength = useCallback(async () => {
    return await pathCpp.totalLength();
  }, [pathCpp]);

  const getSegmentLengths = useCallback(async () => {
    return await pathCpp.getSegmentLengths();
  }, [pathCpp]);

  const getAllSegmentsLength = useCallback(async () => {
    return await collectionCpp.getAllSegmentsLength();
  }, [collectionCpp]);

  const checkAndConvertToHarmonic = useCallback(async () => {
    if (pathCpp["__type"] === "BezierPath") throw new Error("Can't convert from a BezierPath");
    if (pathCpp["__type"] === "Path") {
      const pathCppToUse = await collectionCpp.convertToHarmonicPath(pathIdx);
      dispatch({ type: PathActionType.UPDATE_CPP, cpp: pathCppToUse, pathType: "HarmonicPath" });
      return pathCppToUse;
    }
    return pathCpp;
  }, [dispatch, pathCpp]);

  const checkAndConvertToBezier = useCallback(async () => {
    if (pathCpp["__type"] === "HarmonicPath") throw new Error("Can't convert from a HarmonicPath");
    if (pathCpp["__type"] === "Path") {
      const pathCppToUse = await collectionCpp.convertToBezierPath(pathIdx);
      dispatch({ type: PathActionType.UPDATE_CPP, cpp: pathCppToUse, pathType: "BezierPath" });
      return pathCppToUse;
    }
    return pathCpp;
  }, [dispatch, pathCpp]);

  const changeVector = useCallback(
    async (index: number, vector: VP) => {
      const pathCppToUse = await checkAndConvertToBezier();
      dispatch({ type: PathActionType.CHANGE_VECTOR, index, vector });
      await pathCppToUse.changeVector(index, vector);
      dispatch({ type: PathActionType.SET_STATE, value: await pathCppToUse.getState() });
      await updateBackendPathCollection(project.id, model.id, pathCollection);
      return pathCppToUse;
    },
    [dispatch, pathCpp, checkAndConvertToBezier],
  );

  const removeVector = useCallback(
    async (index: number) => {
      if (pathCpp["__type"] === "HarmonicPath" || pathCpp["__type"] === "Path")
        throw new Error("Can't remove vector from a non-bezier Path");
      dispatch({ type: PathActionType.REMOVE_VECTOR, index });
      await pathCpp.removeVector(index);
      await updateAfterPathChange();
    },
    [dispatch, pathCpp],
  );

  const getNormalAndTangent = useCallback(
    async (index: number, point?: VP) => {
      return await pathCpp.getNormalAndTangent(index, point);
    },
    [pathCpp],
  );

  const closeAndPushPath = useCallback(
    async (closePath?: boolean) => {
      if (closePath) {
        await close();
      }
      await updateCurves();

      const usage = closePath ? "zone" : noGuide ? "guide" : null;
      const idx = Object.keys(pathCollections[model.id]?.collections ?? {}).length;
      const layerName = (closePath ? t("types.closed") : t("types.curve")) + ` ${idx}`;

      const pathCollection = changePathCollectionNew(model.id, { usage, name: layerName });
      const response = await createBackendPathCollection(project.id, model.id, pathCollection);

      pushPathCollection(model.id, response.id);

      if (pathCollection.usage === "guide") {
        const currentSectionAnchors = getSectionAnchors(model.id);
        const numAutoSections = model.attributes.sections?.count || 0;

        const currentColumnAnchors = getColumnAnchors(model.id);
        const numAutoColumns = model.attributes.columns?.count || 0;

        (await getAutoSections(pathCollection.paths[0], numAutoSections, currentSectionAnchors)) ?? [];
        (await getAutoColumns(pathCollection.paths[0], numAutoColumns, currentColumnAnchors)) ?? [];
      }
    },
    [
      pushPathCollection,
      updateCurves,
      close,
      noGuide,
      pathCollections,
      getAutoColumns,
      getAutoSections,
      getSectionAnchors,
    ],
  );

  const flipPath = useCallback(async () => {
    await pathCpp.flip();
    await updateAfterPathChange();
    await updateCurves();
  }, [pathCpp, updateCurves, updateAfterPathChange]);

  return {
    path: state,
    addPoint,
    appendPoint,
    removePoint,
    removePoints,
    updatePoint,
    changeVector,
    removeVector,
    updateCurves,
    pushPath: closeAndPushPath,
    checkAndConvertToBezier,
    checkAndConvertToHarmonic,
    getNormalAndTangent,
    getTotalLength,
    getSegmentLengths,
    getAllSegmentsLength,
    flipPath,
  };
};
