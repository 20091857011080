import BackendApi from "@api/BackendApi";
import { InitAuth } from "@auth/InitAuth.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { AuthHeaders, User } from "./AuthContext";

function useGetAccessToken() {
  const { error: auth0Error, getAccessTokenSilently, isAuthenticated, isLoading: isAuth0Loading } = useAuth0();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();

  const refreshAccessToken = useCallback(async () => {
    try {
      const result = await getAccessTokenSilently();
      setAccessToken(result);
      setError(undefined);
      return result;
    } catch (error) {
      setAccessToken(undefined);
      setError(error as Error);
      throw error;
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      refreshAccessToken().finally(() => setIsLoading(false));
    }
  }, [isAuthenticated, refreshAccessToken]);

  return {
    accessToken,
    error: error || auth0Error,
    isLoading: isLoading || isAuth0Loading,
    refreshAccessToken,
  };
}

function computeAuthHeaders(accessToken: string) {
  return { Authorization: `Bearer ${accessToken}` };
}

export function useInitAuth0(): InitAuth {
  const { loginWithRedirect, logout } = useAuth0();
  const {
    accessToken,
    error: accessTokenError,
    isLoading: isAccessTokenLoading,
    refreshAccessToken,
  } = useGetAccessToken();
  const [{ headers, user, error }, setBackendState] = useState<{
    headers?: AuthHeaders["headers"];
    user?: User;
    error?: Error;
  }>({});

  useEffect(() => {
    if (accessToken) {
      const headers = computeAuthHeaders(accessToken);

      BackendApi.getUser({ headers: headers })
        .then((user) => setBackendState({ headers, user }))
        .catch((error) => setBackendState({ headers, error }));
    }
  }, [accessToken]);

  const refreshAuthHeaders = useCallback(() => refreshAccessToken().then(computeAuthHeaders), [refreshAccessToken]);

  return {
    accessToken,
    error: error || accessTokenError,
    headers,
    isAuthenticated: user !== undefined,
    isLoading: isAccessTokenLoading || (!!accessToken && !user && !error),
    loginWithRedirect,
    logout: () => logout({ logoutParams: { returnTo: window.location.origin } }),
    refreshAuthHeaders,
    user,
  };
}
