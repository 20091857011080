import BackendApi from "@api/BackendApi.ts";
import { AuthHeaders } from "@auth";
import { Project as ProjectData } from "@models/backend";
import { RefObject } from "react";

const THUMBNAIL_CONTENT_TYPE = "image/png";

export type GenerateThumbnailProps = {
  project: ProjectData;
  canvasRef: RefObject<HTMLCanvasElement>;
} & AuthHeaders;

export async function generateThumbnail({ project, canvasRef, ...options }: GenerateThumbnailProps) {
  const canvasElement = canvasRef.current;

  if (!canvasElement) {
    return;
  }

  canvasElement.toBlob(async (content) => {
    if (!content) {
      return;
    }

    await BackendApi.updateProjectThumbnail({
      params: { projectId: project.id },
      content,
      ...options,
    });
  }, THUMBNAIL_CONTENT_TYPE);
}
