import { OrganizationMembership } from "@auth/AuthContext.ts";
import { Flex, Image, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import { DataGrid, DataTable } from "@design-system";
import { ProjectCard, ProjectContextMenu, ProjectsEmptyState } from "@fragments";
import { getProjectPath, useTranslation } from "@hooks";
import { Project } from "@models/backend";
import { useProjectsState } from "@state";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import moment from "moment";
import { useState } from "react";
import { NavLink } from "react-router-dom";

export function ProjectsView({
  organization,
  projects: incomingProjects,
}: {
  organization: OrganizationMembership;
  projects: Project[];
}) {
  const { t } = useTranslation("home.projects.view.attributes");
  const { sort, setSort, viewMode } = useProjectsState();
  const [projects, setProjects] = useState<Project[]>(incomingProjects);

  const handleProjectUpdated = (updatedProject: Project) => {
    setProjects((projects) => projects.map((project) => (project.id === updatedProject.id ? updatedProject : project)));
  };

  const handleProjectDeleted = (deleteddProject: Project) => {
    setProjects((projects) => projects.filter((project) => project.id !== deleteddProject.id));
  };

  const columns: ColumnDef<Project>[] = [
    {
      accessorKey: "name",
      cell: ({ row: { original: value } }) => (
        <LinkBox as={Flex} gap={2} alignItems="center">
          {"thumbnail" in value && <Image src={value.thumbnail} alt={value.name} width="8" height="8" />}
          <Flex direction="column">
            <LinkOverlay as={NavLink} to={getProjectPath(value.id)}>
              <Text>{value.name}</Text>
            </LinkOverlay>
          </Flex>
        </LinkBox>
      ),
      header: t("name.label"),
    },
    {
      accessorKey: "createdAt",
      cell: ({
        row: {
          original: { createdAt },
        },
      }) => <Text>{moment(createdAt).fromNow()}</Text>,
      header: t("createdAt.label"),
    },
    {
      accessorKey: "updatedAt",
      cell: ({
        row: {
          original: { updatedAt },
        },
      }) => <Text>{moment(updatedAt).fromNow()}</Text>,
      header: t("updatedAt.label"),
    },
  ];

  if (!projects.length) {
    return <ProjectsEmptyState height="100%" organization={organization} />;
  }

  return viewMode === "grid" ? (
    <DataGrid
      columns={columns}
      data={projects}
      minChildWidth="23rem"
      px="1.5rem"
      pb="1.5rem"
      pt="1px"
      render={(row) => (
        <ProjectCard
          project={row.original}
          key={row.id}
          margin="-1px 0 0 -1px"
          onProjectUpdated={handleProjectUpdated}
          onProjectDeleted={handleProjectDeleted}
        />
      )}
      sorting={sort}
      w="full"
    />
  ) : (
    <DataTable
      columns={columns}
      data={projects}
      sorting={sort}
      setSorting={(sorting) => {
        const updater = sorting as (old: SortingState) => SortingState;
        setSort(updater(sort));
        return sorting;
      }}
      rowActions={({ original }, disclosureProps) => (
        <ProjectContextMenu
          project={original}
          onProjectUpdated={handleProjectUpdated}
          onProjectDeleted={handleProjectDeleted}
          {...disclosureProps}
        />
      )}
    />
  );
}
