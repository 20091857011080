import { resolveHexValue, useSemanticTokens } from "@design-system";
import { Grid as DreiGrid } from "@react-three/drei";
import { useModelsStore } from "@state/models";
import { useState, useEffect } from "react";
import { DoubleSide, Vector3 } from "three";

export function Grid() {
  const { models } = useModelsStore(({ models }) => ({ models }));
  const [gridPos, setGridPos] = useState<Vector3>(new Vector3(0, 0, 0));

  const gridColor = resolveHexValue(useSemanticTokens().surface.quaternary);

  useEffect(() => {
    let height = Infinity;
    const center: Vector3 = new Vector3();
    models.forEach((model) => {
      model.mesh.geometry.computeBoundingBox();
      if (model.mesh.geometry.boundingBox && model.mesh.geometry.boundingBox.min.y < height) {
        height = model.mesh.geometry.boundingBox.min.y;
        model.mesh.geometry.boundingBox.getCenter(center);
      }
    });

    setGridPos(new Vector3(center.x, height, center.z));
  }, [models]);

  return (
    <>
      {/* <gridHelper position={[gridPos.x, gridPos.y, gridPos.z]} args={[40, 10, gridColor, gridColor]} /> */}
      <DreiGrid
        name="grid"
        position={[gridPos.x, gridPos.y, gridPos.z]}
        args={[50, 50]}
        cellSize={5}
        sectionSize={5}
        cellColor={gridColor}
        sectionColor={gridColor}
        sectionThickness={0.75}
        cellThickness={0.75}
        fadeDistance={250}
        infiniteGrid
        side={DoubleSide}
      />
    </>
  );
}
