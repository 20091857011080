import { headingStyleConfig } from "@design-system/component-styles/HeadingStyleConfig";
import { linkStyleConfig } from "@design-system/component-styles/LinkStyleConfig.ts";
import { progressStyleConfig } from "./ProgressStyleConfig";
import { accordionStyleConfig } from "./AccordionStyleConfig";
import { buttonStyleConfig } from "./ButtonStyleConfig";
import { checkboxStyleConfig } from "./CheckboxStyleConfig";
import { containerStyleConfig } from "./ContainerStyleConfig";
import { formStyleConfig } from "./FormStyleConfig";
import { iconStyleConfig } from "./IconStyleConfig";
import { inputStyleConfig } from "./InputStyleConfig";
import { menuStyleConfig } from "./MenuStyleConfig";
import { switchStyleConfig } from "./SwitchStyleConfig";
import { separatorStyleConfig } from "./SeparatorStyleConfig";
import { tableStyleConfig } from "./TableStyleConfig";
import { tabsStyleConfig } from "./TabsStyleConfig";
import { ToastVariant, toastStyleConfig } from "./ToastStyleConfig";
import { textStyleConfig } from "./TextStyleConfig";
import { toggleButtonStyleConfig } from "./ToggleButtonStyleConfig";
import { toolbarStyleConfig } from "./ToolbarStyleConfig";
import { tooltipStyleConfig } from "./TooltipStyleConfig";
import { dropBoxStyleConfig } from "./DropBoxStyleConfig";

export default {
  Accordion: accordionStyleConfig,
  Button: buttonStyleConfig,
  DropBox: dropBoxStyleConfig,
  Checkbox: checkboxStyleConfig,
  Container: containerStyleConfig,
  Form: formStyleConfig,
  Heading: headingStyleConfig,
  Icon: iconStyleConfig,
  Input: inputStyleConfig,
  Link: linkStyleConfig,
  Menu: menuStyleConfig,
  Progress: progressStyleConfig,
  Separator: separatorStyleConfig,
  Switch: switchStyleConfig,
  Table: tableStyleConfig,
  Toolbar: toolbarStyleConfig,
  Tabs: tabsStyleConfig,
  Toast: toastStyleConfig,
  Text: textStyleConfig,
  ToggleButton: toggleButtonStyleConfig,
  ToggleIconButton: toggleButtonStyleConfig,
  Tooltip: tooltipStyleConfig,
};

export type { ToastVariant };
