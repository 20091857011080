import { Button, ButtonGroup } from "@chakra-ui/react";
import { Dropdown, DropdownItem, IconDropdown, Separator, spacing, useThemeTokens } from "@design-system";
import { useCreateProject, useTranslation } from "@hooks";
import { ProjectSortingState } from "@state";
import equal from "fast-deep-equal/es6";
import { GridIcon, ListIcon } from "./icons";
import { ProjectsToolbarState, useProjectsToolbarState } from "./state";

function SortAction() {
  const { t } = useTranslation("home.projects.toolbar.sort");
  const { sort, setSort } = useProjectsToolbarState();
  const values: { key: string; value: ProjectSortingState }[] = [
    { key: "lastUpdatedDesc", value: [{ id: "updatedAt", desc: true }] },
    { key: "createdDesc", value: [{ id: "createdAt", desc: true }] },
    { key: "createdAsc", value: [{ id: "createdAt", desc: false }] },
  ];
  const selectedValue = values.find((it) => equal(it.value, sort)) ?? values[0];

  return (
    <Dropdown
      label={t(`options.${selectedValue.key}`)}
      buttonStyleProps={{ width: "9rem" }}
      menuListStyleProps={{ minW: "9rem" }}
    >
      {values.map((value) => (
        <DropdownItem
          key={value.key}
          value={value.value}
          selectedValue={selectedValue.value}
          label={t(`options.${value.key}`)}
          setSelectedValue={setSort}
        />
      ))}
    </Dropdown>
  );
}

function ViewAction() {
  const { t } = useTranslation("home.projects.toolbar.view");
  const { viewMode, setViewMode } = useProjectsToolbarState();
  const icons: Record<ProjectsToolbarState["viewMode"], JSX.Element> = {
    grid: <GridIcon />,
    list: <ListIcon />,
  };
  const {
    dropDownItem: {
      icon: { color },
    },
  } = useThemeTokens();

  return (
    <IconDropdown icon={icons[viewMode]} label={t("label")}>
      <DropdownItem
        icon={<ListIcon color={color} />}
        value="list"
        selectedValue={viewMode}
        label="Show as list"
        setSelectedValue={setViewMode}
      />

      <DropdownItem
        icon={<GridIcon color={color} />}
        value="grid"
        selectedValue={viewMode}
        label="Show as grid"
        setSelectedValue={setViewMode}
      />
    </IconDropdown>
  );
}

//https://www.figma.com/file/0gXtyY1FbJM49ZvaF8zxGb/Design-system?type=design&node-id=2088-20672&mode=design&t=MSmN3jYByqmbdpCJ-4
export function ProjectsToolbar({ organizationId }: { organizationId: string }) {
  const { createAndNavigateToProject } = useCreateProject();
  const { t } = useTranslation("home.projects.toolbar");

  return (
    <ButtonGroup gap={spacing.space["50"]} px={spacing.space["100"]}>
      <SortAction />
      <ViewAction />
      <Separator />
      <Button variant="secondary" size="lg" onClick={() => createAndNavigateToProject({ organizationId })} gap={2}>
        {t("createProject.label")}
      </Button>
    </ButtonGroup>
  );
}
