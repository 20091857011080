import BackendApi, { GetProjectRequest } from "@api/BackendApi";
import { useBackendApi } from "@api/useBackendApi";
import { ErrorScreen, Loading } from "@fragments";
import { ProjectContainer } from "@fragments/project/container/ProjectContainer.tsx";
import { CurrentProjectProvider } from "@fragments/project/container/ProjectContext.tsx";
import { useV3dApi } from "@utils/project/initV3dApi.ts";
import { useLoaderData } from "react-router-dom";

export default function Project() {
  const params = useLoaderData() as GetProjectRequest["params"];
  const { error: v3dError, isLoading: isV3dLoading, result: v3dApi } = useV3dApi();
  const {
    error: projectError,
    isLoading: isProjectLoading,
    result: project,
  } = useBackendApi(BackendApi.getProject, { params }, [params]);

  if (projectError) {
    return <ErrorScreen error={projectError} height="full" width="full" />;
  }

  if (v3dError) {
    return <ErrorScreen error={v3dError} height="full" width="full" />;
  }

  if (isProjectLoading || isV3dLoading) {
    return <Loading height="full" />;
  }

  if (!project) {
    return <ErrorScreen error={{ name: "HttpStatusException", message: "Not Found" }} height="full" width="full" />;
  }

  if (!v3dApi) {
    return <ErrorScreen error={{ name: "Error", message: "Internal Server Error" }} height="full" width="full" />;
  }

  return (
    <CurrentProjectProvider project={project} v3dApi={v3dApi}>
      <ProjectContainer project={project} />
    </CurrentProjectProvider>
  );
}
