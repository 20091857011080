import { useAuth } from "@auth";
import { Button, HStack, Image, Link, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { Logo, spacing, useSemanticTokens } from "@design-system";
import { Container } from "@fragments";
import { useTranslation } from "@hooks";
import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

function LoggedInFooter() {
  const { t } = useTranslation("global.info.loggedIn");
  const { user, logout } = useAuth();
  const {
    text: {
      accent: { primary: linkColor },
      tertiary: footerTextColor,
    },
  } = useSemanticTokens();

  return (
    !!user?.email?.length && (
      <Text variant="2xs-regular" color={footerTextColor} textAlign="center">
        {t("description", { email: user.email })}
        <br />
        {t("actions.login.prefix")}
        &nbsp;
        <Link onClick={() => logout()} color={linkColor}>
          {t("actions.login.description")}
        </Link>
        &nbsp;
        {t("actions.login.suffix")}
      </Text>
    )
  );
}

export function ErrorScreen({
  error,
  showHeader = true,
  actions,
  ...props
}: { error: Error; showHeader?: boolean; actions?: ReactNode } & StyleProps) {
  const { t } = useTranslation("global");
  const showFooter = error.message === "Forbidden";

  return (
    <Container
      backgroundImage="/error-screen-background.png"
      header={showHeader ? <Logo h={spacing.space[400]} /> : undefined}
      footer={showFooter && <LoggedInFooter />}
      {...props}
    >
      <Image
        src={`/errors/${error.message}.png`}
        fallbackSrc="/errors/Internal Server Error.png"
        h="5.25rem"
        my="4.55rem"
      />

      <Text
        textAlign="center"
        variant="l"
        dangerouslySetInnerHTML={{ __html: t(`errors.${error.message}`, {}, error.message) }}
        mt={`-${spacing.space[400]}`}
      />

      <HStack gap={spacing.space[600]} mt={spacing.space[600]}>
        {actions ?? (
          <>
            <Button onClick={() => window.location.reload()} variant="secondary" size="lg" w={spacing.space[3600]}>
              {t("retry")}
            </Button>

            <Button as={NavLink} to="/" variant="primary" size="lg" w={spacing.space[3600]}>
              {t("goHome")}
            </Button>
          </>
        )}
      </HStack>
    </Container>
  );
}
