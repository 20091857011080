import { WorkModel } from "@models/project";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { GLTFLoader, GLTF } from "three/examples/jsm/loaders/GLTFLoader.js";
import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";
import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Group,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Object3DEventMap,
  Scene,
} from "three";
import save from "file-saver";
import { Mesh3DBase } from "@variant-tech/pattern-derivation";

interface MeshOptions {
  flipNormals?: boolean;
}

function arrayBufferToBlob(buffer: ArrayBuffer) {
  return new Blob([buffer], { type: "application/octet-stream" });
}

function stringToBlob(text: string) {
  return new Blob([text], { type: "text/plain" });
}

function getLoader(ext: string) {
  switch (ext) {
    case "obj":
      return new OBJLoader();
    case "stl":
      return new STLLoader();
    case "gltf":
      return new GLTFLoader();
    case "glb":
      return new GLTFLoader();
    default:
      throw new Error(`Unsupported file extension: ${ext}`);
  }
}

export async function getGeoPositionsArray(url: string, ext: string) {
  const loader = getLoader(ext);

  const group = new Group();

  const object = await loader
    .loadAsync(url)
    .then((obj) => {
      return obj;
    })
    .catch((err) => {
      console.log("error loading model:", { err, url });
      return null;
    });

  // if model is an STL, we can return the geometry directly
  if (ext === "stl") {
    return (object as BufferGeometry).attributes.position.array;
  }

  // whereas for GLTF/GLB and OBJ we need to add it to a Group which we can then traverse
  if (ext === "gltf" || ext === "glb") {
    group.add((object as GLTF).scene);
  } // ... OBJ
  else {
    group.add(object as Object3D);
  }

  let positionsArray: ArrayLike<number> = [];

  // and then return the geometry from the first mesh we find as BufferGeometry
  group.traverse((child: Object3D<Object3DEventMap>) => {
    if ((child as unknown) instanceof Mesh) {
      let childGeometry = (child as Mesh).geometry as BufferGeometry;
      if (childGeometry.index) {
        childGeometry = childGeometry.toNonIndexed();
      }
      positionsArray = childGeometry.attributes.position.array;
    }
  });
  return positionsArray;
}

export async function getMeshFromMesh3DBase(mesh3DBase: Mesh3DBase) {
  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faceIndices = Array.from(await mesh3DBase.facesAsInt32Buffer());
  const geometry = new BufferGeometry();
  geometry.setIndex(faceIndices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  const mat = new MeshStandardMaterial({
    color: "lightgray",
    // wireframe: true,
  });

  const mesh = new Mesh(geometry, mat);
  return mesh;
}

export async function exportMeshToGLB(mesh: Mesh, name = "scene", binary = true, debug = false): Promise<File> {
  const exportScene = new Scene().add(mesh);

  const gltfExporter = new GLTFExporter();

  const options = {
    trs: false,
    onlyVisible: true,
    binary,
    maxTextureSize: 4096,
  };

  return new Promise<File>((resolve, reject) => {
    gltfExporter.parse(
      exportScene,
      function (result) {
        let blob;
        let filename;
        if (result instanceof ArrayBuffer) {
          blob = arrayBufferToBlob(result);
          filename = `${name}.glb`;
        } else {
          const output = JSON.stringify(result, null, 2);
          blob = stringToBlob(output);
          filename = `${name}.gltf`;
        }
        const file = new File([blob], filename, { type: blob.type });
        if (debug) {
          save(file, filename);
        }
        resolve(file);
      },
      reject,
      options,
    );
  });
}

export type RemeshParameters = {
  maxVertices: number;
  altVertices: number;
  minSize: number;
  minAngle: number;
};

const defaultParams: RemeshParameters = Object.freeze({
  maxVertices: 4500,
  altVertices: 3000,
  minSize: 1e-9,
  minAngle: 1e-5,
});

export async function getUsableMesh(model: WorkModel, params: RemeshParameters = defaultParams) {
  let mesh: Mesh3DBase | undefined;
  if ((await model.mesh3DBase.nVertices()) > params.maxVertices) {
    mesh = await model.mesh3DBase.pmpDecimate(params.altVertices);
    const quality = await mesh.meshQuality();
    if (quality[0] < params.minSize || quality[1] < params.minAngle) {
      const oldMesh = mesh;
      mesh = await mesh.pmpAdaptiveRemeshingAuto();
      oldMesh.delete();
    }
  } else {
    const quality = await model.mesh3DBase.meshQuality();
    if (quality[0] < params.minSize || quality[1] < params.minAngle) {
      mesh = await model.mesh3DBase.pmpAdaptiveRemeshingAuto();
    }
  }
  if (mesh) {
    const quality = await mesh.meshQuality();
    const nVertices = await mesh.nVertices();
    if (nVertices > params.maxVertices || quality[0] < params.minSize || quality[1] < params.minAngle) {
      throw new Error(
        "Could not generate a usable mesh: nVertices= " +
          nVertices +
          ", minSize= " +
          quality[0] +
          ", minAngle= " +
          quality[1],
      );
    }
    const treeMesh = await getMeshFromMesh3DBase(mesh);
    return exportMeshToGLB(treeMesh);
  }
}

export async function initMesh(bufferedPositions: ArrayLike<number>, options: MeshOptions = {}) {
  const v3dApi = getV3dApi();
  const { flipNormals = false } = options;

  const mesh3DBase = await v3dApi.createMesh3DBaseFromBufferedPositions(
    bufferedPositions as Float32Array,
    3,
    flipNormals,
  );

  console.log(`Loaded mesh with ${await mesh3DBase.nVertices()} vertices and ${await mesh3DBase.nFaces()} faces`);

  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faceIndices = Array.from(await mesh3DBase.facesAsInt32Buffer());
  const geometry = new BufferGeometry();
  geometry.setIndex(faceIndices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  const mat = new MeshStandardMaterial({
    color: "lightgray",
    side: DoubleSide,
  });

  const mesh = new Mesh(geometry, mat);
  return { mesh3DBase, mesh };
}

export async function getMeshPayload(path: string, fileName: string, options: MeshOptions = {}) {
  const ext = fileName.split(".").pop() as string;

  const bufferedPositions = await getGeoPositionsArray(path, ext);
  const { mesh3DBase, mesh } = await initMesh(bufferedPositions, options);

  return { bufferedPositions, mesh, mesh3DBase };
}

export async function scaleMesh({ mesh3DBase }: WorkModel, scale: number) {
  await mesh3DBase.setScale(scale, scale, scale);

  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faceIndices = Array.from(await mesh3DBase.facesAsInt32Buffer());
  const geometry = new BufferGeometry();
  geometry.setIndex(faceIndices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  const mat = new MeshStandardMaterial({
    color: "lightgray",
    side: DoubleSide,
  });

  const mesh = new Mesh(geometry, mat);
  return { mesh, mesh3DBase };
}
