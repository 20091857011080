import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function CircleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 6 6" height="0.375rem" width="0.375rem" {...props}>
      <circle cx="3" cy="3" r="3" fill="currentColor" />
    </Icon>
  );
}
