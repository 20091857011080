import { defineStyleConfig, SystemStyleInterpolation } from "@chakra-ui/react";
import { semanticTokens, utility } from "@design-system/semantic-tokens";
import { spacing } from "@design-system/spacing";
import { text } from "@design-system/text";

const primary: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  background: semanticTokens(theme, colorMode).surface.invert.accent.primary,
  color: semanticTokens(theme, colorMode).text.invert.primary,
  _hover: {
    background: `linear-gradient(0deg, ${utility.secondary.white} 0%, ${utility.secondary.white} 100%), ${
      semanticTokens(theme, colorMode).surface.invert.accent.primary
    }`,
    _disabled: {
      background: semanticTokens(theme, colorMode).surface.invert.accent.primary,
    },
  },
});

const secondary: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  background: semanticTokens(theme, colorMode).surface.secondary,
  color: semanticTokens(theme, colorMode).text.accent.primary,
  _hover: {
    background: semanticTokens(theme, colorMode).surface.tertiary,
  },
  selected: {
    background: `linear-gradient(0deg, ${utility.secondary.darkGray} 0%, ${utility.secondary.darkGray} 100%), ${
      semanticTokens(theme, colorMode).surface.tertiary
    }`,
  },
});

const classicSecondary: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  background: semanticTokens(theme, colorMode).surface.classic.secondary,
  color: semanticTokens(theme, colorMode).text.accent.primary,
  _hover: {
    background: semanticTokens(theme, colorMode).surface.classic.tertiary,
  },
  selected: {
    background: `linear-gradient(0deg, ${utility.secondary.darkGray} 0%, ${utility.secondary.darkGray} 100%), ${
      semanticTokens(theme, colorMode).surface.classic.tertiary
    }`,
  },
});

const outline: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  borderColor: semanticTokens(theme, colorMode).border.border,
  borderWidth: "1px",
  color: semanticTokens(theme, colorMode).text.primary,
  _hover: {
    background: semanticTokens(theme, colorMode).surface.secondary,
  },
  _active: {
    background: semanticTokens(theme, colorMode).surface.secondary,
  },
});

const classicOutline: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  borderColor: semanticTokens(theme, colorMode).border.classic.primary,
  borderWidth: "1px",
  color: semanticTokens(theme, colorMode).text.classic.primary,
  _hover: {
    background: semanticTokens(theme, colorMode).surface.classic.secondary,
  },
  _active: {
    background: semanticTokens(theme, colorMode).surface.classic.secondary,
  },
});

const dropdown: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  ...text["xs-regular"],
  padding: "0.75rem",
  bg: semanticTokens(theme, colorMode).surface.secondary,
  color: semanticTokens(theme, colorMode).icons.secondary,
  _hover: {
    bg: semanticTokens(theme, colorMode).surface.tertiary,
    _disabled: { bg: semanticTokens(theme, colorMode).surface.tertiary },
  },
  _active: {
    bg: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
    color: semanticTokens(theme, colorMode).icons.accent.primary,
  },
});

const classicDropdown: SystemStyleInterpolation = ({ theme, colorMode, ...rest }) => ({
  ...dropdown({ theme, colorMode, ...rest }),
  bg: semanticTokens(theme, colorMode).surface.classic.secondary,
  color: semanticTokens(theme, colorMode).icons.classic.secondary,
  _hover: {
    bg: semanticTokens(theme, colorMode).surface.classic.tertiary,
    _disabled: { bg: semanticTokens(theme, colorMode).surface.classic.tertiary },
  },
});

const toggleIconLayerOption: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  bg: "transparent",
  color: semanticTokens(theme, colorMode).icons.primary,
});

const toggleIconDropdown: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  ...text["xs-regular"],
  bg: semanticTokens(theme, colorMode).surface.classic.secondary,
  color: semanticTokens(theme, colorMode).icons.primary,
  height: "44px",
  _active: {
    bg: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
    color: semanticTokens(theme, colorMode).icons.accent.primary,
  },
});

const windowIcon: SystemStyleInterpolation = ({ theme, colorMode }) => ({
  bg: "transparent",
  color: semanticTokens(theme, colorMode).icons.primary,
  _hover: {
    bg: semanticTokens(theme, colorMode).surface.classic.secondary,
  },
  _active: {
    bg: semanticTokens(theme, colorMode).surface.invert.accent.secondary,
    color: semanticTokens(theme, colorMode).icons.accent.primary,
  },
});

export const buttonStyleConfig = defineStyleConfig({
  baseStyle: {
    borderRadius: 0,
    _disabled: {
      opacity: 0.5,
    },
  },
  sizes: {
    xs: {
      gap: spacing.space["50"],
      padding: `${spacing.space["50"]} ${spacing.space["200"]}`,
      ...text["2xs-regular"],
    },
    sm: {
      gap: spacing.space["50"],
      padding: spacing.space["200"],
      ...text["2xs-medium"],
    },
    md: {
      gap: spacing.space["50"],
      padding: spacing.space["300"],
      ...text["2xs-medium"],
    },
    lg: {
      gap: spacing.space["200"],
      padding: spacing.space["300"],
      height: "2.5rem",
      ...text["s-medium"],
    },
    xl: {
      gap: spacing.space["200"],
      padding: spacing.space["400"],
      ...text["m-semibold"],
    },
  },
  defaultProps: {
    size: "lg",
    variant: "primary",
  },
  variants: {
    primary,
    secondary,
    "classic-secondary": classicSecondary,
    outline,
    "classic-outline": classicOutline,
    inverse: classicOutline,
    dropdown,
    "classic-dropdown": classicDropdown,
    contextMenu: dropdown,
    windowIcon,
    toggleIconDropdown,
    toggleIconLayerOption,
  },
});

export type ButtonStyleVariant =
  | "primary"
  | "secondary"
  | "outline"
  | "classic-secondary"
  | "classic-outline"
  | "inverse";
