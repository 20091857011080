import { CoreAnalyticsConfig } from "../analyticsTypes";
import { ValidationError } from "../analyticsErrorHandlers";

export class AnalyticsConfig {
  static validateConfig(config: CoreAnalyticsConfig): void {
    if (!config.host || ![window.location.host].includes(config.host)) {
      throw new ValidationError("Invalid environment specified");
    }
  }
}
