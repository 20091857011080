import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function CloudErrorIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" height="24px" width="24px" fill="none" {...props}>
      <path
        d="M19 18.584C20.7659 17.8124 22 16.0503 22 14C22 11.2386 19.7614 9 17 9C16.4547 9 15.9299 9.08728 15.4386 9.24864C14.3696 6.75042 11.8892 5 9 5C5.13401 5 2 8.13401 2 12C2 14.3787 3.18652 16.4804 5 17.7453M14 16L12 18M12 18L10 20M12 18L10 16M12 18L14 20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </Icon>
  );
}
