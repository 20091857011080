import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useProgressToast, useProjectsNavigation, useTranslation } from "@hooks";
import { Project } from "@models/backend";
import { useCallback } from "react";
import { trackProjectCreated } from "@/events";

export function useCreateProject() {
  const { headers } = useAuth();
  const { navigateToProject } = useProjectsNavigation();
  const { t } = useTranslation("hooks.projects.create.progress");
  const toast = useProgressToast();

  const createAndNavigateToProject = useCallback(
    async (project: Partial<Project> & Pick<Project, "organizationId">) => {
      const request = {
        headers,
        body: {
          name: "Untitled",
          ...project,
        },
      };

      const { update } = toast(t, { title: request.body.name, status: "in-progress" });

      try {
        const response = await BackendApi.createProject(request);

        trackProjectCreated({
          projectId: response.id,
          organizationId: project.organizationId,
          status: "success",
          source: "projects-create-project-button",
        });

        update({ status: "success" });
        navigateToProject(response.id);
      } catch (error) {
        console.log({ message: "Failed to create project", error, project: request.body });

        trackProjectCreated({
          organizationId: project.organizationId,
          status: "failed",
          error: error instanceof Error ? error.message : "Unknown error",
          source: "projects-create-project-button",
        });

        update({ status: "error" });
      }
    },
    [headers, navigateToProject, t, toast],
  );

  return { createAndNavigateToProject };
}
