import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonOptions,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuProps,
  Spacer,
  Text,
  ThemingProps,
  useDisclosure,
} from "@chakra-ui/react";
import { SystemProps } from "@chakra-ui/styled-system";
import { ReactNode } from "react";

export type DropdownProps = {
  children: ReactNode;
  placement?: MenuProps["placement"];
  label: ReactNode;
  variant?: string;
  buttonStyleProps?: SystemProps & ThemingProps & ButtonOptions;
  menuListStyleProps?: SystemProps & ThemingProps;
};

export function Dropdown({
  label,
  children,
  buttonStyleProps,
  menuListStyleProps,
  variant = "dropdown",
  ...props
}: DropdownProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen} variant={variant} {...props}>
      <MenuButton as={Button} textAlign="start" variant={variant} {...buttonStyleProps}>
        <Flex alignItems="center">
          <Text>{label}</Text>
          <Spacer minW={1} />
          <Box
            boxSize="1rem"
            as={ChevronDownIcon}
            transition="transform 0.2s"
            transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
          />
        </Flex>
      </MenuButton>
      <MenuList {...menuListStyleProps}>{children}</MenuList>
    </Menu>
  );
}
