import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useTranslation } from "@hooks";
import { useCallback } from "react";
import { useToast } from "@design-system";
import { SectionAnchor } from "@models/project";

export function useBackendSectionAnchor() {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.sectionAnchors");
  const toast = useToast();

  const createBackendSectionAnchor = useCallback(
    async (projectId: string, modelId: string, sectionAnchor: SectionAnchor) => {
      const { point, name, attributes } = sectionAnchor;

      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: {
          point: point,
          modelId: modelId,
          name: name ?? "Section",
          attributes: attributes,
        },
      };

      try {
        const response = await BackendApi.createSectionAnchor(request);
        sectionAnchor.id = response.id;
        return response;
      } catch (error) {
        console.log({ message: "Failed to upload section anchor", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendSectionAnchor = useCallback(
    async (projectId: string, modelId: string, sectionAnchor: SectionAnchor) => {
      if (sectionAnchor.id === undefined) {
        return null;
      }
      const { point, name, attributes } = sectionAnchor;
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: sectionAnchor.id,
        },
        body: {
          point,
          name,
          attributes,
        },
      };

      try {
        await BackendApi.updateSectionAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to update section anchor", error, project: request.body });
        toast({
          title: t("update.toast.status.error.title"),
          children: t("update.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  const deleteBackendSectionAnchor = useCallback(
    async (projectId: string, modelId: string, sectionAnchorId: string) => {
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: sectionAnchorId,
        },
      };

      try {
        await BackendApi.deleteSectionAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to delete section anchor", error, request });
        toast({
          title: t("delete.toast.status.error.title"),
          children: t("delete.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  return { createBackendSectionAnchor, updateBackendSectionAnchor, deleteBackendSectionAnchor };
}
