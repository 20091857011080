export class AnalyticsError extends Error {
  public readonly timestamp: string;
  public readonly context?: Record<string, unknown>;

  constructor(message: string, error?: unknown, context?: Record<string, unknown>) {
    super(typeof error === "string" ? error : error instanceof Error ? error.message : message);
    this.name = "AnalyticsError";
    this.timestamp = new Date().toISOString();
    this.context = context;
  }
}

export class EventTrackingError extends AnalyticsError {
  constructor(eventName: string, originalError: unknown, context?: Record<string, unknown>) {
    super(
      `Failed to track event "${eventName}": ${originalError instanceof Error ? originalError.message : String(originalError)}`,
      context,
    );
    this.name = "EventTrackingError";
  }
}

export class UserIdentificationError extends AnalyticsError {
  constructor(userId: string, originalError: unknown, context?: Record<string, unknown>) {
    super(
      `Failed to identify user "${userId}": ${originalError instanceof Error ? originalError.message : String(originalError)}`,
      context,
    );
    this.name = "UserIdentificationError";
  }
}

export class ValidationError extends AnalyticsError {
  constructor(message: string, context?: Record<string, unknown>) {
    super(`Validation Error: ${message}`, context);
    this.name = "ValidationError";
  }
}
