import Analytics, { AnalyticsInstance, PageData } from "analytics";
import { CoreAnalyticsConfig } from "../analyticsTypes";
import { AnalyticsConfig } from "./AnalyticsConfig";
import { createAnalyticsPlugins } from "./analyticsPlugins";
import { captureException } from "@sentry/react";

export class CoreAnalytics {
  private analytics!: AnalyticsInstance;

  constructor(config: CoreAnalyticsConfig) {
    try {
      AnalyticsConfig.validateConfig(config);
      const plugins = createAnalyticsPlugins(config);
      this.analytics = Analytics({
        app: "loop",
        debug: config.debugMode,
        plugins,
      });
    } catch (error) {
      captureException(error, {
        tags: { action: "ANALYTICS_INIT" },
      });
    }
  }

  public async track(eventName: string, properties?: Record<string, any>): Promise<void> {
    try {
      return await this.analytics.track(eventName, properties);
    } catch (error) {
      captureException(error);
    }
  }

  public async identify(userId: string, traits?: Record<string, any>): Promise<void> {
    try {
      return await this.analytics.identify(userId, traits);
    } catch (error) {
      captureException(error);
    }
  }

  public async page(pageData: PageData): Promise<void> {
    try {
      return await this.analytics.page(pageData);
    } catch (error) {
      captureException(error);
    }
  }
}

export const setupCoreAnalytics = () => {
  const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;
  if (!amplitudeApiKey) {
    console.warn("[Analytics Wrapper] Amplitude API key not found. Amplitude tracking will be disabled.");
  }

  const host = window.location.host;

  try {
    return new CoreAnalytics({
      host,
      amplitude: amplitudeApiKey
        ? {
            apiKey: amplitudeApiKey,
            instanceName: import.meta.env.VITE_ANALYTICS_INSTANCE_NAME || "loop-dev",
          }
        : undefined,
      debugMode: import.meta.env.MODE === "development",
      errorLogger: (error: Error) => {
        captureException(error);
        console.error("[Analytics Error]:", {
          name: error.name,
          message: error.message,
          timestamp: new Date().toISOString(),
        });
      },
    });
  } catch (error) {
    captureException(error);
  }
};
