import { useLocation } from "react-router-dom";

type BaseRoute = { key: string; path?: string; index?: boolean };

export function useActiveRoute<T extends BaseRoute>(routes: T[], index = routes.find((p) => p.index)): T {
  const { pathname } = useLocation();
  const result = routes
    .filter(({ path }) => path && pathname.startsWith(path))
    .reduce((acc, current) => ((acc.path?.length ?? 0) > (current.path?.length ?? 0) ? acc : current), {} as T);
  return result ?? index;
}
