import { Box, Image, LinkBox, LinkOverlay, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { useSemanticTokens, useThemeTokens } from "@design-system";
import { ProjectContextMenu } from "@fragments";
import { getProjectPath, useTranslation } from "@hooks";
import { Project } from "@models/backend";
import moment from "moment/moment";
import { NavLink } from "react-router-dom";

interface ProjectCardProps extends Omit<StyleProps, keyof Project> {
  project: Project;
  onProjectUpdated?: (updatedProject: Project) => void;
  onProjectDeleted?: (deletedProject: Project) => void;
}

export function ProjectCard({ project: project, onProjectUpdated, onProjectDeleted, ...props }: ProjectCardProps) {
  const { t } = useTranslation("home.projects.view.attributes");
  const { border } = useThemeTokens();
  const semanticTokens = useSemanticTokens();
  const surfaceSecondary = semanticTokens.surface.secondary;
  const textSecondary = semanticTokens.text.secondary;
  const { isOpen, ...disclosureProps } = useDisclosure();

  return (
    <LinkBox
      as={VStack}
      borderColor={border.color}
      borderWidth={border.width}
      gap="1rem"
      h="18.75rem"
      p="1.5rem"
      position="relative"
      role="group"
      transition={"background-color 0.2s"}
      _hover={{
        backgroundColor: surfaceSecondary,
      }}
      {...props}
    >
      <Box h="12rem" w="20rem">
        {project?.thumbnail && <Image h="full" w="full" fit="contain" src={project.thumbnail} />}
      </Box>

      <Box w="20rem">
        <LinkOverlay as={NavLink} to={getProjectPath(project.id)}>
          <Text variant="m-bold" noOfLines={1} textOverflow="ellipsis">
            {project.name}
          </Text>
        </LinkOverlay>
        <Text variant="xs-regular" color={textSecondary}>
          {t("updatedAt.label")} {moment(project.updatedAt).fromNow()}
        </Text>
      </Box>

      <Box
        _groupHover={{ opacity: 1 }}
        opacity={isOpen ? 1 : 0}
        transition="opacity 0.2s"
        position="absolute"
        top="1rem"
        right="1rem"
        backgroundColor={surfaceSecondary}
      >
        <ProjectContextMenu
          project={project}
          onProjectUpdated={onProjectUpdated}
          onProjectDeleted={onProjectDeleted}
          isOpen={isOpen}
          {...disclosureProps}
        />
      </Box>
    </LinkBox>
  );
}
