import { useEffect, useState } from "react";

export function useAsyncLoader<T>(loader: () => Promise<T>) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const [result, setResult] = useState<T>();

  useEffect(() => {
    loader()
      .then((result) => setResult(result))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  });

  return { result, isLoading, error };
}
