import "@/globals";
import { useAsyncLoader } from "@hooks";
import { default as init } from "@variant-tech/pattern-derivation";

async function initV3dApi() {
  window.v3dApi = await init(false, false);
  return window.v3dApi;
}

export function getV3dApi() {
  if (!v3dApi) {
    throw new Error("V3dApi is not initialized");
  }

  return v3dApi;
}

export const useV3dApi = () => useAsyncLoader(initV3dApi);
