import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function GridIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="4" width="4" {...props}>
      <path
        d="M2.5 2.5V2H2V2.5H2.5ZM6.83333 2.5H7.33333V2H6.83333V2.5ZM6.83333 6.83333V7.33333H7.33333V6.83333H6.83333ZM2.5 6.83333H2V7.33333H2.5V6.83333ZM9.16667 2.5V2H8.66667V2.5H9.16667ZM13.5 2.5H14V2H13.5V2.5ZM13.5 6.83333V7.33333H14V6.83333H13.5ZM9.16667 6.83333H8.66667V7.33333H9.16667V6.83333ZM2.5 9.16667V8.66667H2V9.16667H2.5ZM6.83333 9.16667H7.33333V8.66667H6.83333V9.16667ZM6.83333 13.5V14H7.33333V13.5H6.83333ZM2.5 13.5H2V14H2.5V13.5ZM9.16667 9.16667V8.66667H8.66667V9.16667H9.16667ZM13.5 9.16667H14V8.66667H13.5V9.16667ZM13.5 13.5V14H14V13.5H13.5ZM9.16667 13.5H8.66667V14H9.16667V13.5ZM2.5 3H6.83333V2H2.5V3ZM6.33333 2.5V6.83333H7.33333V2.5H6.33333ZM6.83333 6.33333H2.5V7.33333H6.83333V6.33333ZM3 6.83333V2.5H2V6.83333H3ZM9.16667 3H13.5V2H9.16667V3ZM13 2.5V6.83333H14V2.5H13ZM13.5 6.33333H9.16667V7.33333H13.5V6.33333ZM9.66667 6.83333V2.5H8.66667V6.83333H9.66667ZM2.5 9.66667H6.83333V8.66667H2.5V9.66667ZM6.33333 9.16667V13.5H7.33333V9.16667H6.33333ZM6.83333 13H2.5V14H6.83333V13ZM3 13.5V9.16667H2V13.5H3ZM9.16667 9.66667H13.5V8.66667H9.16667V9.66667ZM13 9.16667V13.5H14V9.16667H13ZM13.5 13H9.16667V14H13.5V13ZM9.66667 13.5V9.16667H8.66667V13.5H9.66667Z"
        fill="currentColor"
      />
    </Icon>
  );
}
