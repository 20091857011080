import BackendApi from "@api/BackendApi.ts";
import { Organization, OrganizationMember, useAuth } from "@auth";
import { OrganizationMemberRoles, OrganizationMemberStatuses } from "@auth/AuthContext.ts";
import { Button, ButtonGroup, Flex, Heading, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { Dropdown, DropdownItem, Input, Popover, PopoverTrigger, Separator, useSemanticTokens } from "@design-system";
import { useMembersToolbarState } from "@fragments";
import { useTranslation } from "@hooks";
import { Parser } from "@json2csv/plainjs";
import { saveAs } from "file-saver";
import moment from "moment";
import { useState } from "react";

function InviteMembersAction({
  organization,
  onMembersAdd,
}: {
  organization: Organization;
  onMembersAdd?: (members: OrganizationMember[]) => void;
}) {
  const { headers } = useAuth();
  const { t } = useTranslation("admin.views.members.toolbar.actions.invite");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [emailAddresses, setEmailAddresses] = useState<string>();
  const [organizationMemberRole, setOrganizationMemberRole] = useState<OrganizationMember["memberRole"]>("member");
  const textSecondary = useSemanticTokens().text.secondary;
  const [isLoading, setIsLoading] = useState(false);

  async function inviteMembersToOrganization(
    organizationId: Organization["id"],
    emailAddresses: string[],
    memberRole: OrganizationMember["memberRole"],
  ) {
    setIsLoading(true);

    return Promise.all(
      emailAddresses
        .filter((email) => !organization.members.find((member) => member.email === email))
        .map((email) =>
          BackendApi.addOrganizationMember({
            headers,
            params: {
              organizationId,
            },
            body: {
              email,
              memberRole,
            },
          }),
        ),
    )
      .then((value) => onMembersAdd?.call(null, value))
      .finally(() => setIsLoading(false));
  }

  return (
    <PopoverTrigger content={t("label")} isOpen={isOpen} onClose={onClose} onOpen={onOpen} variant="secondary">
      <Popover onClose={onClose} header={t("modal.header", { organization: organization.name })}>
        <Text variant="xs-regular" color={textSecondary}>
          {t("modal.body", { organization: organization.name })}
        </Text>
        <ButtonGroup alignContent="stretch">
          <Input
            type="email"
            placeholder={t("modal.input.placeholder")}
            dropdown={
              <Dropdown
                label={t(`modal.input.options.${organizationMemberRole}`)}
                buttonStyleProps={{ textAlign: "end", minW: "unset" }}
                placement="bottom-end"
              >
                {OrganizationMemberRoles.map((value) => (
                  <DropdownItem
                    key={value}
                    value={value}
                    selectedValue={organizationMemberRole}
                    label={t(`modal.input.options.${value}`)}
                    setSelectedValue={setOrganizationMemberRole}
                  />
                ))}
              </Dropdown>
            }
            setValue={(v) => setEmailAddresses(v)}
            value={emailAddresses}
          />
          <Button
            variant="primary"
            onClick={() =>
              inviteMembersToOrganization(
                organization.id,
                emailAddresses?.split(",")?.map((emailAddress) => emailAddress.trim()) ?? [],
                organizationMemberRole,
              )
            }
            isLoading={isLoading}
            isDisabled={!emailAddresses?.split(",")?.length}
          >
            {t("modal.action.label")}
          </Button>
        </ButtonGroup>
      </Popover>
    </PopoverTrigger>
  );
}

function DownloadAction({ organization }: { organization: Organization }) {
  const { t } = useTranslation("admin.views.members.toolbar.actions.download");
  const [isLoading, setIsLoading] = useState(false);

  async function download() {
    setIsLoading(true);

    try {
      const opts = {};
      const parser = new Parser(opts);
      const csv = parser.parse(
        organization.members.map(({ name, email, memberStatus, memberRole, updatedAt }) => ({
          "Member name": name,
          "Email address": email,
          "Account status": memberStatus,
          "Member role": memberRole,
          "Last updated": moment(updatedAt).format("yyyy-MM-DD"),
        })),
      );

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `${organization.name} - Members - ${moment().format("yyyy-MM-DD")}.csv`, { autoBom: true });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Button variant="secondary" onClick={download} isLoading={isLoading} isDisabled={!organization.members}>
      {t("label")}
    </Button>
  );
}

type Filter<T> = {
  name: string;
  options: T[];
  selectedValue: T;
  setSelectedValue: (value: T) => void;
};

//https://www.figma.com/file/IrSHApht4p7tXsNxouLqAf/LOOP-UI?type=design&node-id=12369-335096&mode=design&t=Xar77klxV4qaPIml-4
export function MembersToolbar({
  organization,
  onMembersAdd,
  ...styles
}: {
  organization: Organization;
  onMembersAdd?: (members: OrganizationMember[]) => void;
} & StyleProps) {
  const { t } = useTranslation("admin.views.members.toolbar");
  const {
    filter: { role, status },
    setRoleFilter,
    setStatusFilter,
  } = useMembersToolbarState();

  const filters = [
    {
      name: "status",
      options: ["all", ...OrganizationMemberStatuses],
      selectedValue: status,
      setSelectedValue: setStatusFilter,
    } as Filter<string>,
    {
      name: "role",
      options: ["all", ...OrganizationMemberRoles],
      selectedValue: role,
      setSelectedValue: setRoleFilter,
    } as Filter<string>,
  ];

  return (
    <Flex direction="row" alignItems="center" {...styles}>
      <Flex minH="8rem"></Flex>
      <Heading as="h1" size="h1" textTransform="uppercase">
        {t("title")}
      </Heading>
      <Spacer />
      <ButtonGroup gap="2">
        {filters.map(({ name, options, selectedValue, setSelectedValue }) => (
          <>
            <Text variant="xs-regular" alignContent="center">
              {t(`filters.${name}.label`)}
            </Text>
            <Dropdown
              label={t(`filters.${name}.options.${selectedValue}`)}
              buttonStyleProps={{ minW: "unset" }}
              menuListStyleProps={{ minW: "9rem" }}
            >
              {options.map((value) => (
                <DropdownItem
                  value={value}
                  selectedValue={selectedValue}
                  label={t(`filters.${name}.options.${value}`)}
                  setSelectedValue={setSelectedValue}
                />
              ))}
            </Dropdown>
          </>
        ))}
        <Separator />
        <InviteMembersAction organization={organization} onMembersAdd={onMembersAdd} />
        <DownloadAction organization={organization} />
      </ButtonGroup>
    </Flex>
  );
}
