import { OrganizationMember, useAuth } from "@auth";
import { MenuItem, useClipboard } from "@chakra-ui/react";
import { IconDropdown, Separator } from "@design-system";
import {
  removeOrganizationMember,
  updateOrganizationMemberRole,
  updateOrganizationMemberStatus,
} from "@fragments/members/AdminMembersUtils.ts";
import { MoreIcon } from "@fragments/projects/view/icons";

export function MemberContextMenu({
  member,
  organizationId,
  onRemove,
  onUpdate,
}: {
  member: OrganizationMember;
  organizationId: string;
  onRemove?: (member: OrganizationMember) => void;
  onUpdate?: (member: OrganizationMember) => void;
}) {
  const { headers } = useAuth();
  const { onCopy } = useClipboard(member.email);
  const updateMember = (updatedMember: OrganizationMember) => {
    member.memberRole = updatedMember.memberRole;
    member.memberStatus = updatedMember.memberStatus;

    onUpdate?.apply(null, [member]);
  };
  const toggleAdminAccess = () =>
    updateOrganizationMemberRole({
      headers,
      organizationId,
      member,
      memberRole: member.memberRole === "admin" ? "member" : "admin",
    }).then(updateMember);
  const toggleActiveStatus = () =>
    updateOrganizationMemberStatus({
      headers,
      organizationId,
      member,
      memberStatus: member.memberStatus === "pending" || member.memberStatus === "disabled" ? "active" : "disabled",
    }).then(updateMember);
  const removeMemberFromOrganization = () =>
    removeOrganizationMember({
      headers: headers,
      organizationId: organizationId,
      member: member,
    }).then(() => onRemove?.apply(null, [member]));

  return (
    <IconDropdown label="more" icon={<MoreIcon />} variant="contextMenu">
      <MenuItem onClick={onCopy}>Copy email</MenuItem>
      {member.memberStatus === "active" && (
        <MenuItem onClick={toggleAdminAccess}>
          {member.memberRole === "admin" ? "Revoke admin access" : "Grant admin access"}
        </MenuItem>
      )}
      <MenuItem onClick={toggleActiveStatus}>
        {member.memberStatus === "active" ? "Disable" : member.memberStatus === "disabled" ? "Enable" : "Activate"}
      </MenuItem>
      <Separator variant="inverse" m={0} />
      <MenuItem onClick={removeMemberFromOrganization}>Remove</MenuItem>
    </IconDropdown>
  );
}
