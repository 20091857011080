import { Accordion, Button, Container, HStack, Input, Switch, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { useTranslation } from "@hooks";
import { Project as ProjectData } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { useCallback, useState } from "react";
import { FlipIcon } from "./icons";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";
import { useModelsStore } from "@state/models";
import { useBackendPathCollection, usePath } from "@hooks/project";
import { useShallow } from "zustand/react/shallow";

type PathCollectionProps = {
  modelId: Model["id"];
  pathCollectionId: NonNullable<PathCollection["id"]>;
  project: ProjectData;
} & StyleProps;

export function SidebarPathCollectionProperties({ modelId, pathCollectionId, project, ...props }: PathCollectionProps) {
  const { t } = useTranslation("projectProperties.propertiesSidebar.tools");
  const pathCollection = useModelsStore(
    useShallow(({ pathCollections }) => pathCollections[modelId]?.collections[pathCollectionId]),
  );
  const model = useModelsStore(useShallow(({ models }) => models.find(({ id }) => modelId === id)));
  const { updateBackendPathCollection } = useBackendPathCollection();
  const changePathCollection = useModelsStore(({ changePathCollection }) => changePathCollection);
  const { flipPath } = usePath(project, model!, 0, pathCollectionId);

  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const { border } = useThemeTokens();
  const pathCollectionCourse = pathCollection?.attributes?.stitchDensity?.course;
  const modelCourse = model?.attributes?.stitchDensity?.course;

  const [selectedCourse, setSelectedCourse] = useState<string>(
    pathCollectionCourse?.toFixed(3) ?? modelCourse?.toFixed(3) ?? "",
  );

  const saveCourse = useCallback(async () => {
    const course = isNaN(parseFloat(selectedCourse)) ? null : parseFloat(selectedCourse);
    const updated = changePathCollection(modelId, pathCollectionId, { attributes: { stitchDensity: { course } } });
    setSelectedCourse(course !== null ? course.toFixed(3) : "");
    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
  }, [selectedCourse, pathCollectionId, modelId]);

  if (!pathCollection) {
    return null;
  }
  const isZone = pathCollection.usage === "zone";
  const isGuide = pathCollection.usage === "guide";
  const isStitchDensityDefault: boolean = !pathCollection?.attributes?.stitchDensity;
  return (
    <Container variant="classic" borderTopWidth={border.width} {...props}>
      <Accordion variant="outline" allowMultiple defaultIndex={[0, 1]} paddingX={spacing.space["200"]} w="full">
        {isZone ? (
          <SidebarPropertiesSection borderTop="unset" title={t("stitchDensity.label")}>
            <HStack justifyContent="space-between" w={"100%"}>
              <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                {t("stitchDensity.default.label")}
              </Text>
              <Switch
                isChecked={isStitchDensityDefault}
                marginLeft="auto"
                size="sm"
                onChange={async (e) => {
                  console.log(e);
                  let updated: PathCollection[] = [];
                  if (e.target.checked) {
                    updated = changePathCollection(modelId, pathCollectionId, { attributes: { stitchDensity: null } });
                  } else {
                    updated = changePathCollection(modelId, pathCollectionId, { attributes: { stitchDensity: {} } });
                  }
                  setSelectedCourse(modelCourse?.toFixed(3) ?? "");
                  await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
                }}
              />
            </HStack>
            <HStack justifyContent="space-between" w={"100%"}>
              <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                {t("stitchDensity.course.label")}
              </Text>
              <Input
                disabled={isStitchDensityDefault}
                size="sm"
                w="50%"
                height={spacing.space["600"]}
                type="number"
                placeholder="0"
                onChange={(e) => setSelectedCourse(e.target.value)}
                onBlur={saveCourse}
                value={selectedCourse}
              />
            </HStack>
          </SidebarPropertiesSection>
        ) : null}
        {isGuide ? (
          <SidebarPropertiesSection borderTop="unset" title={t("curveSettings.label")}>
            <HStack justifyContent="space-between" w={"100%"}>
              <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                {t("curveSettings.direction.label")}
              </Text>
              <Button
                onClick={async () => {
                  await flipPath();
                }}
                size="xs"
                variant="outline"
                width={spacing.space["800"]}
              >
                <FlipIcon width={spacing.space["400"]} />
              </Button>
            </HStack>
          </SidebarPropertiesSection>
        ) : null}
      </Accordion>
    </Container>
  );
}
