import { useMemo, useCallback } from "react";
import { Box, Spacer } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { IconButton, spacing, Toolbar } from "@design-system";
import { ApplicationContextMenu } from "@fragments";
import { useBMPExporter, useTranslation } from "@hooks";
import { ColumnAnchor, Model, PathCollection, SectionAnchor } from "@models/project";
import { useModelsStore } from "@state/models";
import { recordToArray } from "@utils/project/collection";
import { BasePath } from "@variant-tech/pattern-derivation";
import { ExportIcon } from "./icons";
import { Tools3DToolbar } from "./Tools3DToolbar";
import { useHotkeys } from "react-hotkeys-hook";

function ExportButton(props: StyleProps) {
  const { t } = useTranslation("bottomToolbar.actions");
  const { exportBMP } = useBMPExporter();
  const models = useModelsStore((state) => state.models);
  const { columns, pathCollections, sections } = useModelsStore((s) => s);

  const [model, guideSource, collections, sectionAnchors, columnAnchors] = useMemo(() => {
    let guideSource: BasePath | undefined = undefined;
    let model: Model | undefined = undefined;
    let collections: PathCollection[] = [];
    let sectionAnchors: SectionAnchor[] = [];
    let columnAnchors: ColumnAnchor[] = [];
    if (models.length) {
      model = models[0];
      const pathCollectionsArray = recordToArray(pathCollections[model.id].collections);
      guideSource = pathCollectionsArray.find(({ usage }) => usage === "guide")?.paths[0]?.cpp;
      collections = pathCollectionsArray.filter(({ usage }) => usage === "zone");
      sectionAnchors = sections[model.id].sectionAnchors;
      columnAnchors = columns[model.id].columnAnchors;
    }
    return [model, guideSource, collections, sectionAnchors, columnAnchors];
  }, [models, pathCollections, sections, columns]);

  const disabled = !model || !guideSource;
  const exportFunction = useCallback(async () => {
    if (!model || !guideSource) return;
    await exportBMP(model, guideSource, collections, sectionAnchors, columnAnchors);
  }, [model, guideSource, collections, sections]);

  // TODO: set up a more permanent solution for debug hotkey + handler
  // e.g. change shortcut object shape to allow for multiple shortcuts + handlers
  // (currently supports multiple shortcuts, but not multiple handlers)
  const debugHotkeyEnabled = true;
  const exportFunctionDebug = useCallback(async () => {
    if (!model || !guideSource || !debugHotkeyEnabled) return;
    await exportBMP(model, guideSource, collections, sectionAnchors, columnAnchors, true, true);
  }, [model, guideSource, collections]);

  useHotkeys("ctrl+shift+e", exportFunctionDebug, { preventDefault: true });

  return (
    <IconButton
      onClick={exportFunction}
      label={t("export.label")}
      leftIcon={<ExportIcon width="1rem" />}
      shortcut={{
        keys: "ctrl+e",
      }}
      size="xs"
      variant="primary"
      isDisabled={disabled}
      disabledReason={t("export.disabled")}
      tooltipPlacement={"start"}
      {...props}
    />
  );
}

export function TopToolbar(props: StyleProps) {
  return (
    <Toolbar size="full" direction="row" px="1rem" justifyContent="space-between" {...props}>
      <Box ml={spacing.space[100]}>
        <ApplicationContextMenu showThemeButtons={false} showDashboardLink={true} showContactUs={true} />
      </Box>
      <Spacer />
      <Tools3DToolbar />
      <Spacer />
      <ExportButton height="1.5rem" />
    </Toolbar>
  );
}
