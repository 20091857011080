import { Model as ProjectModel, Unit, Units } from "@models/backend";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { getMeshPayload, scaleMesh } from "@utils/project/mesh";
import { useCallback } from "react";
import { v4 } from "uuid";

export function useModelLoader() {
  const { addModel, addReferenceModel, models } = useModelsStore(({ addModel, addReferenceModel, models }) => ({
    addModel,
    addReferenceModel,
    models,
  }));

  const loadModel = useCallback(
    async function (projectModel: ProjectModel) {
      const { id, url, name, filename: fileName, decimatedUrl, unit: bcUnit, attributes, parentId } = projectModel;
      const loaderUrl = decimatedUrl ?? url;
      const filename = decimatedUrl ? ".glb" : fileName;
      const flipNormals = attributes?.normals?.flipNormals ?? false;
      const { mesh, mesh3DBase } = await getMeshPayload(loaderUrl, filename, { flipNormals });

      function getModelUnit(): Unit {
        if (parentId!) {
          const parentModel = models.find((model) => model.id === parentId);
          if (parentModel) {
            return parentModel.unit;
          }
        }
        return bcUnit ?? "cm";
      }

      const unit = getModelUnit();
      const model: Model = {
        id,
        url,
        filename,
        name,
        showWireFrame: false,
        mesh,
        mesh3DBase,
        unit,
        parentId,
        scale: Units[unit],
        attributes: attributes ?? {},
        references: [],
      };

      if (unit !== "cm") {
        const { mesh: scaledMesh } = await scaleMesh({ mesh, mesh3DBase }, model.scale);
        model.mesh = scaledMesh;
      }

      if (parentId) {
        addReferenceModel(parentId, model);
      } else {
        await addModel(model);
      }

      return model;
    },
    [addReferenceModel, addModel, models],
  );

  const getDefaultModel = useCallback(
    async function () {
      const path = "/models/default_standardized.obj";
      const fileName = path.split("/").pop() as string;

      const model: ProjectModel = {
        id: v4(),
        url: path,
        filename: fileName,
        name: "default_standardized.obj",
        pathCollections: [],
        sectionAnchors: [],
        columnAnchors: [],
      };
      await loadModel(model);
    },
    [loadModel],
  );

  return { getDefaultModel, loadModel };
}
