import { Spacer, Text, VStack } from "@chakra-ui/react";
import { Modal, ModalProps, useSemanticTokens } from "@design-system";
import { useTranslation } from "@hooks";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { ImportIcon } from "./icons";

export type UploadModelModalProps = {
  onModelUpload: (uploadedModelFile: File) => void;
} & Omit<ModalProps, "children">;

export function UploadModelModal({ onModelUpload, ...modalWrapperProps }: UploadModelModalProps) {
  const { t } = useTranslation("project.upload-model.modal");
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, isFileDialogActive } = useDropzone();
  const isFileSelectionActive = isDragActive || isFileDialogActive;
  const {
    border: {
      accent: { primary: borderAccentPrimary },
    },
    icons: {
      primary: iconPrimary,
      classic: { secondary: iconSecondary },
    },
    surface: {
      classic: { primary: surfacePrimary, secondary: surfaceSecondary },
    },
    text: {
      classic: { primary: textPrimary, secondary: textSecondary },
    },
  } = useSemanticTokens();

  useEffect(() => {
    acceptedFiles.length && onModelUpload(acceptedFiles[0]);
  }, [acceptedFiles]);

  return (
    <Modal closable={false} header={t("header")} {...modalWrapperProps}>
      <VStack
        {...getRootProps({
          onFocus: (evt) => (evt.target.style.boxShadow = "none"),
        })}
        borderWidth={isFileSelectionActive ? 1 : 0}
        borderColor={borderAccentPrimary}
        backgroundColor={isFileSelectionActive ? surfaceSecondary : surfacePrimary}
        alignItems="center"
        minH="22.5rem"
        gap="1.5rem"
        boxShadow={isFileSelectionActive ? "md" : "none"}
        transition="all 0.1s ease-in-out"
      >
        <input {...getInputProps()} />
        <Spacer />
        <ImportIcon color={isFileSelectionActive ? iconSecondary : iconPrimary} />
        <VStack>
          <Text variant="m-semibold" color={textPrimary}>
            {isDragActive ? t("body.label.active") : t("body.label.base")}
          </Text>
          <Text variant="s-regular" color={textSecondary}>
            {t("body.description")}
          </Text>
        </VStack>
        <Spacer />
      </VStack>
    </Modal>
  );
}
