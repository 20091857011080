import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function HamburgerIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path d="M2.5 10H17.5M2.5 5H17.5M2.5 15H17.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="square" />
    </Icon>
  );
}
