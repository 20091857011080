import { Model } from "@models/project/Model";
import { Model as BackendModel } from "@models/backend";
import { useModelsStore } from "@state/models";
import { createPathCollectionFromState } from "@utils/project/pathCollections";

export function usePathCollectionLoader() {
  const { pushPathCollections } = useModelsStore(({ pushPathCollections }) => ({ pushPathCollections }));

  async function loadPathCollections(model: Model, projectModel: BackendModel) {
    const collections = await Promise.all(
      projectModel.pathCollections.map(async (pathCollection) => {
        const newCollection = await createPathCollectionFromState(model, pathCollection.paths);
        newCollection.id = pathCollection.id;
        newCollection.usage = pathCollection.usage;
        newCollection.name = pathCollection.name;
        newCollection.attributes = pathCollection.attributes;

        return newCollection;
      }),
    );
    pushPathCollections(model.id, collections);
    return collections;
  }

  return { loadPathCollections };
}
