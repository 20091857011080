import { Organization, OrganizationMember } from "@auth";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { DataTable, useSemanticTokens } from "@design-system";
import { MemberContextMenu } from "@fragments";
import { useMembersTableState } from "@fragments/members/table/state.ts";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import moment from "moment";

export function MembersTable({
  organization,
  members,
  setMembers,
  ...props
}: {
  organization: Organization;
  members: OrganizationMember[];
  setMembers: (value: ((prevState: OrganizationMember[]) => OrganizationMember[]) | OrganizationMember[]) => void;
} & StyleProps) {
  const { filter, sort, setSort } = useMembersTableState();
  const textSecondaryColor = useSemanticTokens().text.secondary;
  const columns: ColumnDef<OrganizationMember>[] = [
    {
      accessorKey: "name",
      cell: ({ row: { original } }) => (
        <Flex gap={2} alignItems="center">
          <Image src={original.avatar} alt={original.name} width="8" height="8" borderRadius="full" />
          <Flex direction="column">
            <Text>{original.name}</Text>
            <Text variant="xs-regular" color={textSecondaryColor}>
              {original.email}
            </Text>
          </Flex>
        </Flex>
      ),
      header: "Name",
    },
    {
      accessorKey: "memberStatus",
      cell: ({ row: { original } }) => <Text casing="capitalize">{original.memberStatus}</Text>,
      header: "Account Status",
      invertSorting: true,
      enableGlobalFilter: true,
    },
    {
      accessorKey: "memberRole",
      cell: ({ row: { original } }) => <Text casing="capitalize">{original.memberRole}</Text>,
      header: "Role",
      invertSorting: true,
      enableGlobalFilter: true,
    },
    {
      accessorKey: "updatedAt",
      cell: ({ row: { original } }) => <Text>{moment(original.updatedAt).fromNow()}</Text>,
      header: "Edited",
      invertSorting: true,
      enableGlobalFilter: true,
    },
  ];

  return (
    <Box {...props}>
      <DataTable
        columns={columns}
        data={members}
        globalFilter={filter}
        globalFilterFn={({ original }) => {
          console.log({ original });

          return !(
            (filter.role !== "all" && original.memberRole !== filter.role) ||
            (filter.status !== "all" && original.memberStatus !== filter.status)
          );
        }}
        sorting={sort}
        setSorting={(sorting) => {
          const updater = sorting as (old: SortingState) => SortingState;
          setSort(updater(sort));
          return sorting;
        }}
        rowActions={({ original, index }) => (
          <MemberContextMenu
            member={original}
            organizationId={organization.id}
            onRemove={() => {
              members.splice(index, 1);
              setMembers(new Array(...members));
            }}
            onUpdate={(member) => {
              const updated = new Array(...members);
              updated[index] = { ...member };
              setMembers(updated);
            }}
          />
        )}
      />
    </Box>
  );
}
