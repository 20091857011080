import BackendApi from "@api/BackendApi.ts";
import { useBackendApi } from "@api/useBackendApi.ts";
import { Organization } from "@auth";
import { Box, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "@hooks";

//www.figma.com/file/AqVNj8AylYRJMqkzmgDuWW/LOOP-UI-Playground?type=design&node-id=2515-373525&mode=design&t=XyYqZ8oWOzqPDsBZ-4
export function AdminHeader({ organization }: { organization: Organization }) {
  const { t } = useTranslation("admin.header");
  const { result } = useBackendApi(
    BackendApi.getOrganizationMembers,
    {
      params: { organizationId: organization.id },
    },
    [organization],
  );
  const { members } = result ?? {};

  return (
    <Box pt="1.25rem">
      <HStack gap="1.5rem" px="1.5rem" py="0.75rem">
        <Image
          src={organization.logo ?? `https://api.multiavatar.com/${organization.name}.png`}
          borderRadius="full"
          w="5rem"
          h="5rem"
        />
        <VStack gap="0.25rem" alignItems="start">
          <Heading as="h1">{organization.name}</Heading>
          <Text size="sm">
            {members?.length} {t("members.label")}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
}
