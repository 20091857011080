import { resolveHexValue, useSemanticTokens, utility } from "@design-system";
import { usePointerState } from "@hooks/project/usePointerState";
import { Model } from "@models/project";
import { Billboard, Circle, Line, ScreenSizer, useSelect } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useProjectState } from "@state/project";
import { Vector3 as VP } from "@variant-tech/pattern-derivation";
import { useCallback, useEffect, useRef, useState } from "react";
import { MeshBasicMaterial, Object3D, Vector3 } from "three";
import { ClickTarget } from "./ClickTarget";
import { useOcclusionChecker } from "./useOcclusionChecker";
import { workspace3dTokens } from "./workspace-3d-tokens";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";

export type SurfacePointProps = {
  point: VP;
  neighbors?: Array<VP>;
  model: Model;
  collectionId?: string;
  isVisible: boolean;
  updatePoint: (point: VP) => void;
  setPointSelection: (selected: boolean) => void;
  removePoint: () => void;
  toggleBend: () => void;
  hovered: boolean;
  setHovered: (hovered: boolean) => void;
};

export function SurfacePoint({
  point,
  neighbors,
  model,
  collectionId,
  isVisible,
  updatePoint,
  setPointSelection,
  removePoint,
  toggleBend,
  hovered,
  setHovered,
}: SurfacePointProps) {
  const [moving, setMoving] = useState(false);
  const [clickPos, setClickPos] = useState<Vector3>(new Vector3());
  const [pointerPos, setPointerPos] = useState<Vector3>(new Vector3());

  const { _3DToolbarSelection } = useProjectState();

  const selection = useSelect();
  const selected = selection.find((obj) => obj.userData.point === point);

  const screenSizerRef = useRef<Object3D>(null);
  const scale = screenSizerRef.current ? screenSizerRef.current.scale.x : 0;

  const accentTertiary = resolveHexValue(useSemanticTokens().icons.accent.tertiary);
  const neighborLineColor = useSemanticTokens().border.invert.secondary;
  const outerRadius = workspace3dTokens.surfacePoint.outerRadius + (selected ? 2 : 0);
  const innerRadius = workspace3dTokens.surfacePoint.innerRadius + (selected ? 2 : 0);
  const tolerance = workspace3dTokens.clickTarget.tolerance;

  const innerCircleMatRef = useRef<MeshBasicMaterial>(null);
  const outerCircleMatRef = useRef<MeshBasicMaterial>(null);

  const occluded = innerCircleMatRef.current?.depthTest == true;

  const [colors, setColors] = useState([utility.white, accentTertiary]);

  const quickLineMat = new LineMaterial({
    color: neighborLineColor,
    linewidth: 2,
    opacity: 0.5,
    transparent: true,
    depthTest: false,
  });

  useEffect(() => {
    if (selected) {
      if (occluded) {
        return; // only modify selection status of surface points that are not occluded
      }
      setColors([accentTertiary, utility.white]);
      setPointSelection(true);
    } else {
      setColors([utility.white, accentTertiary]);
      setPointSelection(false);
    }
  }, [accentTertiary, occluded, selected, selection]);

  useEffect(() => {
    if (hovered) {
      setColors([accentTertiary, accentTertiary]);
    } else {
      if (selected) {
        setColors([accentTertiary, utility.white]);
      } else {
        setColors([utility.white, accentTertiary]);
      }
    }
  }, [accentTertiary, hovered, selected]);

  const { mesh } = model;
  const { raycaster } = useThree();
  const { pointer0Down, altKey, metaKey } = usePointerState();

  const { checkForOcclusion } = useOcclusionChecker({ mesh });

  const move = useCallback(async () => {
    const hit = raycaster.intersectObject(mesh)[0];

    if (!hit) {
      return;
    }

    const newPos = hit.point;
    const coords: VP = [newPos.x, newPos.y, newPos.z];

    updatePoint(coords);
    setMoving(false);
  }, [raycaster, mesh]);

  useEffect(() => {
    if (isVisible && pointer0Down && !altKey && !metaKey && _3DToolbarSelection === "select") {
      if (occluded) {
        return; // only modify selection status of surface points that are not occluded
      }
      const intersected = raycaster.intersectObject(mesh)[0];
      if (!intersected) {
        return;
      }
      setPointerPos(intersected.point);
      if (hovered) {
        setMoving(true);
        setClickPos(intersected.point);
      }
    } else {
      if (moving && pointerPos.distanceTo(clickPos) > scale * outerRadius) {
        move();
      } else {
        setMoving(false);
      }
    }
  }, [pointer0Down, altKey, metaKey]);

  useFrame(() => {
    const intersected = raycaster.intersectObject(mesh)[0]?.point;
    if (moving) {
      if (intersected) {
        setPointerPos(intersected);
      }
    }

    function onChange(occluded: boolean) {
      if (innerCircleMatRef.current) {
        innerCircleMatRef.current.depthTest = occluded;
      }
      if (outerCircleMatRef.current) {
        outerCircleMatRef.current.depthTest = occluded;
      }
    }

    checkForOcclusion(point, onChange);
  });

  const showMovePreview = moving && pointerPos.distanceTo(new Vector3(...point)) > scale * (outerRadius + tolerance);

  function QuickLine(pos: Vector3, index: number) {
    const spCoords = [pointerPos, pos];
    return (
      <Line
        key={index}
        points={spCoords}
        color={neighborLineColor}
        lineWidth={2}
        renderOrder={Infinity}
        material={quickLineMat}
      />
    );
  }

  const movePreview = neighbors?.map((sp, index) => {
    return QuickLine(new Vector3(...sp), index);
  });

  return (
    <>
      {showMovePreview && movePreview}
      <Billboard position={moving && showMovePreview ? pointerPos : new Vector3(...point)} follow>
        <ScreenSizer scale={1} ref={screenSizerRef}>
          <Circle args={[outerRadius, 16]} position={0} renderOrder={Infinity} visible={isVisible}>
            <meshBasicMaterial color={colors[1]} opacity={1.0} transparent depthTest={false} ref={outerCircleMatRef} />
          </Circle>
          <Circle args={[innerRadius, 16]} position={0} renderOrder={Infinity} visible={isVisible}>
            <meshBasicMaterial color={colors[0]} opacity={1.0} transparent depthTest={false} ref={innerCircleMatRef} />
          </Circle>
        </ScreenSizer>
      </Billboard>
      <ClickTarget
        scale={workspace3dTokens.surfacePoint.outerRadius * 2}
        position={new Vector3(...point)}
        userData={{ id: collectionId, type: "surfacePoint", modelId: model.id, occluded: occluded, point }}
        onClick={(e) => {
          if (e.altKey) {
            removePoint();
          }

          if (e.metaKey || e.ctrlKey) {
            toggleBend();
          }
        }}
        onHoveredChange={(isHovered) => setHovered(isHovered)}
      />
    </>
  );
}
