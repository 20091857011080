import { useCurrentProject } from "@fragments/project/container";
import { useCallback, useMemo, useState } from "react";
import { Mesh3DBase } from "@variant-tech/pattern-derivation";
import { useDeepCompareEffect } from "@hooks";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";

export const useZone = (model: Model, collectionId: string) => {
  const { pathCollections } = useModelsStore(({ pathCollections }) => ({ pathCollections }));
  const [zone, setZone] = useState<Mesh3DBase | null>(null);
  const { v3dApi } = useCurrentProject();

  const pathCollection = useMemo(() => {
    return collectionId === undefined
      ? pathCollections[model.id].newCollection
      : pathCollections[model.id].collections[collectionId];
  }, [pathCollections, collectionId, model.id, model.attributes]);
  const { points, controlVectors, isLoop, type } = useMemo(() => {
    const path = pathCollection.paths[0];
    const { points, controlVectors, isLoop } = path;
    return { points, controlVectors, isLoop, type: path.cpp["__type"] };
  }, [pathCollection]);

  const [mesh3DBase, collectionCpp] = useMemo(() => {
    return [model.mesh3DBase, pathCollection.cpp];
  }, [model.mesh3DBase, pathCollection.cpp, model.attributes]);

  const splitMesh = useCallback(async () => {
    const operation = await v3dApi.Operation.create(mesh3DBase, false, true);
    await operation.cut(pathCollection.cpp);
    const mesh = await operation.getAsCutMesh();
    operation.delete();
    return mesh;
  }, [collectionCpp, mesh3DBase]);

  useDeepCompareEffect(() => {
    const minPoints = 2;
    if (points.length < minPoints) {
      if (zone) setZone(null);
      return;
    }

    const split = async () => {
      const resZone = await splitMesh();
      setZone(resZone);
    };

    split();
  }, [points, controlVectors, { isLoop, type }, splitMesh, model.attributes]);

  return { mesh3DBase: zone };
};
