import { Project } from "@models/backend";
import { V3dApi } from "@variant-tech/pattern-derivation";
import { createContext, PropsWithChildren, useContext } from "react";

type ProjectContextProps = { project?: Project; v3dApi?: V3dApi };
export const ProjectContext = createContext<ProjectContextProps>({});

export function CurrentProjectProvider({
  project,
  v3dApi,
  children,
}: PropsWithChildren<Required<ProjectContextProps>>) {
  return <ProjectContext.Provider value={{ project, v3dApi }}>{children}</ProjectContext.Provider>;
}

export const useCurrentProject = (): Required<ProjectContextProps> => {
  const { project, v3dApi } = useContext(ProjectContext);

  if (!project || !v3dApi) {
    throw new Error("useCurrentProject must be used inside a CurrentProjectProvider");
  }

  return { project, v3dApi };
};
