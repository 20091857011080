import { useEffect, useRef, EffectCallback } from "react";
import { isEqual } from "lodash";

export function useDeepCompareEffect(callback: EffectCallback, dependencies: object[]) {
  function deepCompareEquals(a: object, b: object | undefined) {
    return isEqual(a, b);
  }

  function useDeepCompareMemoize(value: object) {
    const ref = useRef<object>();
    if (!deepCompareEquals(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  }

  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
