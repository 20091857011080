import { resolveHexValue } from "@design-system";
import { Project } from "@models/backend";
import { Model } from "@models/project";
import { Helper, useBounds } from "@react-three/drei";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { centerCamera } from "@utils/project/camera";
import { recordToArray } from "@utils/project/collection";
import { useEffect } from "react";
import { Color, DoubleSide, MeshStandardMaterial } from "three";
import { VertexNormalsHelper } from "three/addons/helpers/VertexNormalsHelper.js";
import { Part } from "./Part";
import { Path } from "./Path";
import { PenTool } from "./PenTool";
import { CalibrationCurveTool } from "./CalibrationCurveTool";
import { Zone } from "./Zone";

export interface ModelProps {
  project: Project;
  model: Model;
  showNormals: boolean;
}

function ModelViewer({ project, model, showNormals }: ModelProps) {
  const { mesh, references } = model;
  const bounds = useBounds();
  const { pathCollections } = useModelsStore((s) => s);
  const { collections } = pathCollections[model.id] ?? { collections: {}, newCollection: null };
  const { _3DToolbarSelection } = useProjectState();
  const arrayCollections = recordToArray(collections);

  const pathsViz = arrayCollections
    .map((collection, index) => {
      return collection.paths.map((_, jdx) => (
        <Path
          key={`${collection.id}-${jdx}`}
          project={project}
          model={model}
          collectionId={collection.id}
          collectionUsage={collection.usage}
          collectionIdx={index}
          pathIdx={jdx}
          isSelectionAllowed={_3DToolbarSelection === "select"}
        />
      ));
    })
    .flat();
  const zonesViz = arrayCollections
    .filter(({ usage, id }) => id && usage === "zone")
    .map(({ id }, index) => <Zone key={id} model={model} index={index} pathCollectionId={id!} />);

  useEffect(() => {
    centerCamera(mesh, bounds);
  }, [mesh, bounds]);

  const guideCurveCollection = arrayCollections.find(({ usage, id }) => !!id && usage === "guide") ?? null;

  return (
    <group>
      {references.map((reference) => (
        <mesh
          key={reference.id}
          name={reference.name}
          geometry={reference.mesh.geometry}
          material={
            new MeshStandardMaterial({
              color: new Color(resolveHexValue(reference.attributes.meshSettings?.color ?? "lightGray")),
              side: DoubleSide,
            })
          }
          renderOrder={-1}
        />
      ))}
      {mesh && (
        <mesh name="model" geometry={mesh.geometry} material={mesh.material}>
          {showNormals && <Helper type={VertexNormalsHelper} args={[0.5, 0x126ccc]} />}
        </mesh>
      )}
      {_3DToolbarSelection === "pen" && <PenTool project={project} model={model} />}
      {(_3DToolbarSelection === "section" || _3DToolbarSelection === "column") && guideCurveCollection && (
        <CalibrationCurveTool project={project} model={model} guideSource={guideCurveCollection} />
      )}
      {pathsViz}
      {zonesViz}
      <Part project={project} model={model} />
    </group>
  );
}

export default ModelViewer;
