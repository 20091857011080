import { Model, ColumnAnchor } from "@models/project";
import { Model as BackendModel } from "@models/backend";
import { useModelsStore } from "@state/models";
import { PathState } from "@models/project";
import { generateUserColumnCurves } from "@utils/project/part";

export function useColumnAnchorLoader() {
  const { addColumnAnchor } = useModelsStore();

  async function loadColumnAnchors(model: Model, projectModel: BackendModel, guideSource: PathState) {
    const { columnAnchors } = projectModel;
    const columns = await generateUserColumnCurves(
      model,
      guideSource.cpp,
      columnAnchors.map(({ point }) => point),
    );
    const columnCurves = columns.map(async ([_, curve, origIdx], idx) => {
      const resColumnAnchor: ColumnAnchor = {
        ...columnAnchors[origIdx],
        idx,
        curve,
      };
      addColumnAnchor(model.id, resColumnAnchor);
      return resColumnAnchor;
    });
    return columnCurves;
  }

  return { loadColumnAnchors };
}
