import { useClipboard } from "@chakra-ui/react";
import { basename } from "@/config";
import { useBaseUrl } from "@hooks";

export function getOrganizationProjectsPath(organizationId: string) {
  return `/organizations/${organizationId}/projects`;
}

export function getProjectsPath() {
  return "/projects";
}

export function getProjectPath(projectId: string) {
  return `${getProjectsPath()}/${projectId}`;
}

export function useProjectUrl(projectId: string) {
  const baseUrl = useBaseUrl();
  const path = getProjectPath(projectId);
  const url = `${baseUrl}${basename}${path}`;
  const { onCopy } = useClipboard(url);

  return { path, url, onCopy };
}
