import { colors } from "@design-system/colors.ts";
import { Curve } from "@fragments/project/workspace-3d/curve";
import { Model } from "@models/project";
import { Project } from "@models/backend";
import { useModelsStore } from "@state";
import DirectionalityHelper from "./DirectionalityHelper";
import { workspace3dTokens } from "./workspace-3d-tokens.ts";
import { useHotkeys } from "react-hotkeys-hook";
import { useCalibrationCurves } from "@hooks/project";

export type PartProps = {
  project: Project;
  model: Model;
};

export function Part({ project, model }: PartProps) {
  const { columns, setColumnAnchors, sections, setSectionAnchors, selectedObjects, hoveredObject, setSelectedObjects } =
    useModelsStore();
  const guideSource = useModelsStore(({ pathCollections }) =>
    Object.values(pathCollections[model.id]?.collections)?.find(({ usage }) => usage === "guide"),
  );
  const { removeColumnAnchor, removeSectionAnchor } = useCalibrationCurves({ project, model });
  const modelSectionAnchors = sections[model.id]?.sectionAnchors;
  const sectionCurveStyle = workspace3dTokens.path.section;
  const modelColumnAnchors = columns[model.id]?.columnAnchors;
  const columnCurveStyle = workspace3dTokens.path.column;

  useHotkeys(["delete", "backspace"], () => {
    const selectedSections = selectedObjects.filter((obj) => obj.type === "section" || obj.type === "column");

    selectedSections.map((obj) => {
      if (obj.type === "section") {
        removeSectionAnchor(obj.id);
      } else if (obj.type === "column") {
        removeColumnAnchor(obj.id);
      }
    });
  });

  const cleanUp = async () => {
    if (modelSectionAnchors?.length) {
      modelSectionAnchors.forEach(async (sectionAnchor) => {
        await removeSectionAnchor(sectionAnchor.id!);
      });
      setSectionAnchors(model.id, []);
    }

    if (modelColumnAnchors?.length) {
      modelColumnAnchors.forEach(async (columnAnchor) => {
        await removeColumnAnchor(columnAnchor.id!);
      });
      setColumnAnchors(model.id, []);
    }
  };

  if (!guideSource) {
    cleanUp();
    return null;
  }

  return (
    <>
      {modelSectionAnchors &&
        modelSectionAnchors.map((sectionAnchor) => {
          const selected = !!selectedObjects.find((o) => o.id === sectionAnchor.id);
          const hovered = hoveredObject?.id === sectionAnchor.id;

          const color = selected || hovered ? colors.blue["600"] : sectionCurveStyle.color;
          const opacity = selected || hovered ? 1.0 : sectionCurveStyle.opacity;
          const lineWidth = hovered ? 2.0 : 1.0;

          return (
            <Curve
              key={sectionAnchor.id}
              mesh={model.mesh}
              curve={sectionAnchor.curve}
              color={color}
              opacity={opacity}
              width={lineWidth}
              showLengthOverride
              isSelected={selected}
              onSelected={() => {
                setSelectedObjects([{ type: "section", id: sectionAnchor.id!, modelId: model.id }]);
              }}
              isHovered={hovered}
              userData={{ type: "section", id: sectionAnchor.id, modelId: model.id }}
            />
          );
        })}
      {modelColumnAnchors &&
        modelColumnAnchors.map((columnAnchor) => {
          const selected = !!selectedObjects.find((o) => o.id === columnAnchor.id);
          const hovered = hoveredObject?.id === columnAnchor.id;

          const color = selected || hovered ? colors.blue["600"] : columnCurveStyle.color;
          const opacity = selected || hovered ? 1.0 : columnCurveStyle.opacity;
          const lineWidth = hovered ? 2.0 : 1.0;

          return (
            <Curve
              key={columnAnchor.id}
              mesh={model.mesh}
              curve={columnAnchor.curve}
              color={color}
              opacity={opacity}
              width={lineWidth}
              showLengthOverride
              isSelected={selected}
              onSelected={() => {
                setSelectedObjects([{ type: "column", id: columnAnchor.id!, modelId: model.id }]);
              }}
              isHovered={hovered}
              userData={{ type: "column", id: columnAnchor.id, modelId: model.id }}
            />
          );
        })}
      <DirectionalityHelper mesh={model.mesh} collectionId={guideSource.id!} path={guideSource.paths[0]!} />
    </>
  );
}
