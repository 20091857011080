import { useColorMode } from "@chakra-ui/color-mode";
import { Container, Flex } from "@chakra-ui/react";
import { resolveHexValue, useSelectedTheme, useSemanticTokens } from "@design-system";
import { Sidebar, UploadModelModal, useUploadModel } from "@fragments";
import { TopToolbar } from "@fragments/project/toolbars/TopToolbar.tsx";
import { Grid } from "@fragments/project/workspace-3d/Grid";
import ModelViewer from "@fragments/project/workspace-3d/ModelViewer.tsx";
import { RaycasterSetup } from "@fragments/project/workspace-3d/RaycasterSetup.tsx";
import { SelectTool } from "@fragments/project/workspace-3d/SelectTool";
import { useSavedModel, useUpdateProjectThumbnail } from "@hooks";
import { usePointerState } from "@hooks/project/usePointerState";
import { CursorConsumer, CursorProvider } from "@hooks/useCustomCursor.tsx";
import { Project as ProjectData } from "@models/backend";
import {
  Bounds,
  Environment,
  GizmoHelper,
  GizmoViewport,
  OrbitControls,
  PerspectiveCamera,
  Select,
} from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { useCallback, useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { MOUSE } from "three";
import { ViewportDropzone } from "./ViewportDropzone";

const RAY_CASTER_POINTS_THRESHOLD = 0.025;

const CameraController = () => {
  const { controls } = useThree();

  if (!controls) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (controls as any).mouseButtons = {
    LEFT: null,
    MIDDLE: MOUSE.ROTATE,
    RIGHT: null,
  };

  return null;
};

export function ProjectContainer({ project }: { project: ProjectData }) {
  const { setUpListeners } = usePointerState();
  const { models, showNormals } = useModelsStore(({ models, showNormals }) => ({
    models,
    showNormals,
  }));

  const {
    triggerUploadModelModal,
    uploadModalProps: { onModelUpload, ...uploadModalProps },
  } = useUploadModel({ project });

  useSavedModel({ project, triggerUploadModelModal });

  useEffect(setUpListeners);

  const { toggleColorMode } = useColorMode();
  const { setSelectedTheme } = useSelectedTheme();

  const { showMeasurements, setShowMeasurements, showDirectionality, setShowDirectionality } = useProjectState();

  useHotkeys("ctrl+k", (e) => {
    e.preventDefault();
    setSelectedTheme("base");
    toggleColorMode();
  });

  useHotkeys("ctrl+d", (e) => {
    e.preventDefault();
    setShowDirectionality(!showDirectionality);
  });

  useHotkeys("ctrl+m", (e) => {
    e.preventDefault();
    setShowMeasurements(!showMeasurements);
  });

  const containerBg = resolveHexValue(useSemanticTokens().surface.secondary);
  const { canvasRef, updateProjectThumbnail } = useUpdateProjectThumbnail(project);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.getContext("webgl2", { preserveDrawingBuffer: true });
    }
  }, [canvasRef]);

  const handleReferenceModelLoad = async (file: File) => {
    if (models.length > 0) {
      const parentModelId = models[0].id;
      await onModelUpload(file, parentModelId);
    }
  };
  const getFileExtension = (filename: string): string => {
    return filename.split(".").pop()?.toLowerCase() || "";
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        const extension = getFileExtension(file.name);
        if (["obj", "glb", "gltf"].includes(extension)) {
          await handleReferenceModelLoad(file);
        }
      }
    },
    [handleReferenceModelLoad],
  );

  return (
    <Flex direction="column">
      <TopToolbar borderBottomWidth={1} />
      <Flex direction="row" height="calc(100vh - 45px)">
        <Container bg={containerBg} flex={1} maxW="unset" width="calc(100% - 15rem)">
          <CursorProvider>
            <CursorConsumer>
              {({ cursor }) => {
                return (
                  <ViewportDropzone onDrop={onDrop}>
                    <Canvas
                      id="workspace3D"
                      style={{ cursor }}
                      ref={canvasRef}
                      onCreated={updateProjectThumbnail}
                      onClick={updateProjectThumbnail}
                      onKeyUp={updateProjectThumbnail}
                    >
                      <PerspectiveCamera makeDefault position={[0, 0, 0]} zoom={0.75} fov={25} near={0.25} far={400} />
                      <Environment files="/studio_small_09_1k.hdr" />
                      <OrbitControls makeDefault target={[0, -0.01, 0]} dampingFactor={0.85} />
                      <Grid />
                      {models.length > 0 && (
                        <GizmoHelper alignment="bottom-left" margin={[80, 80]}>
                          <GizmoViewport axisColors={["#B54708", "#12B764", "#009CF2"]} labelColor="white" />
                        </GizmoHelper>
                      )}
                      <Bounds maxDuration={0}>
                        <Select multiple box filter={(items) => items}>
                          <SelectTool>
                            {models
                              .filter((model) => !model.parentId)
                              .map((model) => (
                                <ModelViewer key={model.id} project={project} model={model} showNormals={showNormals} />
                              ))}
                          </SelectTool>
                        </Select>
                      </Bounds>

                      <CameraController />
                      <RaycasterSetup pointsThreshold={RAY_CASTER_POINTS_THRESHOLD} />
                    </Canvas>
                  </ViewportDropzone>
                );
              }}
            </CursorConsumer>
          </CursorProvider>
          <UploadModelModal onModelUpload={onModelUpload} {...uploadModalProps} />
        </Container>
        <Sidebar project={project} height="100%" width="15rem" />
      </Flex>
    </Flex>
  );
}
