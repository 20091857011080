import { Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing, useSemanticTokens } from "@design-system";

type SidebarHeaderProps = {
  title: string;
} & StyleProps;

export function SidebarHeader({ title, ...props }: SidebarHeaderProps) {
  const semanticTokens = useSemanticTokens();

  return (
    <Text
      alignContent="center"
      borderColor={semanticTokens.border.classic.primary}
      borderBottomWidth={1}
      p={spacing.space[200]}
      color={semanticTokens.text.classic.primary}
      isTruncated
      variant="xs-regular"
      {...props}
    >
      {title}
    </Text>
  );
}
