import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function InfoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" height="24px" width="24px" fill="none" {...props}>
      <path
        d="M11 11H12V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <rect x="11.25" y="7.25" width="1.5" height="1.5" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
    </Icon>
  );
}
