import {
  BoxProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useSemanticTokens, useThemeTokens } from "@design-system/hooks";
import { InfoIcon, WarningIcon } from "@design-system/icons";

import { Dispatch, HTMLInputTypeAttribute, ReactNode, Ref, useRef, useState } from "react";

type InputProps = {
  errorMessage?: string;
  infoMessage?: string;
  isInvalid?: (value: string | undefined) => boolean;
  label?: string;
  link?: string;
  placeholder?: string;
  leftIcon?: ReactNode;
  rightIcons?: JSX.Element[];
  dropdown?: ReactNode;
  type?: HTMLInputTypeAttribute;
  value?: string;
  setValue: Dispatch<string | undefined>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: "compact" | "tabsSearch";
  ref?: Ref<HTMLInputElement>;
};

export function Input({
  errorMessage,
  infoMessage,
  isInvalid,
  label,
  link,
  placeholder,
  leftIcon,
  rightIcons,
  dropdown,
  type,
  value,
  setValue,
  variant,
}: InputProps & BoxProps) {
  const {
    input: {
      icons: {
        right: { gap },
        color,
        padding,
        size,
      },
      label: { color: labelColor },
      link: { color: linkColor },
      selected: {
        icons: { color: activeColor },
      },
    },
  } = useThemeTokens();
  const semanticTokens = useSemanticTokens();

  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <FormControl
      variant={variant}
      isInvalid={isInvalid && isInvalid(value)}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={handleContainerClick}
    >
      <Flex dir="row" justifyContent="space-between">
        <Text variant="xs-regular" color={labelColor}>
          {label}
        </Text>
        <Text variant="xs-regular" color={linkColor}>
          {link}
        </Text>
      </Flex>
      <InputGroup alignItems="center">
        <InputLeftElement height={"100%"} color={hover || focus ? activeColor : color} transitionDuration="0.2s">
          {leftIcon}
        </InputLeftElement>
        <ChakraInput
          ref={inputRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          pl={leftIcon ? padding * 2 + size : padding}
          pr={rightIcons ? rightIcons.length * gap + (rightIcons.length + 1) * size + padding : padding}
          type={type}
          value={value}
          errorBorderColor={semanticTokens.border.warning}
          focusBorderColor={semanticTokens.border.border}
          onChange={(e) => setValue(e.target.value)}
          overflowX="scroll"
          placeholder={placeholder}
          variant={variant}
        />
        {rightIcons?.length && (
          <InputRightElement height={"100%"} color={color} gap={gap} pr={gap * rightIcons?.length + padding}>
            {rightIcons}
          </InputRightElement>
        )}
        {dropdown}
      </InputGroup>
      {!isInvalid || !isInvalid(value) ? (
        <FormHelperText>
          {infoMessage && (
            <Flex alignContent="center" gap={1}>
              <InfoIcon /> {infoMessage}
            </Flex>
          )}
        </FormHelperText>
      ) : (
        <FormErrorMessage>
          {errorMessage && (
            <Flex alignContent="center" gap={1}>
              <WarningIcon /> {errorMessage}
            </Flex>
          )}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
