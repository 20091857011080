import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function ZoneIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="5" width="5" {...props}>
      <path
        d="M13.3333 2.66675H10.6666M13.3333 2.66675L2.66663 13.3334M13.3333 2.66675C13.3333 3.63997 13.3333 4.5133 13.3333 5.33341M2.66663 13.3334V10.6667M2.66663 13.3334H5.33329M2.66663 5.33341V2.66675H5.33329M2.66663 5.33341L5.33329 2.66675M2.66663 5.33341V8.00008M5.33329 2.66675H7.99996M2.66663 8.00008L7.99996 2.66675M2.66663 8.00008V10.6667M7.99996 2.66675H10.6666M2.66663 10.6667L10.6666 2.66675M5.33329 13.3334L13.3333 5.33341M5.33329 13.3334H7.99996M13.3333 5.33341C13.3333 6.26842 13.3333 7.13425 13.3333 8.00008M7.99996 13.3334L13.3333 8.00008M7.99996 13.3334H10.6666M13.3333 8.00008C13.3333 8.86591 13.3333 9.73174 13.3333 10.6667M10.6666 13.3334H13.3333C13.3333 12.3602 13.3333 11.4869 13.3333 10.6667M10.6666 13.3334L13.3333 10.6667"
        stroke="currentColor"
      />
    </Icon>
  );
}
