import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function TemplatesIcon({ fill = "none", ...props }: IconProps) {
  return fill === "none" ? (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M12.5001 5.83333V2.5H4.16675V14.1667H7.50008M7.50008 5.83333H15.8334V17.5H7.50008V5.83333Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </Icon>
  ) : (
    <Icon viewBox="0 0 20 20" fill={fill} {...props}>
      <rect x="6.66663" y="5" width="9.16667" height="12.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5 1.66675H3.33337V14.1667H5.83337V4.16675H12.5V1.66675Z" />
    </Icon>
  );
}
