import { Box } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ColorPickerPopup, Dropdown, spacing, utility } from "@design-system";

interface ColorPickerProps extends StyleProps {
  color?: string;
  setColor: (color: string) => void;
  disableAlpha?: boolean;
}

export function ColorPicker({ color = utility.orange, setColor, disableAlpha, ...props }: ColorPickerProps) {
  return (
    <Dropdown
      buttonStyleProps={{ padding: 0, h: spacing.space[600] }}
      label={<Box backgroundColor={color} boxSize={spacing.space[600]} />}
      variant="classic-dropdown"
      {...props}
    >
      <ColorPickerPopup color={color} setColor={setColor} disableAlpha={disableAlpha} />
    </Dropdown>
  );
}
