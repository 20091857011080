import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function ListIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="4" width="4" {...props}>
      <path
        d="M8 12.6666H14M8 3.33325H14M2 12.6666H5M2 3.33325H5M8 7.99992H14M2 7.99992H5"
        fill="none"
        stroke="currentColor"
      />
    </Icon>
  );
}
