import { LoopAnalyticsPlugin, CoreAnalyticsConfig, TrackData, IdentifyData } from "../analyticsTypes";
import amplitudePlugin from "@analytics/amplitude";

export const createAnalyticsPlugins = (config: CoreAnalyticsConfig): LoopAnalyticsPlugin[] => {
  const plugins: LoopAnalyticsPlugin[] = [];

  const loopPlugin: LoopAnalyticsPlugin = {
    name: "loop-analytics-plugin",
    track: ({ payload }: { payload: TrackData }): TrackData => {
      if (config.debugMode) {
        console.debug("Event tracked:", {
          event: payload.event,
          properties: payload.properties,
        });
      }
      return payload;
    },
    identify: ({ payload }: { payload: IdentifyData }): IdentifyData => {
      if (config.debugMode) {
        console.debug("User identified:", {
          userId: payload.userId,
          traits: payload.traits,
        });
      }
      return payload;
    },
    loaded: () => true,
  };
  plugins.push(loopPlugin);

  if (config.amplitude?.apiKey) {
    const ampPlugin = amplitudePlugin({
      apiKey: config.amplitude.apiKey,
      options: {
        instanceName: config.amplitude.instanceName || "loop-dev",
      },
    });

    if (config.debugMode) {
      console.debug("Amplitude plugin initialized with config:", {
        instanceName: config.amplitude.instanceName,
      });
    }

    plugins.push(ampPlugin);
  }

  return plugins;
};
