import { Model } from "@models/project";
import { useMemo, useState, useEffect } from "react";
import { Color, BufferGeometry } from "three";
import { getZoneGeo, getZoneShader } from "@utils/project/zone";
import { useZonePaletteStore } from "./VizCustomizationStore";
import { useZone } from "@hooks/project";

export interface ZoneProps {
  model: Model;
  pathCollectionId: string;
  index: number;
}

export function Zone({ model, index, pathCollectionId }: ZoneProps) {
  const { mesh3DBase } = useZone(model, pathCollectionId);
  const [bufferGeometry, setBufferGeometry] = useState<BufferGeometry | undefined>(undefined);

  useEffect(() => {
    const getAndSetGeo = async () => {
      if (mesh3DBase) {
        const geo = await getZoneGeo(mesh3DBase);
        setBufferGeometry(geo);
      }
    };
    getAndSetGeo();
  }, [mesh3DBase, model.attributes?.normals?.flipNormals]);

  const zoneColors = useZonePaletteStore((state) => state.zonePalette);
  const zoneColorsKeys = Object.keys(zoneColors);
  const zoneColorsValues = Object.values(zoneColors);

  const zoneMat = useMemo(() => {
    return getZoneShader(index);
  }, [bufferGeometry, index]);

  const zoneMesh = useMemo(() => {
    zoneMat.color.set(new Color(zoneColorsValues[index % zoneColorsKeys.length]));
    zoneMat.roughness = 0.8;
    zoneMat.metalness = 0.0;
    return <mesh geometry={bufferGeometry} material={zoneMat} renderOrder={index} />;
  }, [bufferGeometry, zoneColors, index, model.attributes?.normals?.flipNormals]);

  return bufferGeometry ? zoneMesh : null;
}
