import { Size } from "@react-three/fiber";
import { SelectedObject } from "@state";
import { recordToArray } from "@utils";
import { vector2ToScreenSpace } from "@utils/project/raycaster.ts";
import { Object3D, Vector2 } from "three";

const GIZMO_DEAD_ZONE = {
  left: 0,
  bottom: 0,
  width: 150,
  height: 150,
};

export function pointerInDeadZone(pointer: Vector2, size: Size) {
  const pointerScreenSpace = vector2ToScreenSpace(pointer, size, true);

  return (
    pointerScreenSpace.x > GIZMO_DEAD_ZONE.left &&
    pointerScreenSpace.x < GIZMO_DEAD_ZONE.left + GIZMO_DEAD_ZONE.width &&
    pointerScreenSpace.y > GIZMO_DEAD_ZONE.bottom &&
    pointerScreenSpace.y < GIZMO_DEAD_ZONE.bottom + GIZMO_DEAD_ZONE.height
  );
}

export function computeSelectedObjects(selection: Object3D[]): SelectedObject[] {
  const data = selection
    .filter((obj) => obj.userData.id && obj.userData.modelId && obj.userData.type && obj.userData.occluded !== true)
    .map(({ userData: { id, type, modelId } }) => ({
      id: id as string,
      type: type as SelectedObject["type"],
      modelId: modelId as string,
    }))
    .reduce(
      (acc, { id, type, modelId }) => {
        acc[id] = {
          id: id,
          type: type,
          modelId,
        };
        return acc;
      },
      {} as Record<string, SelectedObject>,
    );

  return recordToArray(data);
}
