import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function HelpIcon({ fill = "none", ...props }: IconProps) {
  return fill === "none" ? (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M13.5416 6.45866V6.04199C13.5416 5.35164 14.1012 4.79199 14.7916 4.79199C15.4819 4.79199 16.0416 5.35164 16.0416 6.04199V11.8631C16.0416 15.0915 13.4245 17.7087 10.1961 17.7087C7.77064 17.7087 5.59717 16.2109 4.73361 13.9444L2.70825 8.6288C3.11372 7.63322 4.42608 7.41411 5.13299 8.22396L6.04159 9.26487V4.37533C6.04159 3.68497 6.60123 3.12533 7.29159 3.12533C7.98194 3.12533 8.54159 3.68497 8.54159 4.37533V9.16699M8.54159 8.12533V3.54199C8.54159 2.85164 9.10123 2.29199 9.79159 2.29199C10.4819 2.29199 11.0416 2.85164 11.0416 3.54199V9.58366M11.0416 6.45866V4.37533C11.0416 3.68497 11.6012 3.12533 12.2916 3.12533C12.9819 3.12533 13.5416 3.68497 13.5416 4.37533V10.0003"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="square"
      />
    </Icon>
  ) : (
    <Icon viewBox="0 0 20 20" fill={fill} {...props}>
      <path d="M16.0416 11.8629V6.04175C16.0416 5.35139 15.4819 4.79175 14.7916 4.79175C14.1012 4.79175 13.5416 5.35139 13.5416 6.04175V4.37508C13.5416 3.68473 12.9819 3.12508 12.2916 3.12508C11.6012 3.12508 11.0416 3.68473 11.0416 4.37508V3.54175C11.0416 2.85139 10.4819 2.29175 9.79159 2.29175C9.10123 2.29175 8.54159 2.85139 8.54159 3.54175V4.37508C8.54159 3.68473 7.98194 3.12508 7.29159 3.12508C6.60123 3.12508 6.04159 3.68473 6.04159 4.37508V9.26462L5.13299 8.22372C4.42608 7.41386 3.11372 7.63298 2.70825 8.62855L4.73361 13.9442C5.59717 16.2107 7.77064 17.7084 10.1961 17.7084C13.4245 17.7084 16.0416 15.0913 16.0416 11.8629Z" />
    </Icon>
  );
}
