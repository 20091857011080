import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useProgressToast, useTranslation } from "@hooks";
import { Project } from "@models/backend";
import { useCallback } from "react";

export function useDeleteProject() {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.projects.delete.progress");
  const toast = useProgressToast();

  const deleteProject = useCallback(
    async <T extends Pick<Project, "id" | "name">>(project: T, onProjectDeleted?: (project: T) => void) => {
      const { id, name: title } = project;
      const { update } = toast(t, { title, status: "in-progress" });

      try {
        await BackendApi.deleteProject({ headers, params: { id } });
        update({ status: "success" });
        onProjectDeleted?.(project);
      } catch (error) {
        console.log({ message: "Failed to delete project", error, project });
        update({ status: "error" });
      }
    },
    [headers, t, toast],
  );

  return { deleteProject };
}
