import { createContext } from "react";

export type AuthHeaders = { headers: { Authorization: string } };

export const OrganizationMemberRoles = ["member", "admin"] as const;
export const OrganizationMemberStatuses = ["pending", "active", "disabled"] as const;

export type OrganizationMember = {
  id: string;
  name: string;
  avatar: string;
  email: string;
  memberRole: (typeof OrganizationMemberRoles)[number];
  memberStatus: (typeof OrganizationMemberStatuses)[number];
  createdAt: Date;
  updatedAt: Date;
};

export type Organization = {
  id: string;
  name: string;
  logo: string;
  members: OrganizationMember[];
  createdAt: Date;
  updatedAt: Date;
};

export type OrganizationMembership = {
  memberRole: (typeof OrganizationMemberRoles)[number];
  memberStatus: (typeof OrganizationMemberStatuses)[number];
  organizationId: Organization["id"];
  createdAt: Date;
  updatedAt: Date;
  organization: Organization;
};

export type User = {
  id: string;
  name: string;
  avatar: string;
  email: string;
  organizations: OrganizationMembership[];
};

export const AuthContext = createContext<{
  accessToken: string;
  headers: AuthHeaders["headers"];
  user: User;
  logout: () => Promise<void>;
}>({
  accessToken: "",
  headers: {
    Authorization: "",
  },
  user: {
    id: "",
    name: "",
    organizations: [],
    email: "",
    avatar: "",
  },
  logout: async () => {
    /* Nothing to do here */
  },
});
