import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function CloudSuccessIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" height="24px" width="24px" fill="none" {...props}>
      <path
        d="M16.75 19.25H18.125C20.4032 19.25 22.25 17.4032 22.25 15.125C22.25 12.8468 20.4032 11 18.125 11C18.0814 11 18.038 11.0007 17.9948 11.002C17.9983 10.9184 18 10.8344 18 10.75C18 7.43629 15.3137 4.75 12 4.75C9.3716 4.75 7.13793 6.44009 6.32647 8.7928C3.74507 9.12339 1.75 11.3287 1.75 14C1.75 16.8995 4.10051 19.25 7 19.25H7.25M15 14.5L11.5 18L9.5 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </Icon>
  );
}
