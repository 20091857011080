import { IconProps } from "@chakra-ui/icons";
import { Link } from "@chakra-ui/react";
import { LogoIcon } from "@design-system";

export function Logo(props: IconProps) {
  return (
    <Link target="/">
      <LogoIcon {...props} />
    </Link>
  );
}
