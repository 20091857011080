import { Box, ScreenSizer } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import { Vector3 } from "three";
import { workspace3dTokens } from "./workspace-3d-tokens.ts";

type ClickTargetProps = {
  scale: number;
  tolerance?: number;
  position: Vector3;
  onClick?: (e: ThreeEvent<MouseEvent>) => void;
  onHoveredChange?: (hovered: boolean) => void;
  userData?: Record<string, unknown>;
};

export function ClickTarget({
  scale,
  tolerance = workspace3dTokens.clickTarget.tolerance,
  position,
  onClick,
  onHoveredChange,
  userData,
}: ClickTargetProps) {
  return (
    <ScreenSizer position={position}>
      <Box
        args={[scale + tolerance, scale + tolerance, scale + tolerance]}
        position={0}
        onClick={(e) => onClick?.(e)}
        onPointerEnter={() => onHoveredChange?.(true)}
        onPointerLeave={() => onHoveredChange?.(false)}
        userData={userData}
        visible={false}
      />
    </ScreenSizer>
  );
}
