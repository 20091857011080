import { Size } from "@react-three/fiber";
import { Camera, Vector2, Vector3 } from "three";

export function vector3ToScreenSpace(point: Vector3, camera: Camera, size: Size) {
  const vector = point.project(camera);
  vector.x = ((vector.x + 1) * size.width) / 2;
  vector.y = (-(vector.y - 1) * size.height) / 2;
  vector.z = 0;

  return vector;
}

export function vector2ToScreenSpace(point: Vector2, size: Size, reverseY = false) {
  const vector = new Vector2();
  vector.x = ((point.x + 1) * size.width) / 2;
  vector.y = (-(point.y - 1) * size.height) / 2;

  if (reverseY) {
    vector.y = size.height - vector.y;
  }

  return vector;
}
