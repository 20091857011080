import { ModelsStore } from "../types";
import { SectionAnchor } from "@models/project";
import { GetState, SetState } from "zustand";

export const sectionsSlice = (set: SetState<ModelsStore>, get: GetState<ModelsStore>) => ({
  sections: {},
  sectionsCount: 1,
  setSectionAnchors: (modelId: string, sectionAnchors: SectionAnchor[]) => {
    set(({ sections }) => {
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionAnchors,
          },
        },
      };
    });
  },
  addSectionAnchor: (modelId: string, sectionAnchor: SectionAnchor) => {
    set(({ sections }: ModelsStore) => {
      const modelSectionAnchors = sections[modelId]?.sectionAnchors || [];
      const changedSections = [...modelSectionAnchors, sectionAnchor];
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionAnchors: changedSections,
          },
        },
      };
    });
  },
  removeSectionAnchor: (modelId: string, id: string) => {
    set(({ sections }: ModelsStore) => {
      const modelSectionAnchors = sections[modelId].sectionAnchors;
      const changedSections = modelSectionAnchors.filter((sectionAnchor) => sectionAnchor.id !== id);
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionAnchors: changedSections,
          },
        },
      };
    });
  },
  updateSectionAnchor: (modelId: string, idx: number, update: Partial<SectionAnchor>) => {
    let result: SectionAnchor | undefined;
    set(({ sections }: ModelsStore) => {
      const modelSectionAnchors = sections[modelId].sectionAnchors;
      const changedSections = [...modelSectionAnchors];
      const mergedUpdate = { ...update };

      const sectionAnchor = {
        ...modelSectionAnchors[idx],
        ...mergedUpdate,
      };

      changedSections[idx] = sectionAnchor;

      result = sectionAnchor;
      return {
        sections: {
          ...sections,
          [modelId]: {
            sectionAnchors: changedSections,
          },
        },
      };
    });
    return result!;
  },
  getSectionAnchors: (modelId: string) => {
    return get().sections[modelId].sectionAnchors;
  },
});
