import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function ImportIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 30 30" height="30" width="30" {...props}>
      <path
        d="M28.75 19.5833V28.75H1.25V19.5833M15 20V1.25M15 20L9.16667 14.1667M15 20L20.8333 14.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        fill="none"
      />
    </Icon>
  );
}
