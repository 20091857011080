import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, HStack, IconButton, ResponsiveValue, Text, useMultiStyleConfig, VStack } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { CheckIcon, InfoIcon, useSemanticTokens, WarningIcon } from "@design-system";
import { ToastVariant } from "@design-system/component-styles/ToastStyleConfig.ts";
import { ReactNode } from "react";

const VariantDefaultIcon = {
  info: <InfoIcon />,
  success: <CheckIcon />,
  error: <WarningIcon />,
  warning: <WarningIcon />,
};

export type ToastProps = {
  title?: ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  onClose?: () => void;
  isCloseable?: boolean;
  variant?: ResponsiveValue<string>;
} & Omit<StyleProps, "position">;

export function Toast({ title, icon, children, footer, onClose, isCloseable = true, variant, ...props }: ToastProps) {
  const styles = useMultiStyleConfig("Toast", { variant });
  const tokens = useSemanticTokens();
  const _icon = icon ?? VariantDefaultIcon[variant as ToastVariant];

  return (
    <VStack __css={styles.container} flex={1} gap={0}>
      <HStack gap="0.75rem" alignItems="start" w="full">
        {_icon && (
          <Box __css={styles.icon} as="span">
            {_icon}
          </Box>
        )}
        <VStack flex={1} gap="0.25rem" w="calc(100% - 2rem)">
          <Flex justifyContent="space-between" w="full">
            <Text variant="s-medium" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              {title}
            </Text>
            {isCloseable && (
              <IconButton
                aria-label="Close"
                color={tokens.icons.primary}
                icon={<CloseIcon w={3} h={3} />}
                size="sm"
                variant="windowIcon"
                onClick={onClose}
                m="-0.25rem"
              />
            )}
          </Flex>
          {children && (
            <Box __css={styles.children} flex={1} gap={2} {...props}>
              {children}
            </Box>
          )}
          {footer && (
            <Flex gap={1} mt={3} w="full">
              {footer}
            </Flex>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
}
