import { CurveSegment } from "@fragments/project/workspace-3d/curve/CurveSegment.tsx";
import { ModifierKeys } from "@hooks";
import { useFrame } from "@react-three/fiber";
import { useProjectState } from "@state";
import { computeSegmentsOcclusions, toVertices } from "@utils";
import { isEqual } from "lodash";
import { Fragment, useMemo, useState } from "react";
import { BufferAttribute, BufferGeometry, Mesh, Vector3 } from "three";
import { useOcclusionChecker } from "../useOcclusionChecker";
import { LengthCallout } from "./LengthCallout";

export type CurveProps = {
  curve: Float32Array;
  mesh: Mesh;
  length?: number;
  color?: string;
  opacity?: number;
  width?: number;
  showLengthOverride?: boolean;
  isHovered?: boolean;
  isSelected?: boolean;
  onHovered?: (hovered: boolean) => void;
  onSelected?: (keys: ModifierKeys) => void;
  userData?: Record<string, unknown>;
};

export function Curve({
  curve,
  mesh,
  length,
  color,
  opacity,
  width,
  showLengthOverride,
  isHovered,
  isSelected,
  onHovered,
  onSelected,
  userData,
}: CurveProps) {
  const { showMeasurements } = useProjectState();
  const showLength = length && (showMeasurements || showLengthOverride);
  const { camera } = useOcclusionChecker({ mesh });
  const [segments, setSegments] = useState<{ occluded: boolean; vertices: Vector3[] }[]>([]);

  const vertices = useMemo(() => {
    if (!curve) {
      return [];
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new BufferAttribute(curve, 3));
    return toVertices(geometry);
  }, [curve]);

  useFrame(() => {
    const newSegments = computeSegmentsOcclusions(mesh, vertices, camera);

    if (!isEqual(segments, newSegments)) {
      setSegments(newSegments);
    }
  });

  if (!vertices?.length) {
    return undefined;
  }

  const middle = Math.floor(segments.length / 2);

  return segments.map(({ occluded, vertices }, index) => (
    <Fragment key={index}>
      <CurveSegment
        occluded={occluded}
        vertices={vertices}
        color={color}
        width={width}
        isHovered={isHovered}
        isSelected={isSelected}
        onHovered={onHovered}
        onSelected={onSelected}
        opacity={opacity}
        userData={userData}
      />
      {index === middle && !occluded && showLength && <LengthCallout vertices={vertices} length={length} />}
    </Fragment>
  ));
}
