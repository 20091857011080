import { Vector3 as Vector3Tuple } from "@variant-tech/pattern-derivation";
import { useThree } from "@react-three/fiber";
import { useCallback } from "react";
import { BufferGeometry, Camera, Mesh, Raycaster, Vector3 } from "three";
import { acceleratedRaycast, computeBoundsTree, disposeBoundsTree, MeshBVH, MeshBVHOptions, SAH } from "three-mesh-bvh";

declare module "three" {
  interface BufferGeometry {
    computeBoundsTree(options?: MeshBVHOptions): MeshBVH;

    disposeBoundsTree(): void;

    boundsTree?: MeshBVH;
  }
}

BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
Mesh.prototype.raycast = acceleratedRaycast;

type useOcclusionCheckerProps = {
  mesh: Mesh;
};

export const checkForOcclusionAsync = (
  mesh: Mesh,
  point: Vector3Tuple,
  camera: Camera,
  onOcclusionChange: (occluded: boolean) => void,
) => onOcclusionChange(checkForOcclusion(mesh, point, camera));

export function checkForOcclusion(mesh: Mesh, point: Vector3Tuple, camera: Camera) {
  if (!mesh.geometry.boundsTree) {
    console.log("Building bounds tree");
    mesh.geometry.computeBoundsTree({ maxLeafTris: 1, strategy: SAH });
  }

  const tempRaycaster = new Raycaster();

  const v = new Vector3();
  v.copy(camera.position).sub(new Vector3(...point));

  tempRaycaster.set(new Vector3(...point), v);

  const intersections = tempRaycaster.intersectObject(mesh);
  const tolerance = intersections.some((i) => i.point.distanceTo(new Vector3(...point)) > 0.1);

  return intersections.length > 0 && tolerance;
}

export function useOcclusionChecker({ mesh }: useOcclusionCheckerProps) {
  const { camera } = useThree();

  return {
    camera,
    checkForOcclusion: useCallback(
      (point: Vector3Tuple, onOcclusionChange: (occluded: boolean) => void) =>
        checkForOcclusionAsync(mesh, point, camera, onOcclusionChange),
      [mesh, camera],
    ),
    checkForOcclusionNoCallback: useCallback(
      (point: Vector3Tuple) => checkForOcclusion(mesh, point, camera),
      [mesh, camera],
    ),
  };
}
