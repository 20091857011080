import { useModelUpload } from "@hooks/project";
import { useState } from "react";
import { Project } from "@models/backend";

interface UseUploadModelProps {
  project: Project;
}

export function useUploadModel({ project }: UseUploadModelProps) {
  const [isUploadModelModalOpen, setIsUploadModelModalOpen] = useState(!project.models.length);
  const { onModelUpload } = useModelUpload({ projectId: project.id });

  return {
    triggerUploadModelModal: () => setIsUploadModelModalOpen(true),
    uploadModalProps: {
      isOpen: isUploadModelModalOpen,
      closable: false,
      onClose: () => setIsUploadModelModalOpen(false),
      onModelUpload: async (file: File, parentModelId?: string) => {
        setIsUploadModelModalOpen(false);
        try {
          await onModelUpload(file, parentModelId);
        } catch {
          if (!parentModelId) {
            setIsUploadModelModalOpen(true);
          }
        }
      },
    },
  };
}
