import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function FilesIcon({ fill, ...props }: IconProps) {
  return fill === "none" ? (
    <Icon viewBox="0 0 16 16" {...props} fill="none">
      <path
        d="M4.66667 9.66667H9.66667M4.66667 6.33333H11.3333M1.125 1.125H14.875V14.875H1.125V1.125Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </Icon>
  ) : (
    <Icon viewBox="0 0 16 16" {...props} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0.5H15.5V15.5H0.5V0.5ZM9.66667 10.2917H4.66667V9.04167H9.66667V10.2917ZM4.66667 6.95833H11.3333V5.70833H4.66667V6.95833Z"
      />
    </Icon>
  );
}
