import { defineStyleConfig, SystemStyleFunction } from "@chakra-ui/react";
import { semanticTokens } from "@design-system/semantic-tokens";

const primary: SystemStyleFunction = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).text.accent.primary,
});

const secondary: SystemStyleFunction = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).text.accent.secondary,
});

const tertiary: SystemStyleFunction = ({ theme, colorMode }) => ({
  color: semanticTokens(theme, colorMode).text.accent.tertiary,
});

export const linkStyleConfig = defineStyleConfig({
  baseStyle: {
    textDecoration: "none",
    _hover: {
      textDecoration: "none",
    },
  },
  defaultProps: {
    variant: "primary",
  },
  variants: {
    primary,
    secondary,
    tertiary,
  },
});
