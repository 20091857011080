import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { withLoadingAndErrorHandling } from "@utils";
import { useCallback, useState } from "react";

export const useModelApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { updateModel, removeReferenceModel } = useModelsStore(({ updateModel, removeReferenceModel }) => ({
    updateModel,
    removeReferenceModel,
  }));
  const { headers } = useAuth();

  const patchModel = useCallback(
    async (projectId: string, modelId: string, body: Partial<Model>) =>
      withLoadingAndErrorHandling(setIsLoading, setError, async () => {
        const result = await BackendApi.patchProjectModel({
          headers,
          params: { projectId, id: modelId },
          body,
        });
        updateModel(modelId, body);
        return result;
      }),
    [updateModel, headers],
  );

  const deleteReferenceModel = useCallback(
    async (projectId: string, modelId: string) =>
      withLoadingAndErrorHandling(setIsLoading, setError, async () => {
        await BackendApi.deleteProjectModel({
          headers,
          params: { projectId, id: modelId },
        });
        removeReferenceModel(modelId);
      }),
    [headers, removeReferenceModel],
  );

  return { deleteReferenceModel, patchModel, isLoading, error };
};
