import { Box, Stack, StackProps, useStyleConfig } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ToolbarVariant } from "@design-system/component-styles/ToolbarStyleConfig.ts";
import { ReactNode } from "react";

export type ToolbarProps = {
  direction?: StackProps["direction"];
  size?: "fit-content" | "full";
  variant?: ToolbarVariant;
  children?: ReactNode;
} & StyleProps;

export function Toolbar({ direction = "column", size, variant, children, ...props }: ToolbarProps) {
  const styles = useStyleConfig("Toolbar", { variant, size: `${direction}-${size}` });

  return (
    <Box __css={styles} as={Stack} alignItems="center" direction={direction} {...props}>
      {children}
    </Box>
  );
}
