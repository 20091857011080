import { HStack, Text, StyleProps } from "@chakra-ui/react";
import { spacing, useThemeTokens } from "@design-system";

export type SidebarPropertiesHeaderProps = {
  name: string;
  icon: JSX.Element;
} & StyleProps;

export function SidebarPropertiesHeader({ name, icon, ...props }: SidebarPropertiesHeaderProps) {
  const { border } = useThemeTokens();
  return (
    <HStack
      alignItems="center"
      borderColor={border.classic.color}
      gap={spacing.space[200]}
      px={spacing.space[200]}
      py={spacing.space[400]}
      height="3rem"
      {...props}
    >
      {icon}
      <Text isTruncated variant="xs-regular">
        {name}
      </Text>
    </HStack>
  );
}
