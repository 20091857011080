import { colors } from "@design-system/colors";
import { useDeepCompareEffect } from "@hooks";
import { PathState } from "@models/project";
import { useModelsStore } from "@state/models";
import { SampleParameters } from "@variant-tech/pattern-derivation";
import { useCallback, useMemo, useState } from "react";
import { Mesh, Vector3 } from "three";
import { DirectionArrow } from "./DirectionArrow";

export type DirectionalityHelperProps = {
  mesh: Mesh;
  collectionId?: string;
  path: PathState;
};

function DirectionalityHelper({ mesh, collectionId, path }: DirectionalityHelperProps) {
  const { selectedObjects, hoveredObject } = useModelsStore();
  const [positions, setPositions] = useState<Vector3[]>([]);

  const isSelected = collectionId ? !!selectedObjects.find(({ id }) => id === collectionId) : false;
  const isHovered = !isSelected && hoveredObject?.id === collectionId;
  const color = isSelected || isHovered ? colors.blue["600"] : colors.green["600"];

  const samplePath = useCallback(async () => {
    // over-sample and only render 3 arrows so the arrows actually follow curve contour
    const sampleParams: SampleParameters = { mode: "QUANTITY", quantity: 20 };
    return await path.cpp.sample(sampleParams);
  }, [path.cpp]);

  useDeepCompareEffect(() => {
    const sampleAndSetPositions = async () => {
      if (path.points.length < 2) {
        setPositions([]);
        return;
      }

      const pos = await samplePath();

      const posArray = pos[0].map((v) => new Vector3(v[0], v[1], v[2]));
      setPositions(posArray);
    };
    sampleAndSetPositions();
  }, [path.points, path.controlVectors]);

  const content = useMemo(
    () =>
      positions.map((sp, index) => {
        if (index === positions.length - 1) return null;
        // only render 3 arrows across the length of the curve
        if ((index + 1) % 5 !== 0) return null;
        const dir = positions[index + 1].clone().sub(sp.clone()).normalize();
        const origin = sp.clone();
        const length = sp.distanceTo(positions[index + 1]);

        const coneLength = length < 0.5 ? length : 0.5; //index % 2 != 0 ? 0.5 : 0;
        const coneRadius = 0.2; //index % 2 != 0 ? 0.25 : 0;

        return (
          <DirectionArrow
            key={index}
            mesh={mesh}
            collectionId={collectionId}
            dir={dir}
            origin={origin}
            length={length}
            coneLength={coneLength}
            coneRadius={coneRadius}
            color={color}
          />
        );
      }),
    [positions, color],
  );

  return <>{content}</>;
}

export default DirectionalityHelper;
