import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function PathIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="5" width="5" {...props}>
      <path
        d="M4.00002 10.6668C3.26364 10.6668 2.66669 11.2637 2.66669 12.0001C2.66669 12.7365 3.26364 13.3334 4.00002 13.3334C4.7364 13.3334 5.33335 12.7365 5.33335 12.0001C5.33335 11.2637 4.7364 10.6668 4.00002 10.6668ZM4.00002 10.6668C4.00002 8.00008 7.00002 8.00008 8.00002 8.00008C9.00001 8.00008 12 8.00008 12 5.33342M12 5.33342C12.7364 5.33342 13.3334 4.73646 13.3334 4.00008C13.3334 3.2637 12.7364 2.66675 12 2.66675C11.2636 2.66675 10.6667 3.2637 10.6667 4.00008C10.6667 4.73646 11.2636 5.33342 12 5.33342Z"
        stroke="currentColor"
        fill="none"
      />
    </Icon>
  );
}
