import BackendApi from "@api/BackendApi.ts";
import { useBackendApi } from "@api/useBackendApi.ts";
import { Organization, OrganizationMember } from "@auth/AuthContext.ts";
import { StyleProps } from "@chakra-ui/styled-system";
import { ErrorScreen, Loading, MembersToolbar } from "@fragments";
import { MembersTable } from "@fragments/members/table";
import { useViewVersion } from "@hooks";
import { useEffect, useState } from "react";

interface MembersViewProps {
  organization: Organization;
  tableProps?: StyleProps;
  toolbarProps?: StyleProps;
}

export function MembersView({ organization, tableProps = {}, toolbarProps = {} }: MembersViewProps) {
  const [viewVersion, setViewVersion] = useViewVersion();
  const [members, setMembers] = useState<OrganizationMember[]>([]);

  const { error, isLoading, result } = useBackendApi(
    BackendApi.getOrganizationMembers,
    {
      params: { organizationId: organization.id },
    },
    [organization, viewVersion],
  );

  useEffect(() => {
    setMembers(result?.members ?? []);
  }, [result]);

  if (isLoading) {
    return <Loading height="full" />;
  }

  if (error) {
    return <ErrorScreen error={error} showHeader={false} />;
  }

  return (
    <>
      <MembersToolbar
        paddingStart="1.5rem"
        paddingEnd="2rem"
        paddingBottom="0.75rem"
        paddingTop="2rem"
        organization={{ ...organization, members }}
        onMembersAdd={() => setViewVersion((v) => v + 1)}
        {...toolbarProps}
      />
      <MembersTable organization={organization} members={members} setMembers={setMembers} {...tableProps} />
    </>
  );
}
