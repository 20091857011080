import { useSemanticTokens } from "./useSemanticTokens";

export function useThemeTokens() {
  const semanticTokens = useSemanticTokens();

  return {
    background: {
      color: semanticTokens.surface.primary,
    },
    border: {
      classic: {
        color: semanticTokens.border.classic.primary,
      },
      color: semanticTokens.border.border,
      width: "1px",
    },
    dropDownItem: {
      icon: {
        color: semanticTokens.icons.primary,
      },
      selected: {
        bg: semanticTokens.surface.invert.accent.secondary,
        icon: {
          color: semanticTokens.icons.accent.primary,
        },
      },
    },
    input: {
      icons: {
        right: {
          gap: 4,
        },
        color: semanticTokens.icons.primary,
        padding: 2,
        size: 4,
      },
      label: {
        color: semanticTokens.text.secondary,
      },
      link: {
        color: semanticTokens.text.accent.primary,
      },
      selected: {
        icons: {
          color: semanticTokens.icons.accent.primary,
        },
      },
    },
    popover: {
      header: {
        height: "2.5rem",
        text: {
          variant: "s-medium",
        },
      },
    },
    toolbarItem: {
      selected: {
        rect: {
          color: semanticTokens.icons.accent.primary,
        },
      },
    },
    pipe: {
      color: semanticTokens.border.classic.primary,
    },
  };
}
