import { create } from "zustand";

interface ZonePaletteStore {
  zonePalette: {
    A: string;
    B: string;
    C: string;
    D: string;
    E: string;
    F: string;
    G: string;
    H: string;
    I: string;
    J: string;
    K: string;
  };
  setZonePalette: (zonePalette: ZonePaletteStore["zonePalette"]) => void;
}

export const useZonePaletteStore = create<ZonePaletteStore>((set) => ({
  zonePalette: {
    A: "#FF3B2C",
    B: "#E68608",
    C: "#6941C6",
    D: "#12B76A",
    E: "#009CF2",
    F: "#F75F2D",
    G: "#3FDFC4",
    H: "#D36EF6",
    I: "#F6D259",
    J: "#39E4F3",
    K: "#2AF4B0",
  },
  setZonePalette: (zonePalette: ZonePaletteStore["zonePalette"]) => {
    set(() => ({ zonePalette: zonePalette }));
  },
}));
