import { CheckboxProps, Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";
import { RxDash } from "react-icons/rx";

export function CheckMarkIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 12 12" height="0.75rem" width="0.75rem" {...props}>
      <path
        d="M1.5 8.25732L4.64706 10.7573L10.5 2.75732"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </Icon>
  );
}

export function CheckboxIcon({ isChecked, isIndeterminate, ...props }: IconProps & CheckboxProps) {
  return <>{isChecked ? isIndeterminate ? <Icon as={RxDash} /> : <CheckMarkIcon {...props} /> : null}</>;
}
