import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function ColumnIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path
        d="M10.2083 9.79175V7.91247L15.5701 2.55063L17.4494 4.42991L12.0875 9.79175H10.2083Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="square"
        fill="none"
      />
      <path
        d="M11.4535 11.2501H10.2031C10.2069 11.3869 10.2089 11.5258 10.2089 11.6667C10.2089 13.6659 9.80913 15.2601 9.4132 16.3489C9.31188 16.6275 9.21086 16.8729 9.11695 17.0833H7.13847C7.17702 16.9852 7.21583 16.8827 7.25461 16.7761C7.69201 15.5732 8.12557 13.8341 8.12557 11.6667C8.12557 9.49924 7.69201 7.7601 7.25461 6.55724C7.21583 6.45061 7.17702 6.34817 7.13847 6.25H9.11695C9.18212 6.39601 9.25071 6.55886 9.32044 6.73797L11.0584 5H1.66724C1.66724 5 1.95855 5.43696 2.29759 6.25C2.76951 7.38168 3.3339 9.24197 3.3339 11.6667C3.3339 14.0914 2.76951 15.9517 2.29759 17.0833C1.95855 17.8964 1.66724 18.3333 1.66724 18.3333H13.3339C13.3339 18.3333 15.0006 15.8333 15.0006 11.6667C15.0006 10.743 14.9187 9.90132 14.7912 9.15056L13.6805 10.2613C13.725 10.7023 13.7506 11.1713 13.7506 11.6667C13.7506 13.5818 13.3677 15.1035 12.9925 16.1353C12.8515 16.5229 12.7119 16.8407 12.5946 17.0833H10.4718C10.5104 16.9852 10.5492 16.8827 10.5879 16.7761C11.0253 15.5732 11.4589 13.8341 11.4589 11.6667C11.4589 11.526 11.4571 11.3871 11.4535 11.2501ZM5.78362 17.0833H3.64087C3.65235 17.0525 3.66383 17.0212 3.67531 16.9897C4.13345 15.7298 4.5839 13.9182 4.5839 11.6667C4.5839 9.41515 4.13345 7.60354 3.67531 6.34365C3.66383 6.31209 3.65235 6.28087 3.64087 6.25H5.78362C5.87753 6.46041 5.97854 6.70579 6.07987 6.98442C6.4758 8.07323 6.87557 9.66743 6.87557 11.6667C6.87557 13.6659 6.4758 15.2601 6.07987 16.3489C5.97854 16.6275 5.87753 16.8729 5.78362 17.0833Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </Icon>
  );
}
