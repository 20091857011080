import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, IconButton, ButtonGroup, Button, useColorMode } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { LogoIcon, useSelectedTheme, useSemanticTokens } from "@design-system";
import { HamburgerIcon } from "./icons";
import { useAuth } from "@auth";
import { useTranslation } from "@hooks";

type ApplicationContextMenuProps = {
  placement?: "bottom-start" | "bottom-end" | "top-start" | "top-end";
  showThemeButtons?: boolean;
  showDashboardLink?: boolean;
  showContactUs?: boolean;
  showLogout?: boolean;
  showCopyToken?: boolean;
};

export const ApplicationContextMenu: React.FC<ApplicationContextMenuProps> = ({
  placement = "bottom-start",
  showThemeButtons = true,
  showDashboardLink = false,
  showContactUs = false,
  showLogout = false,
}) => {
  const { selectedTheme, setSelectedTheme } = useSelectedTheme();
  const { colorMode, setColorMode } = useColorMode();
  const semanticTokens = useSemanticTokens();
  const { logout } = useAuth();
  const { t } = useTranslation("applicationContextMenu");

  const openContactForm = () => {
    window.open("https://airtable.com/appZrtSy8nqllTBsN/pagZBJoHaLCJfxmvW/form", "_blank");
  };

  return (
    <Menu placement={placement}>
      <MenuButton
        as={IconButton}
        icon={
          <>
            <HamburgerIcon color={semanticTokens.icons.primary} transition="color 0.2s" />
            <LogoIcon color={semanticTokens.icons.primary} transition="color 0.2s" />
          </>
        }
        aria-label={t("menuLabel")}
        variant=""
        height="100%"
        ml={"2px"}
        _hover={{
          "& svg": { color: semanticTokens.icons.accent.primary },
        }}
        _active={{
          "& svg": { color: semanticTokens.icons.accent.primary },
        }}
      />
      <MenuList>
        {showThemeButtons && (
          <MenuItem as="div">
            <ButtonGroup isAttached variant="inverse">
              <Button
                onClick={() => {
                  setColorMode("light");
                  setSelectedTheme("base");
                }}
                isActive={colorMode === "light" && selectedTheme === "base"}
              >
                {t("lightTheme")}
              </Button>
              <Button
                onClick={() => {
                  setColorMode("dark");
                  setSelectedTheme("base");
                }}
                isActive={colorMode === "dark" && selectedTheme === "base"}
              >
                {t("darkTheme")}
              </Button>
              <Button
                onClick={() => {
                  setColorMode("light");
                  setSelectedTheme("classic");
                }}
                isActive={colorMode === "light" && selectedTheme === "classic"}
              >
                {t("classicTheme")}
              </Button>
            </ButtonGroup>
          </MenuItem>
        )}
        {showDashboardLink && (
          <MenuItem as={RouterLink} to="/">
            {t("goToDashboard")}
          </MenuItem>
        )}
        {showContactUs && <MenuItem onClick={openContactForm}>{t("contactUs")}</MenuItem>}
        {showLogout && <MenuItem onClick={logout}>{t("logout")}</MenuItem>}
      </MenuList>
    </Menu>
  );
};
