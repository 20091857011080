import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ColumnDef, getCoreRowModel, getSortedRowModel, Row, SortingState, useReactTable } from "@tanstack/react-table";
import { ReactNode } from "react";

export type DataGridProps<Data> = {
  columns: ColumnDef<Data>[];
  data: Data[];
  fullTextSearch?: string;
  render: (row: Row<Data>) => ReactNode;
  sorting?: SortingState;
} & Pick<SimpleGridProps, "minChildWidth" | "spacing" | "spacingX" | "spacingY"> &
  StyleProps;

export function DataGrid<Data extends object>({
  columns,
  data,
  fullTextSearch: globalFilter,
  render,
  sorting,
  ...gridProps
}: DataGridProps<Data>) {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      sorting,
    },
  });

  return (
    <SimpleGrid {...gridProps} overflowX="hidden">
      {table.getRowModel().rows.map((row) => render(row))}
    </SimpleGrid>
  );
}
