import { ReactNode } from "react";
import { AccordionButton, AccordionItem, AccordionIcon, AccordionPanel, Heading } from "@chakra-ui/react";
import { useSemanticTokens, spacing } from "@design-system";
import { StyleProps } from "@chakra-ui/styled-system";

export type SidebarPropertiesSectionProps = {
  title: string;
  children?: ReactNode;
} & StyleProps;

export function SidebarPropertiesSection({ title, children, ...props }: SidebarPropertiesSectionProps) {
  const semanticTokens = useSemanticTokens();
  return (
    <AccordionItem w="full" paddingY={spacing.space["300"]} borderBottom="none" {...props}>
      <AccordionButton w="full">
        <Heading as="h5" color={semanticTokens.text.classic.secondary} size="h5" pb={spacing.space["100"]}>
          {title}
        </Heading>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel gap={spacing.space["200"]}>{children}</AccordionPanel>
    </AccordionItem>
  );
}
