import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function GuideCurveIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" height="5" width="5" {...props}>
      <path
        d="M4.00008 10.667C3.2637 10.667 2.66675 11.2639 2.66675 12.0003C2.66675 12.7367 3.2637 13.3337 4.00008 13.3337C4.73646 13.3337 5.33341 12.7367 5.33341 12.0003C5.33341 11.2639 4.73646 10.667 4.00008 10.667ZM4.00008 10.667C4.00008 8.00033 7.00008 8.00033 8.00008 8.00033C9.00007 8.00033 12.0001 8.00033 12.0001 5.33366M12.0001 5.33366C12.7365 5.33366 13.3334 4.73671 13.3334 4.00033C13.3334 3.26395 12.7365 2.66699 12.0001 2.66699C11.2637 2.66699 10.6667 3.26395 10.6667 4.00033C10.6667 4.73671 11.2637 5.33366 12.0001 5.33366Z"
        stroke="currentColor"
        fill="none"
      />
      <path
        d="M9.66675 12.6663L12.0001 10.333L14.3334 12.6663M12.0001 14.9998V10.4997"
        stroke="currentColor"
        fill="none"
      />
    </Icon>
  );
}
