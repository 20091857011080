import { CloseIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { useSemanticTokens } from "@design-system";
import { useThemeTokens } from "@design-system/hooks";
import { ReactNode } from "react";

type PopoverProps = {
  children: ReactNode;
  header?: string;
  onClose: () => void;
  closable?: boolean;
} & StyleProps;

export function Popover({ header, children, onClose, closable = true, ...styles }: PopoverProps) {
  const semanticTokens = useSemanticTokens();
  const { border, popover } = useThemeTokens();

  return (
    <Flex direction="column" w="400px" gap="3" background={semanticTokens.surface.classic.primary} boxShadow="lg">
      <Flex
        p="1"
        pl="4"
        justifyContent="space-between"
        alignItems="center"
        borderColor={semanticTokens.border.classic.secondary}
        borderBottomWidth={border.width}
      >
        <Text color={semanticTokens.text.classic.primary} variant={popover.header.text.variant}>
          {header}
        </Text>
        <IconButton
          aria-label="Close"
          icon={<CloseIcon />}
          size="sm"
          variant="windowIcon"
          onClick={() => onClose()}
          visibility={closable ? "visible" : "hidden"}
        />
      </Flex>
      <Flex direction="column" p="4" pt="3" gap="6" {...styles}>
        {children}
      </Flex>
    </Flex>
  );
}
