import "@/globals";
import { Model } from "@models/project";
import { BasePath, Part, Vector3 } from "@variant-tech/pattern-derivation";
import { getV3dApi } from "@utils/project/initV3dApi.ts";

function getOriginalOrder(original: Array<Vector3>, modified: Array<Vector3>): Array<number> {
  const originalIdx: Array<[Vector3, number]> = original.map((point, idx) => [point, idx]);
  const modifiedIdx: Array<[Vector3, number]> = modified.map((point, idx) => [point, idx]);
  originalIdx.sort();
  modifiedIdx.sort();
  const result = new Array(originalIdx.length).fill(0);
  for (let i = 0; i < original.length; i++) {
    result[modifiedIdx[i][1]] = originalIdx[i][1];
  }
  return result;
}

export async function generateUserSectionCurves(
  model: Model,
  guideSource: BasePath,
  userAnchors: Array<Vector3>,
): Promise<Array<[Vector3, Float32Array, number]>> {
  const v3dApi = getV3dApi();
  const part = await v3dApi.Part.create(model.mesh3DBase, guideSource, []);
  await part.computeFieldValues();
  await part.setSectionAnchors(userAnchors);
  const sectionAnchors = (await part.getExtrasPayload())["sectionAnchors"];
  const sectionCurves = await part.getSectionAnchorCurvesAsFloats();
  await part.delete();
  const order = getOriginalOrder(userAnchors, sectionAnchors);
  return sectionAnchors.map((sectionAnchor: Vector3, idx: number) => [sectionAnchor, sectionCurves[idx], order[idx]]);
}

export async function generateAutoSectionCurves(
  model: Model,
  guideSource: BasePath,
  sectionsCount: number,
): Promise<Array<[Vector3, Float32Array]>> {
  const v3dApi = getV3dApi();
  const part = await v3dApi.Part.create(model.mesh3DBase, guideSource, []);
  await part.computeFieldValues();
  await part.computeSectionAnchors(sectionsCount);
  const sectionCurves = await part.getSectionAnchorCurvesAsFloats();
  // TODO: add types to get this directly.
  const sectionAnchors = (await part.getExtrasPayload())["sectionAnchors"];
  await part.delete();

  return sectionCurves.map((curve, idx) => [sectionAnchors[idx], curve]);
}

export async function getSectionCurve(part: Part, anchorPosition: Vector3): Promise<Float32Array> {
  if (!v3dApi) {
    throw new Error("V3D Api has not been initialized");
  }
  const sectionCurve = await part.computeSectionCurveAsFloats(anchorPosition);
  return sectionCurve;
}

export async function generateUserColumnCurves(
  model: Model,
  guideSource: BasePath,
  userAnchors: Array<Vector3>,
): Promise<Array<[Vector3, Float32Array, number]>> {
  const v3dApi = getV3dApi();
  const part = await v3dApi.Part.create(model.mesh3DBase, guideSource, []);
  await part.computeFieldValues();
  await part.setColumnAnchors(userAnchors);
  const columnAnchors = (await part.getExtrasPayload())["columnAnchors"];
  const columnCurves = await part.getColumnAnchorCurvesAsFloats();
  await part.delete();
  const order = getOriginalOrder(userAnchors, columnAnchors);
  return columnAnchors.map((columnAnchor: Vector3, idx: number) => [columnAnchor, columnCurves[idx], order[idx]]);
}

export async function generateAutoColumnCurves(
  model: Model,
  guideSource: BasePath,
  sectionsCount: number,
): Promise<Array<[Vector3, Float32Array]>> {
  const v3dApi = getV3dApi();
  const part = await v3dApi.Part.create(model.mesh3DBase, guideSource, []);
  await part.computeFieldValues();
  await part.computeColumnAnchors(sectionsCount);
  const columnCurves = await part.getColumnAnchorCurvesAsFloats();
  // TODO: add types to get this directly.
  const columnAnchors = (await part.getExtrasPayload())["columnAnchors"];
  await part.delete();

  return columnCurves.map((curve, idx) => [columnAnchors[idx], curve]);
}

export async function getColumnCurve(part: Part, anchorPosition: Vector3): Promise<Float32Array> {
  if (!v3dApi) {
    throw new Error("V3D Api has not been initialized");
  }
  const columnCurve = await part.computeColumnCurveAsFloats(anchorPosition);
  return columnCurve;
}

export async function getPart(model: Model, guideSource: BasePath): Promise<Part> {
  if (!v3dApi) {
    throw new Error("V3D Api has not been initialized");
  }
  const part = await v3dApi.Part.create(model.mesh3DBase, guideSource, []);
  await part.computeFieldValues();

  return part;
}

export async function deletePart(part: Part) {
  if (!v3dApi) {
    throw new Error("V3D Api has not been initialized");
  }

  await part.delete();
}
