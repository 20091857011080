import { Project } from "@models/backend";
import { Model as ProjectModel } from "@models/project";

export function useModelName(project: Project, model?: ProjectModel | null) {
  if (!model) {
    return project.name;
  }

  const removeExtension = (name: string) => {
    const parts = name.split(".");
    return parts.length > 1 ? parts.slice(0, -1).join(".") : name;
  };

  const modelNameWithoutExtension = removeExtension(model.name);

  if (project.name !== "Untitled") {
    return project.name;
  }

  return modelNameWithoutExtension;
}
