import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { Path, BezierPath, HarmonicPath, Vector3 } from "@variant-tech/pattern-derivation";
import { Model, PathCollection } from "@models/project";
import { Path as PathModel } from "@models/backend";
import { INITIAL_PATH_COLLECTION, INITIAL_PATH } from "@state/models/reducers/pathReducer";

type PathState = Awaited<ReturnType<Path["getState"]>>;

export async function createPathCollection(
  model: Model,
  type: "Path" | "BezierPath" | "HarmonicPath" = "Path",
): Promise<PathCollection> {
  const v3dApi = getV3dApi();
  const cpp = await v3dApi.PathCollection.create(model.mesh3DBase);

  let cppPath: Path | BezierPath | HarmonicPath | null = null;
  switch (type) {
    case "Path":
      cppPath = await v3dApi.Path.create(model.mesh3DBase);
      break;
    case "BezierPath":
      cppPath = await v3dApi.BezierPath.create(model.mesh3DBase);
      break;
    case "HarmonicPath":
      cppPath = await v3dApi.HarmonicPath.create(model.mesh3DBase);
      break;
  }
  await cpp.appendPath(cppPath);
  return {
    ...INITIAL_PATH_COLLECTION,
    cpp,
    paths: [
      {
        ...INITIAL_PATH,
        cpp: cppPath!,
      },
    ],
  };
}

export async function createEmptyPathCollection(model: Model): Promise<PathCollection> {
  const v3dApi = getV3dApi();
  const cpp = await v3dApi.PathCollection.create(model.mesh3DBase);
  return {
    ...INITIAL_PATH_COLLECTION,
    cpp,
    paths: [],
  };
}

export async function createPathCollectionFromState(model: Model, paths: PathModel[]): Promise<PathCollection> {
  const v3dApi = getV3dApi();
  const collection = await v3dApi.pathCollectionFromState(model.mesh3DBase, paths);
  const pathsCpp = await collection.getPaths();
  const pathsState: Array<PathState> = await collection.getState();

  const pathsObjects = await Promise.all(
    pathsState.map(async (pathState, index) => {
      const { controlVectors, points, isLoop, type } = pathState;
      const pathCpp = pathsCpp[index];

      const dummyControlVectors = [];

      for (let i = 0; i < points.length; i++) {
        dummyControlVectors.push([
          [0, 0, 0],
          [0, 0, 0],
        ] as [Vector3, Vector3]);
      }

      const curves = await pathCpp.getCurveSegmentsAsFloats();

      return {
        ...INITIAL_PATH,
        controlVectors: controlVectors && controlVectors.length ? controlVectors : dummyControlVectors,
        cpp: pathCpp as Path | BezierPath | HarmonicPath,
        curves,
        points,
        isLoop,
        type: type as "Path" | "BezierPath" | "HarmonicPath",
      };
    }),
  );

  return {
    ...INITIAL_PATH_COLLECTION,
    cpp: collection,
    paths: pathsObjects,
  };
}

export async function convertPath(
  collection: PathCollection,
  subpathIdx: number,
  type: "BezierPath" | "HarmonicPath",
): Promise<BezierPath | HarmonicPath | Path> {
  if (collection.paths[subpathIdx].cpp["__type"] !== "Path") {
    console.log("convertPath: sub path is not a Path");
    return collection.paths[subpathIdx].cpp;
  }
  const netCpp = collection.cpp;
  const pathCpp =
    type === "BezierPath"
      ? await netCpp.convertToBezierPath(subpathIdx)
      : await netCpp.convertToHarmonicPath(subpathIdx);

  return pathCpp;
}

export async function resetPath(collection: PathCollection, subpathIdx: number): Promise<Path> {
  if (collection.paths[subpathIdx].cpp["__type"] === "Path") {
    // Some states are not managed by this store, and this might happen.
    return collection.paths[subpathIdx].cpp as Path;
  }
  const netCpp = collection.cpp;
  return await netCpp.resetPath(subpathIdx);
}

export async function deletePathCollection(collection: PathCollection) {
  for (const path of collection.paths) {
    path.cpp.delete();
  }
  collection.cpp.delete();
}
