import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function AdminIcon({ fill = "none", ...props }: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill={fill} {...props}>
      <path
        d="M17 13.75V3.75M17 13.75C18.7956 13.75 20.25 15.2044 20.25 17C20.25 18.7956 18.7956 20.25 17 20.25C15.2044 20.25 13.75 18.7956 13.75 17C13.75 15.2044 15.2044 13.75 17 13.75ZM7 20.25V12.25M7 12.25C5.20438 12.25 3.75 10.7956 3.75 9C3.75 7.20438 5.20438 5.75 7 5.75M7 12.25C8.79562 12.25 10.25 10.7956 10.25 9C10.25 7.20438 8.79562 5.75 7 5.75M7 5.75V3.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
