import { create } from "zustand";
import { modelsSlice } from "./slices/models";
import { pathCollectionsSlice } from "./slices/pathCollections";
import { selectionSlice } from "./slices/selection";
import { ModelsStore } from "./types";
import { sectionsSlice } from "./slices/sections";
import { columnsSlice } from "./slices/columns";

export type { ModelsStore, PathCollections, SelectedObject } from "./types";

// create the store and define its initial state and actions
export const useModelsStore = create<ModelsStore>(
  (set, get): ModelsStore => ({
    ...modelsSlice(set, get),
    ...pathCollectionsSlice(set, get),
    ...sectionsSlice(set, get),
    ...columnsSlice(set, get),
    ...selectionSlice(set),
    clear: () => {
      set({ models: [], pathCollections: {}, sections: {}, columns: {}, selectedObjects: [] });
    },
    logModels: () => console.log("logModels", get().models),
  }),
);
