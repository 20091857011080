import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useCurrentProject } from "@fragments/project/container";
import { ModelAttributes, Project, Unit } from "@models/backend";
import { Model } from "@models/project";
import { ModelsStore, useModelsStore } from "@state/models";
import { recordToArray } from "@utils/project/collection";
import { savePathCollection } from "@utils/project/model";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useShallow } from "zustand/react/shallow";
import { useLoadProject } from "@hooks/project/useSavedModel";

export const useModelUpdate = ({ modelId }: { projectId?: Project["id"]; modelId: Model["id"] }) => {
  const {
    project: { id: projectId },
  } = useCurrentProject();
  const { headers } = useAuth();
  const { pathCollections, updateModel, updateModelUnit } = useModelsStore(
    ({ updateModel, updateModelUnit, pathCollections }) => ({
      pathCollections,
      updateModel,
      updateModelUnit,
    }),
  );
  const loadProject = useLoadProject({});
  const model: Model | undefined = useModelsStore(
    useShallow((state: ModelsStore) => state.models.find(({ id }) => id == modelId)),
  )!;

  const getBackendProject = useCallback(async () => {
    return await BackendApi.getProject({
      headers,
      params: { id: projectId },
    });
  }, [headers, projectId]);
  const updateBackendAttributes = useCallback(
    async (attributes: ModelAttributes) => {
      await BackendApi.patchProjectModel({
        headers,
        params: { projectId, id: modelId },
        body: { attributes },
      });
    },
    [headers, projectId, modelId],
  );
  const debouncedUpdateBackendAttributes = useDebouncedCallback(updateBackendAttributes, 1000);
  const flipNormals = useCallback(async () => {
    const attributes = { ...(model.attributes ?? { stitchDensity: {} }) };
    const oldFlipNormals = model.attributes?.normals?.flipNormals ?? false;
    const flipNormals = !oldFlipNormals;
    const newAttributes: ModelAttributes = {
      ...attributes,
      normals: {
        flipNormals,
      },
    };
    await updateBackendAttributes(newAttributes);
    const project = await getBackendProject();
    await loadProject(project);
  }, [modelId, model.attributes, updateBackendAttributes, getBackendProject, loadProject]);
  const changeAttributes = useCallback(
    async (changedAttributes: Partial<ModelAttributes>) => {
      const attributes = { ...(model.attributes ?? { stitchDensity: {} }) };
      for (const catUnknown in changedAttributes) {
        const cat = catUnknown as keyof ModelAttributes;
        attributes[cat] = { ...attributes[cat], ...changedAttributes[cat] };
      }
      updateModel(modelId, { attributes });
      await debouncedUpdateBackendAttributes(attributes);
    },
    [model.attributes, model.filename, model.url, modelId, debouncedUpdateBackendAttributes, updateModel],
  );

  const changeUnit = useCallback(
    async (unit: Unit) => {
      async function updateBackendModelUnit(modelId: Model["id"]) {
        await updateModelUnit(modelId, unit);
        await BackendApi.patchProjectModel({ headers, params: { projectId, id: modelId }, body: { unit } });
        const modelPathCollections = pathCollections[modelId];
        if (modelPathCollections) {
          const collections = recordToArray(modelPathCollections.collections);
          for (const collection of collections) {
            await savePathCollection(headers, projectId, modelId, collection);
          }
        }
      }
      await updateBackendModelUnit(modelId);
      await Promise.all(model.references.map((reference) => updateBackendModelUnit(reference.id)));
    },

    [headers, modelId, pathCollections, projectId, updateModelUnit],
  );

  return { changeAttributes, changeUnit, model, flipNormals };
};
