import { StatusType } from "@api/useBackendApi.ts";
import { Progress } from "@chakra-ui/react";
import { CloudErrorIcon, CloudSuccessIcon, CloudUploadIcon, ToastProps, useToast } from "@design-system";
import { TranslationFunction } from "@hooks/useTranslation.ts";

const statusToastProps: Record<StatusType, Partial<ToastProps & { progress?: number }>> = {
  "in-progress": {
    icon: <CloudUploadIcon />,
    variant: "info",
    isCloseable: false,
  },
  success: {
    icon: <CloudSuccessIcon />,
    progress: 100,
    variant: "success",
    isCloseable: true,
  },
  error: {
    icon: <CloudErrorIcon />,
    variant: "error",
    isCloseable: true,
  },
};

function computeToastProps({
  title,
  message,
  status,
  progress,
  ...props
}: { status?: StatusType; message?: string; progress?: number } & ToastProps) {
  return {
    title,
    children: (
      <>
        {message && <p dangerouslySetInnerHTML={{ __html: message }}></p>}
        {status === "in-progress" && <Progress value={progress} isIndeterminate={progress === undefined} />}
      </>
    ),
    ...props,
  };
}

export type ProgressToastProps = {
  title: string;
  message?: string;
  status: StatusType;
  progress?: number;
} & Partial<ToastProps>;

export function useProgressToast() {
  const toast = useToast();

  return (t: TranslationFunction, { title, message, status, ...props }: ProgressToastProps) => {
    const { close, update: updateToast } = toast(
      computeToastProps({
        title,
        message: message ?? t(`status.${status}.label`),
        status,
        ...statusToastProps[status],
        ...props,
      }),
    );

    const originalTitle = title;

    return {
      update: ({
        title = originalTitle,
        status,
        ...updateProps
      }: Omit<ProgressToastProps, "title"> & { title?: string }) => {
        updateToast(
          computeToastProps({
            title,
            message: message ?? t(`status.${status}.label`),
            status,
            ...props,
            ...statusToastProps[status],
            ...updateProps,
          }),
        );

        if (status === "success") {
          setTimeout(() => {
            close();
          }, 1000);
        }
      },
      close,
    };
  };
}
