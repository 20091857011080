import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useCurrentProject } from "@fragments/project/container/ProjectContext.tsx";
import { useProgressToast, useTranslation } from "@hooks";
import { ColumnAnchor, Model, PathCollection, SectionAnchor } from "@models/project";
import knitProcess from "@utils/project/knitProcess";
import { BasePath } from "@variant-tech/pattern-derivation";
import { saveAs } from "file-saver";
import { useCallback } from "react";
import { trackBitmapExported } from "@/events";
import { captureException } from "@sentry/react";

const MAX_STEPS = 20;
function sleep(millis: number) {
  return new Promise((resolve) => setTimeout(resolve, millis));
}

export function useBMPExporter() {
  const { t } = useTranslation("hooks.project.exportBMP.progress");
  const toast = useProgressToast();

  const {
    project: { id: projectId },
  } = useCurrentProject();
  const { headers } = useAuth();

  const exportBMP = useCallback(
    async (
      model: Model,
      guideSource: BasePath,
      collections: PathCollection[],
      sectionAnchors: SectionAnchor[],
      columnAnchors: ColumnAnchor[],
      binary?: boolean,
      debug?: boolean,
    ) => {
      const { update } = toast(t, { title: t("title"), status: "in-progress" });

      try {
        update({ status: "in-progress", message: t("status.in-progress.knitProcess") });

        const content = await knitProcess(
          model,
          guideSource,
          collections,
          sectionAnchors,
          columnAnchors,
          binary,
          debug,
        );

        update({ status: "in-progress", message: t("status.in-progress.createProcess") });

        let result = await BackendApi.createProcess({
          content,
          onProgress: ({ percentage }: { loaded: number; total: number; percentage: number }) =>
            update({
              status: "in-progress",
              progress: percentage * 0.3,
              message: t("status.in-progress.createProcess"),
            }),
          params: {
            projectId,
          },
          headers,
        });

        let i = 0;
        const id = result.id;
        update({ status: "in-progress", progress: 30, message: t("status.in-progress.getProcess") });

        do {
          await sleep(5000);

          result = await BackendApi.getProcess({
            params: {
              projectId,
              id,
            },
            headers,
          });

          if (result.status !== "ready" && result.status !== "processing") {
            break;
          }

          i += 1;
          update({
            status: "in-progress",
            progress: 30 + (i * 70) / MAX_STEPS,
            message: t("status.in-progress.getProcess"),
          });
        } while (i < MAX_STEPS);

        if (result.status !== "done") {
          throw new Error(`Process failed with status: ${result.status}, message: ${result.info?.message}`);
        }

        update({ status: "in-progress", progress: 99, message: t("status.in-progress.saveAs") });

        const url = result.output?.bmpUrl;
        if (!url) {
          throw new Error("BMP URL not found in process output");
        }

        saveAs(url, "output.bmp");

        trackBitmapExported({
          projectId,
          size: content.size,
          status: "success",
        });

        update({ status: "success" });
      } catch (error) {
        captureException(error, {
          extra: {
            projectId,
            binary,
            debug,
          },
        });

        trackBitmapExported({
          projectId,
          status: "failed",
          error: error instanceof Error ? error.message : String(error),
        });

        update({
          title: t("status.error.title"),
          status: "error",
          message: t("status.error.knitProcess", {
            message: error instanceof Error ? error.message : String(error),
          }),
        });
      }
    },
    [toast, t, projectId, headers],
  );

  return { exportBMP };
}
