import { Mesh3DBase } from "@variant-tech/pattern-derivation";
import { BufferAttribute, BufferGeometry, MeshStandardMaterial } from "three";

export async function getZoneGeo(mesh3DBase: Mesh3DBase) {
  const vertices = await mesh3DBase.verticesAsFloat32Buffer();
  const faces = await mesh3DBase.facesAsInt32Buffer();
  const geometry = new BufferGeometry();
  const indices: number[] = [];
  faces.forEach((face: number) => {
    indices.push(face);
  });

  geometry.setIndex(indices);
  geometry.setAttribute("position", new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  return geometry;
}

export function getZoneShader(index: number) {
  const mat = new MeshStandardMaterial();
  mat.onBeforeCompile = (shader) => {
    shader.uniforms.scaleOffset = { value: 0.005 + 0.003 * index };
    shader.vertexShader = `uniform float scaleOffset;\n` + shader.vertexShader;
    shader.vertexShader = shader.vertexShader.replace(
      "#include <begin_vertex>",
      `
          vNormal = normal;
          vec3 scaledPosition = position + vNormal * scaleOffset;
          vec3 transformed = scaledPosition;
        `,
    );

    shader.vertexShader = shader.vertexShader.replace(
      "#include <project_vertex>",
      `
          vec4 mvPosition = modelViewMatrix * vec4(transformed, 1.0);
          gl_Position = projectionMatrix * modelViewMatrix * vec4( scaledPosition, 1.0 );
        `,
    );
  };

  return mat;
}
