import { useCustomCursor } from "@hooks/useCustomCursor";
import { usePointerState, useCalibrationCurves } from "@hooks";
import { Project } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { useFrame, useThree } from "@react-three/fiber";
import { useProjectState } from "@state/project";
import { Part } from "@variant-tech/pattern-derivation";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { workspace3dTokens } from "./workspace-3d-tokens";
import { deletePart, getPart, getColumnCurve, getSectionCurve } from "@utils/project/part";
import { Curve } from "./curve";
import { useDebouncedCallback } from "use-debounce";
import { useModelsStore } from "@state";

type CalibrationCurveToolProps = {
  project: Project;
  model: Model;
  guideSource: PathCollection;
};

export function CalibrationCurveTool({ project, model, guideSource }: CalibrationCurveToolProps) {
  const { mesh } = model;
  const guideSourcePath = guideSource.paths[0] ?? null;
  const { setCursor } = useCustomCursor();
  const { _3DToolbarSelection: mode, set3DToolbarSelection } = useProjectState();
  const { addColumnAnchor, addSectionAnchor } = useCalibrationCurves({ project, model });

  const { columns, sections } = useModelsStore();
  const modelSectionAnchors = sections[model.id]?.sectionAnchors;
  const modelColumnAnchors = columns[model.id]?.columnAnchors;

  const [calibrationCurve, setCalibrationCurve] = useState<Float32Array | null>(null);

  const [part, setPart] = useState<Part>();

  const calibrationCurveStyle = mode === "section" ? workspace3dTokens.path.section : workspace3dTokens.path.column;

  useEffect(() => {
    async function createPart() {
      const part = await getPart(model, guideSourcePath.cpp);
      setPart(part);
    }
    setCursor("addSelection");
    createPart();

    return () => {
      abort();
    };
  }, [guideSourcePath]);

  useHotkeys("escape", () => abort());

  const abort = async () => {
    if (part) {
      await deletePart(part);
    }
    set3DToolbarSelection("select");
  };

  const { raycaster } = useThree();
  const { pointer0Down } = usePointerState();

  const process = async () => {
    if (!pointer0Down || !part) {
      return;
    }

    const hit = raycaster.intersectObject(mesh)[0];
    if (!hit) {
      return;
    }

    const pos: [number, number, number] = [hit.point.x, hit.point.y, hit.point.z];

    if (mode === "section") {
      const newCalibrationCurve = await getSectionCurve(part, pos);
      const index = modelSectionAnchors.length;
      const attributes = { source: "user" };
      await addSectionAnchor(pos, newCalibrationCurve, index, attributes);
    } else if (mode === "column") {
      const newCalibrationCurve = await getColumnCurve(part, pos);
      const index = modelColumnAnchors.length;
      const attributes = { source: "user" };
      await addColumnAnchor(pos, newCalibrationCurve, index, attributes);
    }
  };

  const getPreviewCalibrationCurve = useDebouncedCallback(async (pos: [number, number, number]) => {
    if (!part) return;

    let curve: Float32Array | null = null;

    if (mode === "section") {
      curve = await getSectionCurve(part, pos);
    } else if (mode === "column") {
      curve = await getColumnCurve(part, pos);
    }

    setCalibrationCurve(curve);
  }, 5);

  useFrame(() => {
    const hit = raycaster.intersectObject(mesh)[0];
    if (!hit) {
      setCalibrationCurve(null);
      return;
    }

    const pos: [number, number, number] = [hit.point.x, hit.point.y, hit.point.z];

    getPreviewCalibrationCurve(pos);
  });

  useEffect(() => {
    process();
  }, [pointer0Down]);

  return (
    <>
      {calibrationCurve && (
        <Curve
          mesh={model.mesh}
          curve={calibrationCurve}
          color={calibrationCurveStyle.color}
          opacity={1.0}
          width={2.0}
          showLengthOverride
        />
      )}
    </>
  );
}
