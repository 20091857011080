import { Button, Menu, MenuButton, MenuList, MenuProps } from "@chakra-ui/react";
import { SystemProps } from "@chakra-ui/styled-system";
import { ReactNode } from "react";

export type PopoverTriggerProps = {
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  children: ReactNode;
  placement?: MenuProps["placement"];
  content: ReactNode;
  variant?: string;
} & SystemProps;

export function PopoverTrigger({
  isOpen,
  onOpen,
  onClose,
  children,
  placement,
  content,
  variant,
  ...triggerStyleProps
}: PopoverTriggerProps) {
  return (
    <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen} placement={placement} variant="dropdown">
      <MenuButton as={Button} textAlign="start" variant={variant ?? "primary"} {...triggerStyleProps}>
        {content}
      </MenuButton>
      <MenuList>{children}</MenuList>
    </Menu>
  );
}
