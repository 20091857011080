import { Dispatch, SetStateAction } from "react";

export async function withLoadingAndErrorHandling<T>(
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<Error | null>>,
  task: () => Promise<T>,
) {
  setIsLoading(true);
  setError(null);

  try {
    const result = await task();
    setIsLoading(false);
    return result;
  } catch (err) {
    setError(err instanceof Error ? err : new Error(`${err}`));
    setIsLoading(false);
    throw err;
  }
}
