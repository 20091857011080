import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function WarningIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" height="24px" width="24px" fill="none" {...props}>
      <rect x="11" y="15" width="2" height="2" fill="currentColor" />
      <path d="M12 10V13M12 3L2.5 19H21.5L12 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
    </Icon>
  );
}
