import { AuthHeaders, OrganizationMember, User } from "@auth";
import { ColumnAnchor, Model, PathCollection, Project, SectionAnchor } from "@models/backend";
import { Process } from "@models/project";
import { env } from "../config";
import FetchBackendApi from "./FetchBackendApi";
import { MockBackendApi } from "./mock";

type Body<T> = { body: T };
type Params<T> = { params: T };

type Id = { id: string };
type MemberId = { memberId: string };
type OrganizationId = { organizationId: string };
type ProjectId = { projectId: string };
type ModelId = { modelId: string };
export interface Upload<T extends Blob = Blob> {
  content: T;
  onProgress?: (props: { loaded: number; total: number; percentage: number }) => void;
}

export type CreateProjectRequest = AuthHeaders & Body<Partial<Project> & { name: Project["name"] }>;
export type DeleteProjectRequest = AuthHeaders & Params<Id>;
export type GetProjectRequest = AuthHeaders & Params<Id>;
export type GetProjectsRequest = AuthHeaders & Params<Partial<OrganizationId>>;
export type Projects = { projects: Project[] };
export type AddProjectModelRequest = AuthHeaders & Params<ProjectId> & Body<Partial<Model>> & Upload<File>;
export type DecimateProjectModelRequest = AuthHeaders & Params<ProjectId & Id> & Upload<File>;
export type PatchProjectModelRequest = AuthHeaders &
  Params<ProjectId & Id> &
  Body<Partial<Pick<Model, "unit" | "attributes" | "name">>>;
export type DeleteProjectModelRequest = AuthHeaders & Params<ProjectId & Id>;
export type UpdateProjectThumbnailRequest = AuthHeaders & Params<ProjectId> & Upload;
export type CreatePathCollectionRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<Omit<PathCollection, "id">>;
export type UpdatePathCollectionRequest = AuthHeaders &
  Params<ProjectId & ModelId & Id> &
  Body<Partial<PathCollection>>;
export type DeletePathCollectionRequest = AuthHeaders & Params<ProjectId & ModelId & Id>;

export type CreateSectionAnchorRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<Omit<SectionAnchor, "id">>;
export type UpdateSectionAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id> & Body<Partial<SectionAnchor>>;
export type DeleteSectionAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id>;

export type CreateColumnAnchorRequest = AuthHeaders & Params<ProjectId & ModelId> & Body<Omit<ColumnAnchor, "id">>;
export type UpdateColumnAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id> & Body<Partial<ColumnAnchor>>;
export type DeleteColumnAnchorRequest = AuthHeaders & Params<ProjectId & ModelId & Id>;

export type GetOrganizationMembersRequest = AuthHeaders & Params<OrganizationId>;
export type AddOrganizationMemberRequest = AuthHeaders & Params<OrganizationId> & Body<Partial<OrganizationMember>>;
export type UpdateOrganizationMemberRequest = AuthHeaders &
  Params<OrganizationId & MemberId> &
  Body<Partial<OrganizationMember>>;
export type RemoveOrganizationMembersRequest = AuthHeaders &
  Params<OrganizationId> &
  Body<{ ids: OrganizationMember["id"][] }>;
export type OrganizationMembers = { members: OrganizationMember[] } & OrganizationId;

export type GetUserRequest = AuthHeaders;

export type CreateProcessRequest = AuthHeaders & Params<ProjectId> & Upload<File>;
export type GetProcessRequest = AuthHeaders & Params<ProjectId & Id>;
export type PatchProjectRequest = AuthHeaders & Params<ProjectId> & Body<{ name: string }>;

export interface BackendApi {
  createProject(request: CreateProjectRequest): Promise<Project>;

  deleteProject(request: DeleteProjectRequest): Promise<void>;

  getProjects(request: GetProjectsRequest): Promise<Projects>;

  getProject(request: GetProjectRequest): Promise<Project>;

  addProjectModel(request: AddProjectModelRequest): Promise<Model>;

  decimateProjectModel(request: DecimateProjectModelRequest): Promise<Model>;

  patchProjectModel(request: PatchProjectModelRequest): Promise<Model>;

  deleteProjectModel(request: DeleteProjectModelRequest): Promise<void>;

  createProcess(request: CreateProcessRequest): Promise<Process>;

  getProcess(request: GetProcessRequest): Promise<Process>;

  updateProjectThumbnail(request: UpdateProjectThumbnailRequest): Promise<void>;

  createPathCollection(request: CreatePathCollectionRequest): Promise<PathCollection>;

  updatePathCollection(request: UpdatePathCollectionRequest): Promise<PathCollection>;

  deletePathCollection(request: DeletePathCollectionRequest): Promise<void>;

  createSectionAnchor(request: CreateSectionAnchorRequest): Promise<SectionAnchor>;

  updateSectionAnchor(request: UpdateSectionAnchorRequest): Promise<SectionAnchor>;

  deleteSectionAnchor(request: DeleteSectionAnchorRequest): Promise<void>;

  createColumnAnchor(request: CreateColumnAnchorRequest): Promise<ColumnAnchor>;

  updateColumnAnchor(request: UpdateColumnAnchorRequest): Promise<ColumnAnchor>;

  deleteColumnAnchor(request: DeleteColumnAnchorRequest): Promise<void>;

  getOrganizationMembers(request: GetOrganizationMembersRequest): Promise<OrganizationMembers>;

  addOrganizationMember(request: AddOrganizationMemberRequest): Promise<OrganizationMember>;

  updateOrganizationMember(request: UpdateOrganizationMemberRequest): Promise<OrganizationMember>;

  removeOrganizationMembers(request: RemoveOrganizationMembersRequest): Promise<OrganizationMembers>;

  getUser(request: GetUserRequest): Promise<User>;

  patchProject(request: PatchProjectRequest): Promise<Project>;
}

const backendApi: BackendApi = env === "mock" ? MockBackendApi : FetchBackendApi;

export default backendApi;
