import { HStack } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ToggleIconButton } from "@design-system";
import { useTranslation } from "@hooks";
import { useProjectState } from "@state/project";
import { useEffect } from "react";
import { useCustomCursor } from "@hooks/useCustomCursor.tsx";
import { ColumnIcon, PenIcon, SectionIcon, SelectIcon } from "./icons";

export function PenTool() {
  const { t } = useTranslation("project3D.toolbarLeft.tools");
  const { _3DToolbarSelection, set3DToolbarSelection } = useProjectState();
  const toolName = "pen";

  return (
    <ToggleIconButton
      key={toolName}
      isSelected={_3DToolbarSelection == toolName}
      onToggle={() => {
        set3DToolbarSelection(toolName);
      }}
      label={t(toolName + ".label")}
      icon={<PenIcon />}
      shortcut={{
        label: t(toolName + ".label"),
        keys: "p",
      }}
      placement="bottom"
      variant="inverse"
    />
  );
}

export function SectionTool() {
  const { t } = useTranslation("project3D.toolbarLeft.tools");
  const { _3DToolbarSelection, set3DToolbarSelection } = useProjectState();
  const toolName = "section";

  return (
    <ToggleIconButton
      key={toolName}
      isSelected={_3DToolbarSelection == toolName}
      onToggle={() => {
        set3DToolbarSelection(toolName);
      }}
      label={t(toolName + ".label")}
      icon={<SectionIcon />}
      shortcut={{
        label: t(toolName + ".label"),
        keys: "s",
      }}
      placement="bottom"
      variant="inverse"
    />
  );
}

export function ColumnTool() {
  const { t } = useTranslation("project3D.toolbarLeft.tools");
  const { _3DToolbarSelection, set3DToolbarSelection } = useProjectState();
  const toolName = "column";

  return (
    <ToggleIconButton
      key={toolName}
      isSelected={_3DToolbarSelection == toolName}
      onToggle={() => {
        set3DToolbarSelection(toolName);
      }}
      label={t(toolName + ".label")}
      icon={<ColumnIcon />}
      shortcut={{
        label: t(toolName + ".label"),
        keys: "c",
      }}
      placement="bottom"
      variant="inverse"
    />
  );
}

export function SelectTool() {
  const { t } = useTranslation("project3D.toolbarLeft.tools");
  const { _3DToolbarSelection, set3DToolbarSelection } = useProjectState();
  const toolName = "select";

  return (
    <ToggleIconButton
      key={toolName}
      isSelected={_3DToolbarSelection == toolName}
      onToggle={() => {
        set3DToolbarSelection(toolName);
      }}
      label={t(toolName + ".label")}
      icon={<SelectIcon />}
      shortcut={{
        label: t(toolName + ".label"),
        keys: "v",
      }}
      placement="bottom"
      variant="inverse"
    />
  );
}

export function Tools3DToolbar(props: StyleProps) {
  const { _3DToolbarSelection } = useProjectState();
  const { setCursor } = useCustomCursor();

  useEffect(() => {
    const cursor = () => {
      switch (_3DToolbarSelection) {
        case "select":
          return "select";
        case "pen":
          return "pen";
        case "section":
          return "pen";
        default:
          return "select";
      }
    };
    setCursor(cursor());
  }, [_3DToolbarSelection, setCursor]);

  return (
    <HStack gap={0} {...props}>
      <SelectTool />
      <PenTool />
      <SectionTool />
      <ColumnTool />
    </HStack>
  );
}
