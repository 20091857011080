import { BoundsApi } from "@react-three/drei";
import { Mesh, Vector3 } from "three";

export function centerCamera(obj: Mesh, bounds: BoundsApi) {
  const center: Vector3 = new Vector3();
  obj.geometry.computeBoundingBox();
  obj.geometry.boundingBox?.getCenter(center);
  bounds.refresh(obj).clip();

  const y = obj.geometry.boundingBox?.max.y;
  const z = bounds.getSize().distance;
  bounds.moveTo(new Vector3(0, y, z)).lookAt({ target: center, up: [0, 1, 0] });
}
