import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function PenIcon(props: IconProps) {
  const clipUrl = "#clip" + props.id + ")"; // NOTE: clip acts weird when there are multiple icons on the same page and one is hidden
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <g clipPath={"url(" + clipUrl + ")"}>
        <path
          d="M15.0715 9.76124L11.9404 16.3687L2.50002 17.6187M15.0715 9.76124L10.3575 5.0472M15.0715 9.76124L15.6608 10.3505L16.25 10.9398L18.3334 8.85643L11.2623 1.78536L9.17896 3.86868L10.3575 5.0472M2.50002 17.6187L3.69079 8.11913L10.3575 5.0472M2.50002 17.6187L8.33335 11.7854"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          fill="none"
        />
        <ellipse cx="8.33333" cy="11.7854" rx="1.66667" ry="1.66667" fill="currentColor" />
      </g>
      <defs>
        <clipPath id={clipUrl}>
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </Icon>
  );
}
