import { analytics } from "@integrations/analytics/analytics";
import { EventTrackingError } from "../integrations/analytics/analyticsErrorHandlers";
import { BITMAP_EXPORTED, BITMAP_EXPORT_FAILED } from "./eventNames";
import { captureException } from "@sentry/react";

interface BitmapExportedProperties {
  projectId: string;
  status: "started" | "success" | "failed";
  size?: number;
  error?: string;
}

export function trackBitmapExported(properties: BitmapExportedProperties): void {
  const eventName = properties.status === "failed" ? BITMAP_EXPORT_FAILED : BITMAP_EXPORTED;

  analytics.track(eventName, properties).catch((error: Error) => {
    const trackingError = new EventTrackingError(eventName, error, { properties });
    captureException(trackingError);
  });
}
