import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useTranslation } from "@hooks";
import { useCallback } from "react";
import { useToast } from "@design-system";
import { ColumnAnchor } from "@models/project";

export function useBackendColumnAnchor() {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.columnAnchors");
  const toast = useToast();

  const createBackendColumnAnchor = useCallback(
    async (projectId: string, modelId: string, columnAnchor: ColumnAnchor) => {
      const { point, name, attributes } = columnAnchor;

      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: {
          point,
          modelId,
          name: name ?? "Column",
          attributes,
        },
      };

      try {
        const response = await BackendApi.createColumnAnchor(request);
        columnAnchor.id = response.id;
        return response;
      } catch (error) {
        console.log({ message: "Failed to upload column anchor", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendColumnAnchor = useCallback(
    async (projectId: string, modelId: string, columnAnchor: ColumnAnchor) => {
      if (columnAnchor.id === undefined) {
        return null;
      }
      const { point, name, attributes } = columnAnchor;
      const request = {
        headers,
        params: {
          projectId,
          modelId,
          id: columnAnchor.id,
        },
        body: {
          point,
          name,
          attributes,
        },
      };

      try {
        await BackendApi.updateColumnAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to update column anchor", error, project: request.body });
        toast({
          title: t("update.toast.status.error.title"),
          children: t("update.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  const deleteBackendColumnAnchor = useCallback(
    async (projectId: string, modelId: string, columnAnchorId: string) => {
      const request = {
        headers,
        params: {
          projectId,
          modelId,
          id: columnAnchorId,
        },
      };

      try {
        await BackendApi.deleteColumnAnchor(request);
      } catch (error) {
        console.log({ message: "Failed to delete column anchor", error, request });
        toast({
          title: t("delete.toast.status.error.title"),
          children: t("delete.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  return { createBackendColumnAnchor, updateBackendColumnAnchor, deleteBackendColumnAnchor };
}
