import { Box, useStyleConfig } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { SeparatorVariant } from "../component-styles/SeparatorStyleConfig";

export type SeparatorProps = { variant?: SeparatorVariant } & StyleProps;

export function Separator({ variant, ...props }: SeparatorProps) {
  const styles = useStyleConfig("Separator", { variant });

  return <Box __css={styles} {...props} />;
}
