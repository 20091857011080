import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icons";

export function SelectIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" height="5" width="5" {...props}>
      <path
        fill="none"
        d="M1.25 1.25L7.93056 19.75L11.5278 11.5278L19.75 7.93056L1.25 1.25Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </Icon>
  );
}
