import { IconProps } from "@chakra-ui/icons";
import { Icon } from "@chakra-ui/react";

export function UpdatesIcon({ fill = "none", ...props }: IconProps) {
  return fill === "none" ? (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clipPath)">
        <path
          d="M2.70825 9.99967C6.24992 8.74967 8.74992 6.24967 9.99992 2.70801C11.2499 6.24967 13.7499 8.74967 17.2916 9.99967C13.7499 11.2497 11.2499 13.7497 9.99992 17.2913C8.74992 13.7497 6.24992 11.2497 2.70825 9.99967Z"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clipPath">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </Icon>
  ) : (
    <Icon viewBox="0 0 20 20" fill={fill} {...props}>
      <path d="M2.70825 9.99992C6.24992 8.74992 8.74992 6.24992 9.99992 2.70825C11.2499 6.24992 13.7499 8.74992 17.2916 9.99992C13.7499 11.2499 11.2499 13.7499 9.99992 17.2916C8.74992 13.7499 6.24992 11.2499 2.70825 9.99992Z" />
    </Icon>
  );
}
