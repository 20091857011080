import { Model, SectionAnchor } from "@models/project";
import { Model as BackendModel } from "@models/backend";
import { useModelsStore } from "@state/models";
import { PathState } from "@models/project";
import { generateUserSectionCurves } from "@utils/project/part";

export function useSectionAnchorLoader() {
  const { addSectionAnchor } = useModelsStore();

  async function loadSectionAnchors(model: Model, projectModel: BackendModel, guideSource: PathState) {
    const { sectionAnchors } = projectModel;
    const sections = await generateUserSectionCurves(
      model,
      guideSource.cpp,
      sectionAnchors.map(({ point }) => point),
    );
    const sectionCurves = sections.map(async ([_, curve, origIdx], idx) => {
      const resSectionAnchor: SectionAnchor = {
        ...sectionAnchors[origIdx],
        idx,
        curve,
      };
      addSectionAnchor(model.id, resSectionAnchor);
      return resSectionAnchor;
    });
    return sectionCurves;
  }

  return { loadSectionAnchors };
}
